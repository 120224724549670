import React, { useState, useEffect, useContext, Fragment } from 'react';
import InvitationCodesContext from '../../../../contexts/permissionContext';

import { useTranslation } from 'react-i18next';
import { columns, tableSearch, renderTable } from './invitationcode.utils';
import { getInvitationCodeReq } from '../../../../api/invitationcode/invitationcode';

import Head from '../../../../components/head';
import Table from '../../../../components/table';
import AddInvitationCode from './modals/addInvitationCode';
import DeleteInvitationCode from './modals/deleteInvitationCode';
import { IconPermission } from '../../../../assets/icons/icons-sidebar';

const InvitationCode = () => {
  const { t } = useTranslation(); // 't' jamais utilisé
  const { permContext } = useContext(InvitationCodesContext);
  const [tableData, setTableData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false); //'showModal' jamais utilisé
  const [currentItem, setCurrentItem] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isAdd, setIsAdd] = useState(false);

  const refreshDataList = () => {
    let isEdit, isDelete;
    isEdit = permContext?.codePromoUpdate;
    isDelete = permContext?.codePromoDelete;
    setIsAdd(isEdit);

    if (permContext) {
      /*Get data of the list*/
      getInvitationCodeReq()
        .then((res) => {
          /*Render and set data of the list*/
          setTableData(
            renderTable(res, setAddModal, setDeleteModal, setCurrentItem, isEdit, isDelete),
          );
        })
        .finally(() => setDataStatus('done'));
    }
  };

  useEffect(() => {
    refreshDataList();
  }, [permContext]); // manque 'refreshDataList'

  useEffect(() => {
    tableSearch(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/admin">Administration&nbsp;&nbsp;/</a>&nbsp;&nbsp;Code d'invitation
          unique
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Head
        headTitle={headTitle()}
        HeadIcon={IconPermission}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setAddModal={setAddModal}
        setCurrentItem={setCurrentItem}
        isAdd={isAdd}
      />
      <div className="o-content">
        <Table
          columns={columns}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
          setShowModal={setShowModal}
          setCurrentItem={setCurrentItem}
        />
      </div>
      {/*Add and edit invitation code modal*/}
      {addModal && (
        <AddInvitationCode
          addModal={addModal}
          setAddModal={setAddModal}
          currentItem={currentItem}
          refreshData={refreshDataList}
        />
      )}
      {/*Delete invitation code modal*/}
      {deleteModal && (
        <DeleteInvitationCode
          currentItem={currentItem}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          refreshData={refreshDataList}
        />
      )}
    </div>
  );
};

export default InvitationCode;
