import {  backendApiEndpoint} from '../constants.js'; //endpoint supprimé car jamais lu
import { CheckAuth,  instanceBackendApi } from '../index'; //instance supprimée car jamais lue 
import { toast } from 'react-toastify';

export async function deleteCodePromoReq(id) {
  try {
    const res = await instanceBackendApi.delete(backendApiEndpoint.code_promo + '/' + id);
    if (res.status === 200) {
      toast('Les données ont été supprimées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
