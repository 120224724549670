import * as Yup from 'yup';
import React from 'react';
import { ButtonTable } from '../../../components/button/index';

export const UserInitialValues = {
  email: '',
  group: '',
  permissions: '',
};
export const UserSchema = Yup.object().shape({
  email: Yup.string().email('E-mail invalide').required('Requis'),
  group: Yup.mixed().required('Requis'),
  permissions: Yup.mixed(),
});

export const columns = ['E-mail', 'Groupe', 'Permissions', 'Modif.', 'Supp.'];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.email.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.group.toLowerCase().includes(searchInput.toLowerCase())
    );
  });
  setFilteredData(result);
};

export const renderTable = (res, setAddModal, setDeleteModal, setSelectedID, isEdit, isDelete) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.id,
      email: item.email && item.email,
      group: item.group && item.group.name,
      permissions: item.permissions && (
        <div className="o-table-element__list">
          {item.permissions.map((permission, index) => (
            <span key={index} className="o-badge">
              {permission.name}
            </span>
          ))}
        </div>
      ),
      modif: isEdit ? (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedID(item.id);
            setAddModal(true);
          }}
        />
      ) : (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
      delete: isDelete ? (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
            setDeleteModal(true);
            setSelectedID(item.id);
          }}
        />
      ) : (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
    }),
  );
  return data;
};
