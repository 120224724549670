import moment from 'moment';

export const tableWalletHeader = ['Wallet ID','Treezor WalletId', 'Création', 'Mise à jour', 'Nom', 'Type', 'Statut', 'Solde', 'Balance Auth.'];
export const tableCardHeader = [
  'Card ID',
  'PAN',
  'Trz Card ID',
  'Type',
  'Nom complet du porteur',
  'Status',
  'Active',
  'Date d’expiration',
];
export const tableTransactionsHeader = ['Date de l’opération', 'Libellé', 'Type','Transaction Type','Statut', 'Montant Total', 'Arrondi', 'Transaction'];

export const renderWalletTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.walletId,
      walletID: item.walletId,
      trzWalletId: item.trzWalletId,
      createdDate: moment(item.created).format('DD/MM/YYYY'),
      updatedDate: moment(item.updated).format('DD/MM/YYYY'),
      name: item.name,
      type: item.type,
      status: item.status,
      balance: item.solde + ' €',
      authorizedBalance: item.authorized_balance + ' €',
    }),
  );
  return data;
};

export const renderCardTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.cardId,
      cardId: item.cardId,
      cardNumber: item.maskedPan,
      trzCardId: item.trzCardId,
      cardType : item.card_type,
      name: item.embossedName,
      status: item.status,
      active: item.activated ? 'Oui' : 'Non',
      expiryDate: moment(item.expiryDate).format('DD/MM/YYYY'),
    }),
  );
  return data;
};
export const renderTransactionsTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.walletLineId,
      date: moment(item.operation_date).format('DD/MM/YYYY'),
      label: item.label,
      type: item.type + (item.object?.mcc_code === '6011' ? '(ATM)' : ''),
      objectType: item.objectType,
      status: item.status,
      amount: (item.type === 'DEBIT' ? '-' : '') + item.amount + ' €', // Corrected error : Expected '===' and instead saw '=='
      roundAmount: '-' + (item.roundAmount ? item.roundAmount : '0.0') + ' €', 
      transactionAmount: (item.type === 'DEBIT' ? '-' : '') + (item.transactionAmount ? item.transactionAmount : item.amount) + ' €', // MODIF : Expected '===' and instead saw '=='
    }),
  );
  return data;
};
