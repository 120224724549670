import { backendApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceBackendApi } from '../../index';
import { toast } from 'react-toastify';

export async function walletBalanceHistory(userId, walletId, data) {
  try {
    let queryParams = { params: data};
    const res = await instanceBackendApi.get(backendApiEndpoint.wallet_balance_history.replace('{{userId}}', userId).replace('{{walletId}}', walletId), queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('WALLET BALANCE HISTORY - Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail! : Error :' + error.response?.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}
