import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { regenerateStatementReq } from '../../../../../api/client/statements';
import IconLoading from '../../../../../components/loading/loading.jsx';
import IconCross from '../../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';
import { useTranslation } from 'react-i18next';

const ConfirmationRegenerateStatement = ({
  statementId,
  regenerateStatementOpen,
  setRegenerateStatementOpen,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const regenerateHandler = async (id) => {
    setLoading(true);
    let status = await regenerateStatementReq(id);
    setLoading(false);
    setRegenerateStatementOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  const AddStatementInitialValues = {};
  const AddStatementSchema = Yup.object().shape({});

  return (
    <Modal isOpen={regenerateStatementOpen} toggle={setRegenerateStatementOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={AddStatementInitialValues}
        validationSchema={AddStatementSchema}
        onSubmit={(values) => {
          regenerateHandler(statementId);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img
              src={IconCross}
              alt="Close"
              className="icon-cancel"
              onClick={() => setRegenerateStatementOpen(false)}
            />
            <div className="o-modal-info__title-text o-title">
              Confirmez la regéneration du relevé de compte sélectionné ({statementId})
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button
                className="cancel" btnType="outline"
                onClick={() => setRegenerateStatementOpen(false)}
              >
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationRegenerateStatement;
