import global from './global/index.json';
import login from './login/index.json';
import sidebar from './sidebar/index.json';
import permission from './permission/index.json';
import group from './group/index.json';
import user from './user/index.json';
import document from './document/index.json';
import feature from './feature/index.json';
import type from './type/index.json';
import client from './client/index.json';
import bridge from './bridge/index.json';

import auth_response_code from './authorization_response_code.json';
import card_holder_presence from './card_holder_presence.json';
import pan_entry_method from './pan_entry_method.json';
import sdd_errors from './sdd_errors.json';

export default { // l'objet doit être assigné à une varaible avant d'être exporté
  global: {
    ...global,
  },
  login: {
    ...login,
  },
  sidebar: {
    ...sidebar,
  },
  permission: {
    ...permission,
  },
  group: {
    ...group,
  },
  user: {
    ...user,
  },
  document: {
    ...document,
  },
  feature: {
    ...feature,
  },
  type: {
    ...type,
  },
  client: {
    ...client,
  },
  bridge: {
    ...bridge,
  },
  auth_response_code: {
    ...auth_response_code,
  },
  card_holder_presence: {
    ...card_holder_presence,
  },
  pan_entry_method: {
    ...pan_entry_method,
  },
  sdd_errors: {
    ...sdd_errors,
  },
};
