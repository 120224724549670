import moment from 'moment';

//function renderBillingsHistoryArray(array){
//  return array.map((item, index) => <div>{ item }</div>);
//}
//function renderFeature(item){
//  return <div><div>{ (item.feature ? item.feature.name : '-') }</div>
//        <div>{ (item.transfer ? '"' + item.transfer.label + '"' : '') }</div>
//        </div>;
//}

export const tableStatementsHeader = ['ID', 'Date Début', 'Date de Fin','Wallet','Storage Path'];

export const renderStatementsTable = (res, setRegenerateModal, setDeleteModal, setCurrentItem, isEdit, isDelete) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.statementId,
      statement_id: item.statementId,
      begin_date : item.beginDate? moment(item.beginDate).format('DD/MM/YYYY') : '-',
      end_date: item.endDate? moment(item.endDate).format('DD/MM/YYYY') : '-',
      wallet_name: item.wallet ? '(' + item.wallet.walletId +') '+ item.wallet.name : item.walletId,
      storage_path: item.downloadGAEPath ? item.downloadGAEPath :'-',
    }),
  );
  return data;
};
