import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { deletePermissionReq } from '../../../../api/permission/permissions';
import IconCross from '../../../../assets/icons/cross.svg';
const DeletePermission = ({ deleteModal, setDeleteModal, currentItem }) => {
  const { t } = useTranslation();

  const deleteReq = () => {
    deletePermissionReq(currentItem).then((res) => {
      if (res === 'success') {
        setDeleteModal(false);
      }
    });
  };

  return (
    <Modal isOpen={deleteModal} toggle={setDeleteModal}>
      <div className="o-modal-info">
        <img
          src={IconCross}
          alt="Close"
          className="icon-cancel"
          onClick={() => {
            setDeleteModal(false);
          }}
        />
        <div className="o-modal-info__title-text">
          <div className="o-title">{t('permission:delete_permission')}</div>
          <div
            className="o-modal-info__title-text__text"
            dangerouslySetInnerHTML={{ __html: t('permission:delete_permission_description') }}
          />
        </div>
        <div className="o-btn-group">
          <Button type="submit" onClick={deleteReq}>
            {t('global:confirm')}
          </Button>
          <Button
            className="cancel" btnType="outline"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePermission;
