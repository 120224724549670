export const YesNo = [
  { label: 'Oui', value: true },
  { label: 'Non', value: false },
];

export const YesNoInteger = [
  { label: 'Oui', value: 1 },
  { label: 'Non', value: 0 },
];

export const CardLostStolenTypes = [
  { label: 'Carte Perdue', value: 'LOST' },
  { label: 'Carte volée', value: 'STOLEN' },
  { label: 'Carte detruite', value: 'DESTROYED' },
];

export const CardDigitalizationUpdateActions = [
  { label: 'Suspendre', value: 'SUSPEND' },
  { label: 'Réactiver', value: 'UNSUSPEND' },
];

export const CardDigitalizationUpdateReasons = [
  { label: 'DEVICE_LOST', value: 'DEVICE_LOST' , action : 'SUSPEND'},
  { label: 'DEVICE_STOLEN', value: 'DEVICE_STOLEN' , action : 'SUSPEND'},
  { label: 'FRAUDULENT_TRANSACTION', value: 'FRAUDULENT_TRANSACTION' , action : 'SUSPEND'},
  { label: 'SUSPEND_OTHER', value: 'SUSPEND_OTHER' , action : 'SUSPEND'},
  { label: 'DEVICE_FOUND', value: 'DEVICE_FOUND' , action : 'UNSUSPEND'},
  { label: 'NON_FRAUDULENT_TRANSACTION', value: 'NON_FRAUDULENT_TRANSACTION' , action : 'UNSUSPEND'},
  { label: 'UNSUSPEND_OTHER', value: 'UNSUSPEND_OTHER' , action : 'UNSUSPEND'},
];

export const CardDigitalizationDeleteReasons = [
  { label: 'DEVICE_LOST', value: 'DEVICE_LOST' },
  { label: 'DEVICE_STOLEN', value: 'DEVICE_STOLEN' },
  { label: 'FRAUDULENT_TRANSACTION', value: 'FRAUDULENT_TRANSACTION' },
  { label: 'FRAUDULENT_TRANSACTION_CONFIRM', value: 'FRAUDULENT_TRANSACTION_CONFIRM' },
  { label: 'ACCOUNT_CLOSE', value: 'ACCOUNT_CLOSE' },
  { label: 'OTHER', value: 'OTHER' },
];

export const AlertFraudStatus = [
  { label: 'Nouvelle Alerte', value: 'NEW' },
  { label: 'Infos demandées', value: 'INFO_ASKED' },
  { label: 'Vérification OK', value: 'CHECKED_OK' },
  { label: 'Fraude', value: 'FRAUD' },
];

export const WaitingListTypes = [
  { label: 'Demande de code d\'invitation', value: 'INVITATION_CODE' },
  { label: 'Demande d\'ouverture de compte', value: 'UNAVAILABLE' },
  { label: 'Pays de résidence non ouvert', value: 'COUNTRY' },
  { label: 'Client trop jeune', value: 'TOOYOUNG' },
  { label: 'Demande pour ETI/GE', value: 'ETI_GE' },
];

export const WaitingListStatus = [
  { label: 'Demande en attente', value: 'WAITING' },
  { label: 'Demande traitée', value: 'DONE' },
  { label: 'Envoi d\'un email suite à la demande', value: 'EMAIL_SENT' },
  { label: 'Demande refusée', value: 'REFUSED' },
  { label: 'Demande supprimée', value: 'DELETED' },
];
