import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../../components/button';
import { Formik, Form, Field } from 'formik';
import PermissionsContext from '../../../contexts/permissionContext';
import Head from '../../../components/head';
import TablePage from '../../../components/tablepage';
import { useTranslation } from 'react-i18next';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import FilterTabs from '../../../components/filterTabs';
import { columns, renderTable } from './client.status';
import { localStorageKeys } from '../../../api/constants.js';
import { searchClientPageReq } from '../../../api/client/searchClient';
import { getClientByStatusDownload } from '../../../api/client/getClient';
import IconLoading from '../../../components/loading/loading.jsx';
import IconDoc from '../../../assets/icons/doc.svg';
import {
  IconDownload,
  IconSearch,
  IconShare,
} from '../../../assets/icons/icons-search-download.jsx';
import { use } from 'i18next';
import RoadButton from '../../../components/roadButton/index.jsx'; // Import du bouton de changement de route

const ClientSearchByStatus = () => {
  const { t } = useTranslation();
  const { status } = useParams();
  const { permContext } = useContext(PermissionsContext);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [csvLoading, setCsvLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [sortDirection, setSortDirection] = useState('DESC');
  const [pageMetaData, setPageMetaData] = useState({});
  const [userPageMetaData, setUserPageMetaData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [displayPayin, setDisplayPayin] = useState(false);

  const downloadCsv = async (status) => {
    if (status) {
      setCsvLoading(true);
      const res = await getClientByStatusDownload(status).finally(() => setCsvLoading(false));
    }
  };

  const refreshUsersListPage = (values, pageNo, pageSize) => {
    // FONCTION QUI PERMET DE RECHARGER LA PAGE AVEC LE FILTRAGE PAR DEFAUT USERID
    setLoading(true);
    setDataStatus('loading');
    setFormValues(values);
    pageNo = pageNo != null ? pageNo : pageMetaData.pageNumber;
    pageSize = pageSize != null ? pageSize : pageMetaData.pageSize;
    values['display_payin'] =
      status === 'onboarding_finalized' || status === 'onboarding_waiting_payin';
    //const sortBy = 'userId';
    searchClientPageReq(values, status, pageNo, pageSize) //sortBy
      .then((res) => {
        let renderResult = JSON.stringify(res.content);
        localStorage.setItem(localStorageKeys.clients_searched + status, renderResult);
        setTableData(renderTable(status, res.content));
        setPageMetaData(res.pageable);
        setUserPageMetaData({ count: res.totalElements });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setDataStatus('done');
      });
  };

  // const FilterByStatus = (selectedStatus) => { // FONCTION QUI PERMET DE TRIER LES CLIENTS PAR STATUT
  //   setLoading(true);
  //   setDataStatus('loading');
  //   let pageNo = pageMetaData.pageNumber;
  //   let pageSize = pageMetaData.pageSize;
  //   searchClientPageReq({}, selectedStatus, pageNo, pageSize)
  //     .then((res) => {
  //       let renderResult = JSON.stringify(res.content);
  //       localStorage.setItem(localStorageKeys.clients_searched + selectedStatus, renderResult);
  //       setTableData(renderTable(selectedStatus, res.content));
  //       setPageMetaData(res.pageable);
  //       setUserPageMetaData({ count: res.totalElements });
  //     })
  //     .catch((error) => {
  //       console.error('Erreur lors de la recherche des clients:', error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //       setDataStatus('done');
  //     });
  // };

  const SortBy = (filter, isDescending) => {
    // FONCTION QUI PERMET DE TRIER LES CLIENTS PAR FILTRE (NOM, PRENOM, ETC.)
    setLoading(true);
    setDataStatus('loading');
    let pageNo = pageMetaData.pageNumber;
    let pageSize = pageMetaData.pageSize;
    const sortBy = filter;
    const newSortDirection = isDescending ? 'DESC' : 'ASC';
    const values = {};
    searchClientPageReq(values, status, pageNo, pageSize, sortBy, newSortDirection)
      .then((res) => {
        let renderResult = JSON.stringify(res.content);
        localStorage.setItem(localStorageKeys.clients_searched + status, renderResult);
        setTableData(renderTable(status, res.content));
        setPageMetaData(res.pageable);
        setUserPageMetaData({ count: res.totalElements });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setDataStatus('done');
      });
  };

  const refreshData = (pageNo, pageSize) => {
    refreshUsersListPage(formValues, pageNo, pageSize);
  };

  useEffect(() => {
    let isRead;
    isRead = permContext?.clientRead;
    if (permContext && !isRead) window.location.href = '/dashboard';

    setLoading(true);
    setDataStatus('loading');
    let searchedClients = localStorage.getItem(localStorageKeys.clients_searched + status);
    var tableDataList = [];
    try {
      tableDataList = renderTable(status, JSON.parse(searchedClients));
    } catch (e) {
      console.log('Problème de parsing de ' + searchedClients);
    } finally {
      setTableData(tableDataList);
    }
    setLoading(false);
    setDataStatus('done');
  }, [permContext, status]);

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${selectedID}?status=` + status;
  }, [showModal]);

  function DownloadButton(status) {
    return (
      <Fragment>
        <div className="ml-5">
          <Formik
            enableReinitialize={true}
            initialValues={SearchUserInitialValues}
            validationSchema={SearchUserSchema}
            onSubmit={(values) => {
              refreshUsersListPage(values, 0);
            }}
          >
            <Form className="form-inline">
              <div className="form-group">
                {status === 'onboarding_finalized' || status === 'onboarding_waiting_payin' ? (
                  <div>
                    <Field
                      type="checkbox"
                      name="with_payin"
                      className="ml-2"
                      label="with_payin"
                      placeholder="with_payin"
                    />
                    Avec virement entrant
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Form>
          </Formik>
        </div>
        <div>
          <Button
            btnType="outline"
            onClick={() => {
              downloadCsv(status);
            }}
            icon={csvLoading ? <IconLoading /> : <IconShare />}
          >
            Exporter
          </Button>
        </div>
      </Fragment>
    );
  }

  const SearchUserInitialValues = {
    with_payin: '',
  };
  const SearchUserSchema = Yup.object().shape({});

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head
            headTitle={t('sidebar:client')}
            HeadIcon={IconClient}
            setSelectedID={setSelectedID}
            isAdd={false}
            disabledSearch={true}
          />
          <RoadButton to="/dashboard/client_search" className="o-btn o-btn-road"></RoadButton>
        </div>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <FilterTabs />
          {DownloadButton(status)}
        </div>
        <TablePage
          columns={columns(status)}
          tableData={searchInput ? filteredData : tableData}
          pageMetaData={userPageMetaData}
          loadTableData={refreshData}
          status={dataStatus}
          SortBy={SortBy}
          selectedStatus={status}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
          ContainHeader={() => ''} //suppression de 'Clients' affiché initialement dans le header du tableau
          contain
        />
      </div>
    </div>
  );
};

export default ClientSearchByStatus;
