import React, { useState } from 'react';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { useTranslation } from 'react-i18next';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { updateCardLockUnlockReq } from '../../../../../api/client/card/lockCard';
import IconCheck from '../../../../../assets/icons/check.svg';
import IconCross from '../../../../../assets/icons/cross.svg';

const ConfirmationBlockCard = ({
  userID,
  card,
  blockModal,
  setBlockModal,
  status,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const [responseText, setResponseText] = useState('');
  const { t } = useTranslation();
  const LockUnlockAccountHandler = async () => {
    setLoading(true);
    const res = await updateCardLockUnlockReq(
      userID,
      card.wallet_id,
      card.cardId,
      status === 'UNLOCK' ? 'LOCK' : 'UNLOCK',
    );
    res ? setResponseText('success') : setResponseText('fail');
    refreshData();
    setLoading(false);
  };

  const close = () => {
    setBlockModal(false);
    setResponseText('');
  };

  const ShowResponseText = () => (
    <div style={{ textAlign: 'center', margin: '20px auto 30px auto' }}>
      {responseText} <br />
      {responseText === 'success' ? (
        <img className="d-flex mx-auto" style={{ marginTop: '20px' }} src={IconCheck} alt="" />
      ) : (
        <img className="d-flex mx-auto" style={{ marginTop: '20px' }} src={IconCross} alt="" />
      )}
    </div>
  );

  return (
    <Modal isOpen={blockModal} toggle={setBlockModal}>
      <div>
        <div className="o-modal-info">
          <img src={IconCross} alt="Close" className="icon-cancel" onClick={close} />
          <div className="o-modal-info__title-text o-title">
            {status === 'UNLOCK' ? 'Bloquer' : 'Débloquer'} la carte{' '}
          </div>

          {loading ? (
            <img
              className="d-flex mx-auto"
              style={{ margin: '20px auto 30px auto' }}
              src={IconLoading}
              height="25"
              alt=""
            />
          ) : responseText.length ? (
            <ShowResponseText />
          ) : (
            ''
          )}
          <div className="o-btn-group">
            {!responseText.length ? (
              <Button className="mb-2 mt-2" onClick={LockUnlockAccountHandler} type="submit">
                {t('global:confirm')}
              </Button>
            ) : (
              ''
            )}
            <Button className="cancel" btnType="outline" onClick={close}>
              {t('global:cancel')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationBlockCard;
