import React from 'react';

export const ArrowDown = (props) => {
  return (
    <svg width={20} height={20} fill="none" {...props}>
      <g>
        <path
          d="M9.364 13.293L3.707 7.636c-.41-.411-.41-1.003 0-1.414s1.003-.411 1.415 0l4.95 4.95 4.95-4.95c.41-.411 1.002-.411 1.413 0 .411.411.411 1.003 0 1.414l-5.657 5.657c-.41.411-1.003.411-1.414 0z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const ArrowLeft = (props) => {
  return (
    <svg width={12} height={13} viewBox="0 0 12 13" {...props}>
      <defs>
        <clipPath id="a">
          <path
            data-name="Rectangle 1825"
            transform="translate(.452)"
            fill="#fff"
            d="M0 0H12V13H0z"
          />
        </clipPath>
      </defs>
      <g data-name="Group 8302" transform="translate(-.452)" clipPath="url(#a)">
        <path
          data-name="Path 6295"
          d="M285.859 995.409l3.738 3.647a.65.65 0 00.934 0 .612.612 0 000-.912l-3.27-3.189 3.27-3.189a.611.611 0 000-.911.649.649 0 00-.934 0l-3.738 3.646a.608.608 0 000 .908z"
          transform="translate(-281.625 -988.512)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
export const ArrowRight = (props) => {
  return (
    <svg width={12} height={13} viewBox="0 0 12 13" {...props}>
      <defs>
        <clipPath id="a">
          <path transform="translate(-.088)" fill="#fff" d="M0 0H12V13H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 8303" transform="translate(.088)" clipPath="url(#a)">
        <path
          data-name="Path 6296"
          d="M384.215 994.5l-3.364-3.646a.544.544 0 00-.841 0 .657.657 0 000 .911l2.943 3.189-2.943 3.189a.658.658 0 000 .912.545.545 0 00.841 0l3.364-3.646a.655.655 0 000-.909z"
          transform="translate(-376.292 -988.512)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
