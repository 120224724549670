import { sidekickApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceSideKickApi } from '../../index';
import { toast } from 'react-toastify';



export async function isicCardListReq(userId, data) {

  try {
    const res = await instanceSideKickApi.get(sidekickApiEndpoint.isic_cards.replace('{{ooUserId}}', userId), data);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';

  }
}

export async function uploadCardFilesReq(userId, isicCardId, photoContent, proofContent) {

  try {
    var formData = new FormData();
    formData.append("photo", photoContent);
    formData.append("proof", proofContent);

    const res = await instanceSideKickApi.post(sidekickApiEndpoint.isic_card_detail_files.replace('{{ooUserId}}', userId).replace('{{isicCardId}}', isicCardId), formData);
    if (res.status === 200) {
      toast('Importation des fichiers de la carte faite !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.message);
    CheckAuth(error)
    return 'fail';
   
  }
}

export async function isicCardDetailReq(userId, isicCardId, data) {

  try {
    const res = await instanceSideKickApi.get(sidekickApiEndpoint.isic_card_detail.replace('{{ooUserId}}', userId).replace('{{isicCardId}}', isicCardId), data);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';
   
  }
}

export async function isicCardImageReq(userId, isicCardId, data) {

  try {
    const res = await instanceSideKickApi.get(sidekickApiEndpoint.isic_card_detail_image.replace('{{ooUserId}}', userId).replace('{{isicCardId}}', isicCardId), {responseType: "arraybuffer"});
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    var bufString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
    var jsonResult = JSON.parse(bufString);
    toast.error('Une erreur est survenue ! Error Code = ' + jsonResult.message);
    CheckAuth(error)
    return 'fail';
    
  }
}

export async function isicCardDeleteReq(userId, isicCardId) {

  try {
    const res = await instanceSideKickApi.delete(sidekickApiEndpoint.isic_card_detail.replace('{{ooUserId}}', userId).replace('{{isicCardId}}', isicCardId));
    if (res.status === 204) {
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';
   
  }
}

export async function isicCardImportReq(userId, data) {

  try {
    const res = await instanceSideKickApi.post(sidekickApiEndpoint.isic_card_import_isicnumber.replace('{{ooUserId}}', userId), data);
    if (res.status === 200) {
      toast('Importation de la carte faite !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.message);
    CheckAuth(error)
    return 'fail';
    
  }
}
