import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function addPartnershipReq(data) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.partnership_list, data);
    if (res.status === 200) {
      toast('Les données ont été enregistrées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!');
    CheckAuth(error);
  }
}


export async function getPartnershipsReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.partnership_list);
    return res.data;
  } catch (error) {
    CheckAuth(error);
  }
}

export async function updatePartnershipReq(data) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.partnership_detail.replace('{{ooPartnershipId}}' , data.code), {
      data,
    });
    if (res.status === 200) {
      toast('Les données ont été modifiées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!');
    CheckAuth(error);
    return 'fail';
  }
}
