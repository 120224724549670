import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';


export async function getWaitingListTypesReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.waitinglist_types);
    return res.data;
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
  }
}

export async function getWaitingListStatusReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.waitinglist_status);
    return res.data;
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
  }
}

export async function searchWaitingListPageReq(data, pageNo, pageSize) {
  pageNo = pageNo == null ? 0 : pageNo;
  pageSize = pageSize == null ? 100 : pageSize;
  let queryParams = {
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    params: {
      pageNo : pageNo,
      pageSize : pageSize,
      sortDirection : 'DESC',
  }};
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.waitinglist_search, JSON.stringify(data), queryParams);
    return res.data;
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
  }
}

export async function updateWaitingListReq(data) {
  try {
    console.info(data);
    const res = await instanceBackendApi.put(backendApiEndpoint.waitinglist, data);
    if (res.status === 200) {
      toast('Les données ont été enregistrées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
  }
}
