import React, { useState } from 'react';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { updateClientKycRefuse } from '../../../../api/client/updateClient';
import { useTranslation } from 'react-i18next';
import IconLoading from '../../../../components/loading/loading.jsx';
import IconCheck from '../../../../assets/icons/check.svg';
import IconCross from '../../../../assets/icons/cross.svg';

const ConfirmationKycRefuse = ({ kycRefuseOpen, setKycRefuseOpen, userData, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [responseText, setResponseText] = useState('');

  const KycRefuseClientHandler = async () => {
    setLoading(true);
    let status = await updateClientKycRefuse(userData.userId);
    status ? setResponseText(status) : setResponseText('fail');
    setLoading(false);
    if (status) {
      refreshData();
    }
  };

  const close = () => {
    setKycRefuseOpen(false);
    setResponseText('');
  };

  const ShowResponseText = () => (
    <div style={{ textAlign: 'center', margin: '20px auto 30px auto' }}>
      {responseText} <br />
      {responseText === 'success' ? (
        <img className="d-flex mx-auto" style={{ marginTop: '20px' }} src={IconCheck} alt="" />
      ) : (
        <img className="d-flex mx-auto" style={{ marginTop: '20px' }} src={IconCross} alt="" />
      )}
    </div>
  );

  return (
    <Modal isOpen={kycRefuseOpen} toggle={setKycRefuseOpen}>
      <div>
        <div className="o-modal-info">
          <img src={IconCross} alt="Close" className="icon-cancel" onClick={close} />
          <div className="o-modal-info__title-text o-title">Refuser le KYC du client ?</div>
          {loading ? (
            <img
              className="d-flex mx-auto"
              style={{ margin: '20px auto 30px auto' }}
              src={IconLoading}
              height="25"
              alt=""
            />
          ) : responseText.length ? (
            <ShowResponseText />
          ) : (
            ''
          )}
          <div className="o-btn-group">
            {!responseText.length ? (
              <Button className="warning" onClick={KycRefuseClientHandler} type="submit">
                {t('global:confirm')}
              </Button>
            ) : (
              ''
            )}

            <Button className="cancel" btnType="outline" onClick={close}>
              {t('global:back')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationKycRefuse;
