import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './dropdown.scss';

/*
  <Dropdown label="Permissions" placeholder="Permissions" options={options} isMulti />
*/
/*Conditions for the color of the underline to change in cases of disable, error and focus*/
const conditionsBorderColor = (error, state, onlyRead) => {
  if (state.isDisabled && !onlyRead) {
    return '1px solid var(--secondary-mid-2)';
  } else if (onlyRead) {
    return '1px solid var(--secondary)';
  } else {
    if (error) {
      return '1px solid var(--error)';
    } else {
      if (state.isFocused) {
        return '1px solid var(--primary)';
      } else {
        return '1px solid var(--stroke)';
      }
    }
  }
};

/*Conditions for the color of the arrow to change in cases of disable, error and focus*/
const conditionsArrowColor = (error, state, onlyRead) => {
  if (state.isDisabled && !onlyRead) {
    return ' var(--onSurface)';
  } else if (onlyRead) {
    return ' var(--white)';
  } else {
    if (error) {
      return 'var(--red)';
    } else {
      if (state.isFocused) {
        return 'var(--onSurfaceVariant)';
      } else {
        return 'var(--onSurfaceVariant)';
      }
    }
  }
};

const customStyles = (error, onlyRead) => {
  return {
    input: (base) => ({
      ...base,
      color: 'var(--onSurface)',
    }),
    placeholder: (base) => ({
      ...base,
      display: 'none',
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      borderRadius: '0.5rem',
      background: state.isDisabled ? 'var(--primary-dark)' : 'initial',
      '&:hover': {
        borderColor: state.isFocused
          ? error
            ? 'var(--red)'
            : 'var(--primary)'
          : error
          ? 'var(--red)'
          : 'var(--stroke)',
      },
      border: conditionsBorderColor(error, state, onlyRead),
      overflow: 'visible',
    }),
    valueContainer: (base) => ({
      ...base,
      overflow: 'visible',
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: '14px',
        backgroundColor: isFocused ? 'var(--background)' : 'var(--background)',
        background: isSelected ? 'var(--surface)' : 'var(--background)',
        color: isSelected ? 'var(--onPrimaryContainer)' : 'var(--onSurface)',
        '&:hover': {
          backgroundColor: 'var(--secondarySurface)',
          color: 'var(--onSurface)',
        },
      };
    },
    multiValue: (styles, { isDisabled }) => {
      return {
        ...styles,
        borderRadius: '50px',
        backgroundColor: '#10403B',
        color: isDisabled ? '#949494' : 'white',
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: 'white',
      fontSize: '10px',
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: data ? 'var(--onSurface)' : 'var(--onSurfaceVariant)',
    }),
    menu: (provided, state) => ({
      ...provided,
      borderRadius: '0.25rem',
      boxShadow: '0px 4px 4px 0px rgba(27, 27, 27, 0.25), 0px 0px 8px 0px rgba(27, 27, 27, 0.03)',
      color: 'var(--onSurface)',
      backgroundColor: 'var(--background)',
      zIndex: 1115,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: conditionsArrowColor(error, state, onlyRead),
      ':hover': {
        color: conditionsArrowColor(error, state, onlyRead),
      },
      pointerEvents: 'none',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
  };
};

const customTheme = (theme, error) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: error ? 'var(--red)' : 'var(--primary)',
      primary75: error ? 'var(--red)' : 'var(--primary-dark)',
      primary50: error ? 'var(--primary-dark)' : 'var(--primary-light)',
      primary25: error ? 'var(--primary)' : 'var(--secondary-light-1)',
      danger: 'var(--red)',
      neutral50: 'var(--secondary-mid-1)',
    },
    borderRadius: 0,
  };
};
const Dropdown = ({
  field,
  form: { errors, touched, setFieldValue, setFieldTouched },
  label,
  options,
  isMulti,
  disabled,
  onChange,
  supportText,
  onlyRead,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div
      className={` ${!disabled && touched[field.name] && errors[field.name] && 'o-dropdown-error'}`}
      style={{ cursor: disabled && 'not-allowed', width: '100%', position: 'relative' }}
    >
      <Select
        {...field}
        styles={customStyles(errors[field.name] && touched[field.name], onlyRead)}
        theme={(theme) => customTheme(theme, errors[field.name] && touched[field.name], onlyRead)}
        options={options}
        onBlur={() => {
          setFieldTouched(field.name, true);
          setIsFocused((prevState) => {
            if (field.value && field.value.label && prevState) {
              console.log('test');
              return prevState;
            }
            return !prevState;
          });
          console.log('onBlur');
        }}
        onFocus={() => {
          setIsFocused((prevState) => {
            if (field.value && field.value.label && prevState) {
              return prevState;
            }
            return !prevState;
          });
          console.log('onFocus');
        }}
        onChange={
          onChange
            ? onChange
            : (e) => {
                setFieldValue(field.name, e);
              }
        }
        isDisabled={onlyRead || disabled}
        searchable={false}
        isMulti={isMulti}
        noOptionsMessage={() => 'Pas d’options'}
        {...props}
      />
      <label
        className={` ${isFocused ? 'o-dropdown-label' : 'o-dropdown-label-not-focus'} `}
      >
        {label}
      </label>
      <span className="o-dropdown-message">
        {touched[field.name] && errors[field.name] ? errors[field.name] : supportText}
      </span>
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedOption: PropTypes.func,
  errorText: PropTypes.string,
  supportText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Dropdown;
