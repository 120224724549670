import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';

import Head from '../../../components/head';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import IconDoc from '../../../assets/icons/doc.svg';
import IconLoading from '../../../components/loading/loading.jsx';
import { columnsHistoryHttpTable, renderHistoryHttpTable } from './client.utils';
import { columnsSummaryHttpTable, renderSummaryHttpTable } from './client.utils';
import {
  historyClientHttpReq,
  summaryClientHttpReq,
  downloadClientHttpHistoryReq,
  summaryByCountryClientHttpReq,
  summaryByPlateformClientHttpReq,
} from '../../../api/client/historyClient';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { IconDownload } from '../../../assets/icons/icons-search-download.jsx';
import { Line, Pie } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

const pieData = (res) => {
  let dataLabels = [];
  res.map((item) => dataLabels.push(item.country));
  let datas = [];
  res.map((item) => datas.push(item.count));
  let result = {
    labels: dataLabels,
    datasets: [
      {
        label: '# / Pays',
        data: datas,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return result;
};

const piePlateformData = (res) => {
  let dataLabels = [];
  res.map((item) => dataLabels.push(item.plateform));
  let datas = [];
  res.map((item) => datas.push(item.count));
  let result = {
    labels: dataLabels,
    datasets: [
      {
        label: '# / Plateform',
        data: datas,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return result;
};

const emptyPieData = {
  labels: [],
  datasets: [
    {
      label: '# / Pays',
      data: [],
      borderWidth: 1,
    },
  ],
};

const emptyPiePlateformData = {
  labels: [],
  datasets: [
    {
      label: '# / Pays',
      data: [],
      borderWidth: 1,
    },
  ],
};

const ClientHistoryHttp = () => {
  const { id } = useParams();
  const [tableHistoryData, setTableHistoryData] = useState([]);
  const [tableSummaryData, setTableSummaryData] = useState([]);
  const [pieChartData, setPieChartData] = useState(emptyPieData);
  const [pieChartPlateformData, setPieChartPlateformData] = useState(emptyPiePlateformData);
  const [pieChartLoading, setPieChartLoading] = useState(false);
  const [pieChartPlateformLoading, setPieChartPlateformLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState('loading');
  const [csvLoading, setCsvLoading] = useState(false);

  useEffect(() => {
    historyClientHttpReq(id)
      .then((res) => {
        //console.log(res);
        setTableHistoryData(renderHistoryHttpTable(res));
      })
      .finally(() => setStatus('done'));

    summaryClientHttpReq(id)
      .then((res) => {
        //console.log(res);
        setTableSummaryData(renderSummaryHttpTable(res));
      })
      .finally(() => setStatus('done'));

    setPieChartLoading(true);
    summaryByCountryClientHttpReq(id)
      .then((res) => {
        //console.log(res);
        setPieChartData(pieData(res));
      })
      .finally(() => setPieChartLoading(false));

    setPieChartPlateformLoading(true);
    summaryByPlateformClientHttpReq(id)
      .then((res) => {
        //console.log(res);
        setPieChartPlateformData(piePlateformData(res));
      })
      .finally(() => setPieChartPlateformLoading(false));
  }, []); // manque 'id'

  const downloadCsv = async (userId) => {
    if (userId) {
      setCsvLoading(true);
      const res = await downloadClientHttpHistoryReq(userId);
      setCsvLoading(false);
    }
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Historique http client
        </div>
      </Fragment>
    );
  }

  function RightTitle() {
    return (
      <Button
        btnType="outline"
        className="ml-4 mb-4"
        onClick={() => {
          downloadCsv(id);
        }}
        icon={csvLoading ? <IconLoading /> : <IconDownload />}
      >
        Télécharger CSV
      </Button>
    );
  }

  return (
    <div>
      <Head
        headTitle={headTitle()}
        HeadIcon={IconClient}
        isAdd={false}
        disabledSearch
        rightFragment={RightTitle()}
      />
      <div className="d-flex o-content justify-content-center">
        <div>{pieChartLoading ? <IconLoading /> : <Pie data={pieChartData} />}</div>
        <div>
          {pieChartPlateformLoading ? <IconLoading /> : <Pie data={pieChartPlateformData} />}
        </div>
      </div>
      <div className="d-flex o-content">
        <div className="flex-fill mr-2 table-title">
          Historique Résumé
          <Table
            showModal={showModal}
            setShowModal={setShowModal}
            columns={columnsSummaryHttpTable}
            tableData={tableSummaryData}
            status={status}
            textLeft
            ContainHeader={() => ''}
            contain
            itemPerPage={10}
          />
        </div>
        <div className="flex-fill table-title">
          Historique Résumé
          <Table
            showModal={showModal}
            setShowModal={setShowModal}
            columns={columnsHistoryHttpTable}
            tableData={tableHistoryData}
            status={status}
            textLeft
            ContainHeader={() => ''}
            contain
            itemPerPage={10}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientHistoryHttp;
