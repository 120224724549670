import React, { useState } from 'react';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { useTranslation } from 'react-i18next';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { unlockPinReq } from '../../../../../api/client/card/forcePin';
import IconCross from '../../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationUnlockPinCard = ({ userID, card, unlockPinCardModal, setUnlockPinCardModal }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const unlockPinHandler = async () => {
    setLoading(true);
    let status = await unlockPinReq(userID, card.wallet_id, card.cardId);
    setLoading(false);
    if (status === 'success') {
      close();
    }
  };

  const close = () => {
    setUnlockPinCardModal(false);
  };

  return (
    <Modal isOpen={unlockPinCardModal} toggle={setUnlockPinCardModal}>
      <div className="o-modal-info">
        <img src={IconCross} alt="Close" className="icon-cancel" onClick={close} />
        <div className="o-modal-info__title-text o-title">
          Veuillez confirmer le déblocage de la carte.
        </div>
        <div className="o-btn-group">
          <Button className="mb-2 mt-2" onClick={unlockPinHandler} type="submit">
            {loading ? (
              <div className="d-flex mx-auto">
                <IconLoading />
              </div>
            ) : (
              t('global:confirm')
            )}
          </Button>
          <Button className="cancel" btnType="outline" onClick={close}>
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationUnlockPinCard;
