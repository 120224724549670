import React, { useState, useEffect } from 'react';
import { ReactComponent as IconChevronDown } from '../../assets/icons/_SmallIcons/ic_chevron_down.svg';
import { ReactComponent as IconChevronUp } from '../../assets/icons/_SmallIcons/ic_chevron_up.svg';

const SwitchButton = ({ onSort, clicked, type, item }) => {
  const [isDescending, setIsDescending] = useState(true);

  useEffect(() => {
    if (item === 'Mise à jour') { 
      onSort(isDescending);
    }
  }, [isDescending]); 

  const toggleChevron = () => {
    if (item === 'Mise à jour') {
      setIsDescending(!isDescending);
    }
    else {
      setIsDescending(!isDescending);
      onSort(!isDescending);
    }
  };

  return (
    <button
      onClick={toggleChevron}
      className={`chevron-button ${type} ${clicked ? 'clicked' : ''}`}
    >
      {isDescending ? (
        <IconChevronDown style={{ fill: 'var(--onPrimaryContainer)' }} />
      ) : (
        <IconChevronUp style={{ fill: 'var(--onPrimaryContainer)' }} />
      )}
      {clicked && <div className="chevron-button-circle"></div>}
    </button>
  );
};


export default SwitchButton;
