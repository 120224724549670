import moment from 'moment';

export const columns = ['Création','Mise à jour', 'UserId', 'E-mail', 'Nom', 'Date de naissance', 'Adresse', 'Statut', 'Code Promo', 'Bloqué'];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.email.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.firstname.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.id.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      value.status.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.address.toLowerCase().includes(searchInput.toLowerCase())||
      value.codePromo.toLowerCase().includes(searchInput.toLowerCase())
    );
  });
  setFilteredData(result);
};

export const renderTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.userId,
      createdDate: moment(item.created).format('DD/MM/YYYY HH:mm'),
      updatedDate: moment(item.updated).format('DD/MM/YYYY HH:mm'),
      id: item.userId,
      email: item.email,
      firstname: item.firstname + ' ' + item.lastname,
      birthdayDate : moment(item.birthday).format('DD/MM/YYYY'),
      address : item.postcode + ' ' + item.city,
      status: item.userStatus,
      codePromo: item.code_promo,
      blocked: item?.blocked ? 'Oui' : 'Non',
    }),
  );
  return data;
};
