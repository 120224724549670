import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Head from '../../../components/head';
import Table from '../../../components/table';
import Logo from '../../../components/logo';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { getClientGodchildrenReq } from '../../../api/client/getClient';
import { tableGodchildrenHeader, renderGodchildrenTable } from './godchildren.utils';

const ClientGodchildren = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [statusBeneficiariesTable, setStatusBeneficiariesTable] = useState('loading');
  const [godchildrenTableData, setGodchildrenTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cardTableData, setCardTableData] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [allBeneficiaries, setAllBeneficiaries] = useState([]);
  const [selectedGodchildren, setSelectedGodchildren] = useState();

  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    const godchildren = allBeneficiaries.find((x) => x.userId === selectedID);
    setSelectedGodchildren(godchildren);
    setSelectedItem('godchildren');
  }, [selectedID]);

  const refreshData = () => {
    setLoading(true);
    getClientGodchildrenReq(id)
      .then((res) => {
        setLoading(false);
        setAllBeneficiaries(res);
        setGodchildrenTableData(renderGodchildrenTable(res));
      })
      .finally(() => setStatusBeneficiariesTable('done'));
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Liste des filleuls
        </div>
      </Fragment>
    );
  }

  const renderSelectedGodchildrenActions = (godchildren) => {
    return <div className="o-information-detail__actions"></div>;
  };

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-detail">
        <div className="o-information-detail__block">
          <div className="o-information-detail__actions"></div>
        </div>
      </div>
      <div className="o-information-grid">
        <div className="mx-3 mb-3 table-title">
          Bénéficiaires
          <Table
            columns={tableGodchildrenHeader}
            tableData={godchildrenTableData}
            status={statusBeneficiariesTable}
            setShowModal={setShowModal}
            setSelectedID={setSelectedID}
            ContainHeader={() => ''}
            contain
          />
        </div>
        <div className="o-viewer">
          {selectedID && selectedItem === 'godchildren' && (
            <div>
              <div>
                <div className="mt-5">
                  <b>Informations Filleul</b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div>
                        <b>Godchildren ID</b>
                      </div>
                      <div>{selectedGodchildren?.userId}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Date de création</b>
                      </div>
                      <div>{moment(selectedGodchildren?.created).format('DD/MM/YYYY HH:mm')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Mise à jour</b>
                      </div>
                      <div>{moment(selectedGodchildren?.updated).format('DD/MM/YYYY HH:mm')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Statut</b>
                      </div>
                      <div>{selectedGodchildren?.userStatus}</div>
                    </div>
                  </div>
                  {/*<div className=" mt-2 ml-4">
                    <div>
                      <div>
                        <b>Trz Godchildren ID</b>
                      </div>
                      <div>{selectedGodchildren?.trzGodchildrenId}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Active</b>
                      </div>
                      <div>{selectedGodchildren?.activated ? 'Oui' : 'Non'}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Active</b>
                      </div>
                      <div>{selectedGodchildren?.usableForSct ? 'SCT' : 'SDDR'}</div>
                    </div>
                  </div>
                  */}
                </div>
                <div className="mt-5">
                  <b>Actions</b>
                  <hr />
                  {renderSelectedGodchildrenActions(selectedGodchildren)}
                </div>
              </div>
            </div>
          )}
          {!selectedID && <Logo className="o-viewer-logo" />}
        </div>
      </div>
    </div>
  );
};

export default ClientGodchildren;
