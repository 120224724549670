import * as Yup from 'yup';
import React from 'react';
import { ButtonTable } from '../../../components/button/index';

export const PackageFeatureInitialValues = {
  name: '',
  type: '',
  domain: { label: 'VARIOUS', value: 'VARIOUS' },
};
export const PackageFeatureSchema = Yup.object().shape({
  name: Yup.string().max(255, '255 car. max').required('Requis'),
  type: Yup.string().max(255, '255 car. max').required('Requis'),
  domain: Yup.string().max(255, '255 car. max').required('Requis'),
});

export const columns = ['Nom', 'Type', 'Domaine', 'Modif.', 'Supp.'];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.type.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.domain.toLowerCase().includes(searchInput.toLowerCase())
    );
  });
  setFilteredData(result);
};

export const renderTable = (
  res,
  setAddModal,
  setDeleteModal,
  setSelectedID,
  config,
  isEdit,
  isDelete,
) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.featureId,
      name: item.name,
      type:
        item.type &&
        config.featureTypes &&
        config.featureTypes.filter((e) => e.value === item.type)[0].label,
      domain:
        item.domain &&
        config.featureDomains &&
        config.featureDomains.filter((e) => e.value === item.domain)[0].label,
      modif: isEdit ? (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedID(item.featureId);
            setAddModal(true);
          }}
        />
      ) : (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
      delete: isDelete ? (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
            setDeleteModal(true);
            setSelectedID(item.featureId);
          }}
        />
      ) : (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
    }),
  );
  return data;
};
