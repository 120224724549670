import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function updateClientInfos(userId, data) {
  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_update_infos.replace('{{userId}}', userId), data);
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function updateClientLegalsInfos(userId, data) {
  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_update_legalinfos.replace('{{userId}}', userId), data);
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function updateClientPhone(userId, data) {
  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_update_phone.replace('{{userId}}', userId), data);
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function updateClientTrustLevel(userId, data) {
  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_update_trust_level.replace('{{userId}}', userId), data);
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function updateClientEmail(userId, data) {
  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_update_email.replace('{{userId}}', userId), data);
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function updateClientCodePromo(userId, data) {
  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_code_promo.replace('{{userId}}', userId), data);
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function deleteClientCodePromo(userId) {
  try {
    const res = await instanceBackendApi.delete(backendApiEndpoint.client_code_promo.replace('{{userId}}', userId));
    if (res.status === 204) {
      toast('Code Promo supprimé pour le client');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function updateClientAclRole(userId, data) {
  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_update_acl_role.replace('{{userId}}', userId), data);
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

// KYC

export async function updateClientKycAccept(userId) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.client_kyc_accept.replace('{{userId}}', userId));
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function updateClientKycRefuse(userId) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.client_kyc_refuse.replace('{{userId}}', userId));
    if (res.status === 200) {
      toast('Les données ont été mise à jour avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

// Commentaires sur le client

export async function addClientNote(userId, data) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.client_notes.replace('{{userId}}', userId), data);
    if (res.status === 200) {
      toast('Le nouveau commentaire a été ajouté');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}
