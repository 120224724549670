import React from 'react';

const PermissionsContext = React.createContext({
  permContext: {
    permissionRead: false,
    permissionUpdate: false,
    permissionDelete: false,

    groupRead: false,
    groupUpdate: false,
    groupDelete: false,

    userRead: false,
    userUpdate: false,
    userDelete: false,

    featureRead: false,
    featureUpdate: false,
    featureDelete: false,

    typeRead: false,
    typeUpdate: false,
    typeDelete: false,

    docRead: false,
    docUpdate: false,
    docDelete: false,

    codePromoRead: false,
    codePromoUpdate: false,
    codePromoDelete: false,

    adminRead: false,

    clientRead: false,
    clientUpdate: false,
    clientDelete: false,

    partnershipRead: false,
    partnershipUpdate: false,

    reportRead: false,

    userID: null,
    market: null,
  },
  setPermContext: () => {},
});

export default PermissionsContext;
