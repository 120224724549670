import moment from 'moment';

//function renderBridgeItemsArray(array){
//  return array.map((item, index) => <div>{ item }</div>);
//}

export const tableBridgeItemsHeader = ['ID', 'Date Création', 'Banque', 'Statut', 'Statut Bridge'];

export const renderBridgeItemsTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.itemId,
      itemId: item.itemId,
      created : item.created? moment(item.created).format('DD/MM/YYYY HH:mm') : '-',
      bank: item.bank ? item.bank.id + ' - ' + item.bank.name : '-',
      ooStatus: item.ooStatus ? item.ooStatus:'-',
      status: item.status ? item.status : '0',
    }),
  );
  return data;
};


export const tableBridgePaymentRequestHeader = ['ID', 'Date de Création', 'Statut','Status Reason'];

export const renderBridgePaymentRequestTable = (res, t, test) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.id,
      id: item.id,
      created : item.created? moment(item.created).format('DD/MM/YYYY HH:mm') : '-',
      status: item.status ? item.status + ' - ' + t('bridge:' + item.status) : '-',
      status_reason: item.status_reason ? item.status_reason + ' - ' + t('bridge:' + item.status_reason):'-',
    }),
  );
  return data;
};
