import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { activateCardReq } from '../../../../../api/client/card/card';
import IconCross from '../../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationActivateCard = ({
  userID,
  card,
  activateCardModal,
  setActivateCardModal,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const changeActivateHandler = async (data) => {
    setLoading(true);
    let status = await activateCardReq(userID, card.wallet_id, card.cardId, data);
    setLoading(false);
    if (status === 'success') {
      refreshData();
      close();
    }
  };

  const close = () => {
    setActivateCardModal(false);
  };

  const ActivateCardInitialValues = {};
  const ActivateCardSchema = Yup.object().shape({
    public_token: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={activateCardModal} toggle={setActivateCardModal}>
      <Formik
        enableReinitialize={true}
        initialValues={ActivateCardInitialValues}
        validationSchema={ActivateCardSchema}
        onSubmit={(values) => {
          changeActivateHandler(values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img src={IconCross} alt="Close" className="icon-cancel" onClick={close} />
            <div className="o-modal-info__title-text">
              <div className="o-title">ATTENTION CETTE OPERATION EST IRREVERSIBLE</div>
              <div className="o-title">
                Veuillez confirmer en saisissant le public token de la carte.
              </div>
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="public_token"
                label="Public Token de la carte *"
                placeholder="Public Token de la carte *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={close}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationActivateCard;
