import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import '../../../../components/modal/modal.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import { updateClientPhone } from '../../../../api/client/updateClient';
import { countryPhones } from '../../../../api/config/config';
import IconLoading from '../../../../components/loading/loading.jsx';
import IconCross from '../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationUpdatePhone = ({
  updatePhoneOpen,
  setUpdatePhoneOpen,
  userData,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const [countryPhonesData, setCountryPhonesData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    countryPhones(false).then((res) => {
      let phoneCodes = [];
      res.map((item) =>
        phoneCodes.push({
          label: item.name + ' (' + item.code + ' / +' + item.phone_code + ')',
          value: item.code,
        }),
      );
      setCountryPhonesData(phoneCodes);
    });
  }, []);

  const updateHandler = async (id, data) => {
    setLoading(true);
    data['mobile_country'] = data.mobile_country?.value;
    let status = await updateClientPhone(id, data);
    setLoading(false);
    setUpdatePhoneOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  const UpdatePhoneInitialValues = (data) => {
    return {
      oldMobile: data ? data.mobile : '',
      newMobile: '',
      confirmMobile: '',
      mobile_country: 'FR',
    };
  };
  const UpdatePhoneSchema = Yup.object().shape({
    oldMobile: Yup.string().required('Requis'),
    newMobile: Yup.string().required('Requis'),
    confirmMobile: Yup.string()
      .oneOf([Yup.ref('newMobile'), null], 'Les numéros de téléphone doivent être identiques')
      .required('Requis'),
    mobile_country: Yup.object().required('Requis'),
  });

  return (
    <Modal isOpen={updatePhoneOpen} toggle={setUpdatePhoneOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={UpdatePhoneInitialValues(userData)}
        validationSchema={UpdatePhoneSchema}
        onSubmit={(values) => {
          console.log('Delete client submit');
          updateHandler(userData.userId, values).then((res) => {
            if (res === 'success') {
              setUpdatePhoneOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img
              src={IconCross}
              alt="Close"
              className="icon-cancel"
              onClick={() => setUpdatePhoneOpen(false)}
            />
            <div className="o-modal-info__title-text">
              <div className="o-title">Modifier le numéro de téléphone</div>
              <div className="o-modal-info__title-text__text">
                Tous les numéros doivent être au format international
              </div>
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="oldMobile"
                label="Ancien numéro de téléphone"
                placeholder="Ancien numéro de téléphone *"
                component={InputFormik}
              />
              <Field
                name="newMobile"
                label="Nouveau numéro "
                placeholder="Nouveau numéro "
                component={InputFormik}
              />
              <Field
                name="confirmMobile"
                label="Confirmation du nouveau numéro "
                placeholder="Confirmation du nouveau numéro "
                component={InputFormik}
              />
              <Field
                name="mobile_country"
                //label="Code Pays de l'indicatif"
                //placeholder="Code Pays de l'indicatif *"
                component={Dropdown}
                options={countryPhonesData}
              />
            </div>
            <Button className="confirm-infos" type="submit">
              {loading ? (
                <div className="d-flex mx-auto">
                  <IconLoading />
                </div>
              ) : (
                t('global:confirm')
              )}
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdatePhone;
