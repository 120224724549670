import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function downloadDocument(userId, documentId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_documents_detail_download.replace('{{userId}}', userId).replace('{{documentId}}', documentId), {responseType: "arraybuffer"});
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
