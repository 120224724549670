import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';

export async function getCodePromoReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.code_promo);
    return res.data.sort((a,b) => a.code > b.code ? 1 : -1);
  } catch (error) {
    CheckAuth(error);
  }
}
