import React from 'react';

export function IconShare(props) {
    return (
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M11.3333 13.1667C11.3333 15.0067 9.84 16.5 8 16.5H3.33333C1.49333 16.5 0 15.0067 0 13.1667V8.50003C0 6.66003 1.49333 5.16669 3.33333 5.16669H4C4.36667 5.16669 4.66667 5.46669 4.66667 5.83336C4.66667 6.20003 4.36667 6.50003 4 6.50003H3.33333C2.23333 6.50003 1.33333 7.40003 1.33333 8.50003V13.1667C1.33333 14.2667 2.23333 15.1667 3.33333 15.1667H8C9.1 15.1667 10 14.2667 10 13.1667C10 12.8 10.3 12.5 10.6667 12.5C11.0333 12.5 11.3333 12.8 11.3333 13.1667ZM15.42 4.42669L11.8133 0.700026C11.56 0.43336 11.1333 0.426693 10.8733 0.686693C10.6133 0.946693 10.6 1.36669 10.86 1.62669L14.2867 5.16003H8.66667C6.82667 5.16003 5.33333 6.65336 5.33333 8.49336V11.8267C5.33333 12.1934 5.63333 12.4934 6 12.4934C6.36667 12.4934 6.66667 12.1934 6.66667 11.8267V8.49336C6.66667 7.39336 7.56667 6.49336 8.66667 6.49336H14.28L10.8533 10.0267C10.6 10.2934 10.6 10.7134 10.8667 10.9667C10.9933 11.0934 11.1667 11.1534 11.3333 11.1534C11.5 11.1534 11.68 11.0867 11.8133 10.9534L15.4133 7.24003C16.1933 6.46003 16.1933 5.19336 15.42 4.42003V4.42669Z" fill="var(--primary)"/>
      </svg>
    );
  }

export function IconDownload(props) {
    return (
      <span className="mr-2" alt="icon">
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Vector" d="M9.87798 18.122C10.1566 18.4008 10.4874 18.6219 10.8515 18.7728C11.2156 18.9237 11.6059 19.0014 12 19.0014C12.3941 19.0014 12.7844 18.9237 13.1485 18.7728C13.5126 18.6219 13.8434 18.4008 14.122 18.122L17.333 14.911C17.5051 14.7206 17.5975 14.4713 17.5909 14.2147C17.5843 13.958 17.4793 13.7138 17.2976 13.5325C17.1159 13.3512 16.8714 13.2467 16.6148 13.2406C16.3581 13.2346 16.109 13.3274 15.919 13.5L12.993 16.427L13 1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0C11.7348 0 11.4804 0.105357 11.2929 0.292893C11.1053 0.48043 11 0.734784 11 1L10.991 16.408L8.08098 13.5C7.89334 13.3125 7.6389 13.2072 7.37363 13.2073C7.10836 13.2074 6.85399 13.3129 6.66648 13.5005C6.47897 13.6881 6.37369 13.9426 6.37378 14.2079C6.37387 14.4731 6.47934 14.7275 6.66698 14.915L9.87798 18.122Z" fill="currentColor"/>
        <path id="Vector_2" d="M23 16C22.7348 16 22.4804 16.1054 22.2929 16.2929C22.1054 16.4804 22 16.7348 22 17V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V17C2 16.7348 1.89464 16.4804 1.70711 16.2929C1.51957 16.1054 1.26522 16 1 16C0.734784 16 0.48043 16.1054 0.292893 16.2929C0.105357 16.4804 0 16.7348 0 17L0 21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V17C24 16.7348 23.8946 16.4804 23.7071 16.2929C23.5196 16.1054 23.2652 16 23 16Z" fill="currentColor"/>
        </svg>
      </span>
    );
  }
  
  export function IconSearch(props) {
    return (
      <span alt="icon">
        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.707 22.293L17.738 16.324C19.3646 14.3346 20.1644 11.7961 19.9718 9.23356C19.7793 6.67103 18.6092 4.28053 16.7036 2.55652C14.798 0.832506 12.3026 -0.0931122 9.73364 -0.0288803C7.16469 0.0353515 4.7187 1.08452 2.90161 2.90161C1.08452 4.7187 0.0353515 7.16469 -0.0288803 9.73364C-0.0931122 12.3026 0.832506 14.798 2.55652 16.7036C4.28053 18.6092 6.67103 19.7793 9.23356 19.9718C11.7961 20.1644 14.3346 19.3646 16.324 17.738L22.293 23.707C22.4816 23.8892 22.7342 23.99 22.9964 23.9877C23.2586 23.9854 23.5094 23.8802 23.6948 23.6948C23.8802 23.5094 23.9854 23.2586 23.9877 22.9964C23.99 22.7342 23.8892 22.4816 23.707 22.293ZM10 18C8.41775 18 6.87103 17.5308 5.55543 16.6518C4.23984 15.7727 3.21446 14.5233 2.60896 13.0615C2.00346 11.5997 1.84503 9.99112 2.15371 8.43927C2.46239 6.88743 3.22432 5.46196 4.34314 4.34314C5.46196 3.22432 6.88743 2.46239 8.43927 2.15371C9.99112 1.84503 11.5997 2.00346 13.0615 2.60896C14.5233 3.21446 15.7727 4.23984 16.6518 5.55543C17.5308 6.87103 18 8.41775 18 10C17.9976 12.121 17.154 14.1544 15.6542 15.6542C14.1544 17.154 12.121 17.9976 10 18Z" fill="currentColor"/>
        </svg>
      </span>
    );
  }