import React, { useState, useEffect, useContext, Fragment } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Head from '../../../../components/head';
import Table from '../../../../components/table';
import Button from '../../../../components/button';
import Logo from '../../../../components/logo';
import { IconClient } from '../../../../assets/icons/icons-sidebar';
import IconCancel from '../../../../assets/icons/cancel.png';
import PermissionsContext from '../../../../contexts/permissionContext';
import { getTopupCardsReq } from '../../../../api/client/topupcards/topupcards';
import { tableTopupCardsHeader, renderTopupCardsTable } from './topupcards.utils';

const ClientTopupCards = () => {
  const { id, topupCardsId } = useParams();
  const { permContext } = useContext(PermissionsContext);
  const [loading, setLoading] = useState(false);
  const [statusTopupCardsTable, setStatusTopupCardsTable] = useState('loading');
  const [topupCardsTableData, setTopupCardsTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cardTableData, setCardTableData] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [allTopupCards, setAllTopupCards] = useState([]);
  const [selectedTopupCards, setSelectedTopupCards] = useState();
  const [deleteTopupCardsOpen, setDeleteTopupCardsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    refreshData();
  }, [permContext]);

  useEffect(() => {
    const topupCards = allTopupCards.find((x) => x.topupCardsId === selectedID);
    setSelectedTopupCards(topupCards);
    setSelectedItem('topupCards');
  }, [selectedID]);

  const refreshData = () => {
    setLoading(true);
    if (permContext?.market) {
      getTopupCardsReq(permContext?.market, id)
        .then((res) => {
          setLoading(false);
          setAllTopupCards(res);
          setTopupCardsTableData(renderTopupCardsTable(res));
        })
        .finally(() => setStatusTopupCardsTable('done'));
    }
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <div>{permContext?.market}</div>
          <a href="/dashboard/client">Liste client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Topups carte
        </div>
      </Fragment>
    );
  }

  const renderSelectedTopupCardsActions = (topupCards) => {
    return (
      <div className="o-information-detail__actions">
        {topupCards?.activated && topupCards?.status === 'VALIDATED' && topupCards?.usableForSct && (
          <Button btnType="outline" onClick={() => setDeleteTopupCardsOpen(true)}>
            <img className="mr-2" height="14" src={IconCancel} alt="" />
            Supprimer
          </Button>
        )}
      </div>
    );
  };

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-detail">
        <div className="o-information-detail__block">
          <div className="o-information-detail__actions"></div>
        </div>
      </div>
      <div className="o-information-grid">
        <div className="mx-3 mb-3 table-title">
          Topup Carte
          <Table
            columns={tableTopupCardsHeader}
            tableData={topupCardsTableData}
            status={statusTopupCardsTable}
            setShowModal={setShowModal}
            setSelectedID={setSelectedID}
            ContainHeader={() => ''}
            contain
          />
        </div>
        <div className="o-viewer">
          {selectedID && selectedItem === 'topupCards' && (
            <div>
              <div>
                <div className="mt-5">
                  <b>Informations Virement Programmé</b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div>
                        <b>TopupCards ID</b>
                      </div>
                      <div>{selectedTopupCards?.topupCardsId}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Date de création</b>
                      </div>
                      <div>{moment(selectedTopupCards?.created).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Mise à jour</b>
                      </div>
                      <div>{moment(selectedTopupCards?.updated).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Statut</b>
                      </div>
                      <div>{selectedTopupCards?.status}</div>
                    </div>
                  </div>
                  <div className=" mt-2 ml-4">
                    <div>
                      <div>
                        <b>Trz TopupCards ID</b>
                      </div>
                      <div>{selectedTopupCards?.trzTopupCardsId}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Active</b>
                      </div>
                      <div>{selectedTopupCards?.activated ? 'Oui' : 'Non'}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Active</b>
                      </div>
                      <div>{selectedTopupCards?.usableForSct ? 'SCT' : 'SDDR'}</div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <b>Actions</b>
                  <hr />
                  {renderSelectedTopupCardsActions(selectedTopupCards)}
                </div>
              </div>
            </div>
          )}
          {!selectedID && <Logo className="o-viewer-logo" />}
        </div>
      </div>
    </div>
  );
};

export default ClientTopupCards;
