import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { useTranslation } from 'react-i18next';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { updateCardLimts } from '../../../../../api/client/card/cardlimits';
import IconCross from '../../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationUpdateCardLimits = ({
  userID,
  card,
  updateLimitsModal,
  setUpdateLimitsModal,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const [cardLimits, setCardLimits] = useState();
  const { t } = useTranslation();
  const updateCardLimitsHandler = async (data) => {
    setLoading(true);
    console.log('card.cardLimits : ' + card.cardLimits);
    let status = await updateCardLimts(userID, card.wallet_id, card.cardId, data);
    setLoading(false);
    if (status === 'success') {
      refreshData();
      close();
    }
  };

  const close = () => {
    setUpdateLimitsModal(false);
  };

  const UpdateLimitsInitialValues = {
    limitAtmDay: card?.cardLimits?.limitAtmDay ? card.cardLimits.limitAtmDay : '0',
    limitAtmWeek: card?.cardLimits?.limitAtmWeek ? card.cardLimits.limitAtmWeek : '0',
    limitAtmMonth: card?.cardLimits?.limitAtmMonth ? card.cardLimits.limitAtmMonth : '0',
    limitPaymentDay: card?.cardLimits?.limitPaymentDay ? card.cardLimits.limitPaymentDay : '0',
    limitPaymentWeek: card?.cardLimits?.limitPaymentWeek ? card.cardLimits.limitPaymentWeek : '0',
    limitPaymentMonth: card?.cardLimits?.limitPaymentMonth
      ? card.cardLimits.limitPaymentMonth
      : '0',
  };
  const UpdateLimitsSchema = Yup.object().shape({
    limitAtmDay: Yup.number().required('Requis').min(0).integer(),
    limitAtmWeek: Yup.number().required('Requis').min(0).integer(),
    limitAtmMonth: Yup.number().required('Requis').min(0).integer(),
    limitPaymentDay: Yup.number().required('Requis').min(0).integer(),
    limitPaymentWeek: Yup.number().required('Requis').min(0).integer(),
    limitPaymentMonth: Yup.number().required('Requis').min(0).integer(),
  });

  return (
    <Modal isOpen={updateLimitsModal} toggle={setUpdateLimitsModal}>
      <Formik
        enableReinitialize={true}
        initialValues={cardLimits ? cardLimits : UpdateLimitsInitialValues}
        validationSchema={UpdateLimitsSchema}
        onSubmit={(values) => {
          console.log('Update card limits' + cardLimits);
          updateCardLimitsHandler(values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img src={IconCross} alt="Close" className="icon-cancel" onClick={close} />
            <div className="o-modal-info__title-text o-title">
              Modification des plafonds de la carte
            </div>

            <div className="o-modal-info__fields">
              <Field
                name="limitAtmDay"
                label="Limit Atm Day *"
                placeholder="Limit Atm Day *"
                component={InputFormik}
              />
              <Field
                name="limitAtmWeek"
                label="Limit Atm Week *"
                placeholder="Limit Atm Week *"
                component={InputFormik}
              />
              <Field
                name="limitAtmMonth"
                label="Limit Atm Month *"
                placeholder="Limit Atm Month *"
                component={InputFormik}
              />
              <Field
                name="limitPaymentDay"
                label="Limit Payment Day *"
                placeholder="Limit Payment Day *"
                component={InputFormik}
              />
              <Field
                name="limitPaymentWeek"
                label="Limit Payment Week *"
                placeholder="Limit Payment Week *"
                component={InputFormik}
              />
              <Field
                name="limitPaymentMonth"
                label="Limit Payment Month *"
                placeholder="Limit Payment Month *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={close}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateCardLimits;
