import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';

export async function getPackageTypeReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.membership_type_list);
    console.log(res);
    return res.data;
  } catch (error) {
    CheckAuth(error);
  }
}
