import React, { useEffect, useState } from 'react';
import './isic_card.scss';
import IconLoading from '../../components/loading/loading.jsx';
import { isicCardImageReq } from '../../api/client/isic/isiccards';

const IsicCard = ({ userId, isicCard }) => {
  const [loadingImage, setLoadingImage] = useState(true);
  const [binaryImg, setBinaryImg] = useState('');

  useEffect(() => {
    getIsicCardImageHandler();
    console.log('isicCard = ' + isicCard);
  }, [isicCard]); //manque 'getIsicCardImageHandler'

  const getIsicCardImageHandler = async () => {
    setLoadingImage(true);
    console.log('isicCard =', isicCard);
    if (isicCard.status === 'ACTIVE') {
      const data = await isicCardImageReq(userId, isicCard.cardid);
      setLoadingImage(false);
      if (data !== 'fail') {
        const file = new Blob([data], { type: 'image/jpeg', type: 'image/png' }); // clé dupliquée
        const fileUrl = URL.createObjectURL(file);
        const imageCarte = document.getElementById('isic_card_image');
        imageCarte.src = fileUrl;
      }
    }
    setLoadingImage(false);
  };

  return (
    <div>
      <div>
        {
          loadingImage ? (
            <div className="d-flex mx-auto">
              <IconLoading size={40} />
            </div>
          ) : (
            <img id="isic_card_image" className="o-isic-card-img" alt="" src="" />
          ) //le alt est vide
        }
      </div>
      <div className="o-isic-card">
        <div className="o-isic-card__container"></div>
        <div className="o-isic-card__number">{isicCard?.isic_card_values?.isic_number}</div>
        <div className="o-isic-card__container">
          <div>
            <div className="o-isic-card__label">Card Holder</div>
            <div className="o-isic-card__name">
              {isicCard?.isic_user_values?.firstname}&nbsp;{isicCard?.isic_user_values?.lastname}
            </div>
          </div>
          <div>
            <div className="o-isic-card__label">Expires</div>
            <div className="o-isic-card__name">
              {isicCard?.isic_card_values?.isic_stop_validity}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IsicCard;
