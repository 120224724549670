import moment from 'moment';

export const tableCardTransactionsHeader = ['Date transaction', 'Montant', 'Statut','MCC Code','Auth. Note', 'Auth. Response Code', 'Pan Entry Method'];

export const renderCardTransactionsTable = (res, t) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.walletLineId,
      date: moment(item.transaction_date).format('DD/MM/YYYY'),
      label: item.amount + ' €',
      type: item.status,
      objectType: item.mcc_code,
      status: item.authorization_note,
      amount: item.authorization_reponse_code ? item.authorization_reponse_code + ':' + t('auth_reponse_code:' + item.authorization_reponse_code) : '-',
      roundAmount: item.pan_entry_method ? item.pan_entry_method + ':' + t('pan_entry_method:' + item.pan_entry_method) : '-',
    }),
  );
  return data;
};
