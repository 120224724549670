import React from 'react';
import PropTypes from 'prop-types';
import './modal.scss';

const Modal = ({ isOpen, toggle, children, ...props }) => {
  return (
    <div
      id="modal"
      className="o-modal"
      style={{ display: isOpen ? 'flex' : 'none' }}
      onClick={(e) => {
        e.target.id === 'modal' && toggle(false);
      }}
    >
      <div className="o-modal-content" style={props.style}>{children}</div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.object,
};

export default Modal;
