import React from 'react';
import PropTypes from 'prop-types';
import './input.scss';
import TextareaAutosize from 'react-textarea-autosize';

const handleKeyDown = (event) => {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault();
  }
};

const Input = ({
  label,
  errorText,
  supportText,
  placeholder,
  className,
  multiline = false,
  ...props
}) => {
  return (
    <div className={`o-input-group ${errorText ? 'o-input-error' : ''} ${className ? className : ''}`}>
      {multiline ? (
        <TextareaAutosize
          className="o-input"
          type="text"
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          {...props}
        />
      ) : (
        <input
          className="o-input"
          type="text"
          placeholder={placeholder}
          {...props}
        />
      )}
      <label>{label}</label>
      <span className="o-input-subtext">{errorText ? errorText : supportText}</span>
    </div>
  );
};

export const InputFormik = ({
  field,
  form: { touched, errors },
  label,
  supportText,
  placeholder,
  className,
  onlyRead,
  disabled,
  type,
  multiline,
  ...props
}) => (
  <div className={`o-input-group  ${touched[field.name] && errors[field.name] ? 'o-input-error' : ''} ${className}`}>
    {type === 'number' || !multiline ? (
      <input
        className="o-input"
        type={type || 'text'}
        placeholder={placeholder}
        {...field}
        {...props}
        disabled={onlyRead || disabled}
      />
    ) : (
      <TextareaAutosize
        className="o-input"
        type="text"
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        {...field}
        {...props}
        disabled={onlyRead || disabled}
        style={onlyRead ? { color: 'black', borderBottomColor: 'black', cursor: 'default' } : {}}
      />
    )}

    <label>{label}</label>
    <span className="o-input-subtext">
      {touched[field.name] && errors[field.name] ? errors[field.name] : supportText}
    </span>
  </div>
);

Input.propTypes = {
  label: PropTypes.string,
  errorText: PropTypes.string,
  supportText: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  multiline: PropTypes.bool,
};

export default Input;
