import { backendApiEndpoint, fridayApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi, instanceFridayApi } from '../index';
import { toast } from 'react-toastify';

export async function countryPhones(onlyPhoneAvailable) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.config_country_phones + '?phone_available=' + onlyPhoneAvailable);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('COUNTRY PHONES - Une erreur est survenue!');
      return [];
    }
  } catch (error) {
    toast.error('COUNTRY PHONES - Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return [];
  }
}

export async function trustLevelsReq(userId, data) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.config_trust_level);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('TRUST LEVELS - Une erreur est survenue!');
      return [];
    }
  } catch (error) {
    toast.error('TRUST LEVELS - Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return [];
  }
}

export async function aclRolesReq(userId, data) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.config_user_acl_roles);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('ACL ROLES - Une erreur est survenue!');
      return [];
    }
  } catch (error) {
    toast.error('ACL ROLES - Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return [];
  }
}

export async function legalFormsReq(market) {
  try {
    const res = await instanceFridayApi.get(fridayApiEndpoint.config_legal_forms.replace('{{market}}', market) + '?level=3');
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('LEGAL FORMS - Une erreur est survenue!');
      return [];
    }
  } catch (error) {
    toast.error('LEGAL FORMS - Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return [];
  }
}

export async function incomeRangeReq(market) {
  try {
    const res = await instanceFridayApi.get(fridayApiEndpoint.config_income_range.replace('{{market}}', market));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('INCOME RANGE - Une erreur est survenue!');
      return [];
    }
  } catch (error) {
    toast.error('INCOME RANGE - Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return [];
  }
}

export async function personalAssetsReq(market) {
  try {
    const res = await instanceFridayApi.get(fridayApiEndpoint.config_personal_assets.replace('{{market}}', market));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('PERSONAL ASSETS RANGE - Une erreur est survenue!');
      return [];
    }
  } catch (error) {
    toast.error('PERSONAL ASSETS RANGE - Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return [];
  }
}

export async function legalAnnualNetIncomReq(market) {
  try {
    const res = await instanceFridayApi.get(fridayApiEndpoint.config_legal_annual_net_income.replace('{{market}}', market) );
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('ANNUAL NET INCOME - Une erreur est survenue!');
      return [];
    }
  } catch (error) {
    toast.error('ANNUAL NET INCOME - Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return [];
  }
}

export async function legalAnnualTurnoverReq(market) {
  try {
    const res = await instanceFridayApi.get(fridayApiEndpoint.config_legal_annual_turnover.replace('{{market}}', market) );
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('ANNUAL TURNOVER - Une erreur est survenue!');
      return [];
    }
  } catch (error) {
    toast.error('ANNUAL TURNOVER - Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return [];
  }
}

export async function legalNumberOfEmployeeReq(market) {
  try {
    const res = await instanceFridayApi.get(fridayApiEndpoint.config_legal_number_of_employees.replace('{{market}}', market) );
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('NUMBER EMPLOYEES - Une erreur est survenue!');
      return [];
    }
  } catch (error) {
    toast.error('NUMBER EMPLOYEES - Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return [];
  }
}
