import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function getInvitationCodeReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.invitation_code);
    return res.data.sort((a,b) => a.code > b.code ? 1 : -1);
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
  }
}

export async function detailInvitationCodeReq(code) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.invitation_code + '/' + code);
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function addInvitationCodeReq(data) {
  try {
    console.info(data);
    const res = await instanceBackendApi.post(backendApiEndpoint.invitation_code, data);
    if (res.status === 200) {
      toast('Les données ont été enregistrées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
  }
}

export async function deleteInvitationCodeReq(code) {
  try {
    const res = await instanceBackendApi.delete(backendApiEndpoint.invitation_code + '/' + code);
    if (res.status === 204) {
      toast('Les données ont été supprimées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
