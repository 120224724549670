import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Head from '../../../components/head';
import Table from '../../../components/table';
import Button from '../../../components/button';
import Logo from '../../../components/logo';
import IconEdit from '../../../assets/icons/edit.svg';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import IconCancel from '../../../assets/icons/cancel.png';
import IconLoading from '../../../components/loading/loading.jsx';
import {
  clientBeneficiaryReq,
  clientBeneficiaryRefreshReq,
} from '../../../api/client/beneficiary/beneficiary';
import { tableBeneficiaryHeader, renderBeneficiaryTable } from './beneficiary.utils';
import ConfirmationDeleteBeneficiary from './modals/beneficiary/confirmationDelete';
import ConfirmationAddBeneficiary from './modals/beneficiary/confirmationAdd';

const ClientBeneficiary = () => {
  const { id, beneficiaryId } = useParams();
  const [loading, setLoading] = useState(false);
  const [statusBeneficiariesTable, setStatusBeneficiariesTable] = useState('loading');
  const [beneficiaryTableData, setBeneficiaryTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cardTableData, setCardTableData] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [allBeneficiaries, setAllBeneficiaries] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [deleteBeneficiaryOpen, setDeleteBeneficiaryOpen] = useState(false);
  const [addBeneficiaryOpen, setAddBeneficiaryOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    refreshData();
  }, []); // manque 'refreshData'

  useEffect(() => {
    const beneficiary = allBeneficiaries.find((x) => x.beneficiaryId === selectedID);
    setSelectedBeneficiary(beneficiary);
    setSelectedItem('beneficiary');
  }, [selectedID]); // manque 'allBeneficiaries'

  const refreshData = () => {
    setLoading(true);
    clientBeneficiaryReq(id)
      .then((res) => {
        setLoading(false);
        setAllBeneficiaries(res);
        setBeneficiaryTableData(renderBeneficiaryTable(res));
      })
      .finally(() => setStatusBeneficiariesTable('done'));
  };

  const refreshBeneficiaryData = (id, beneficiaryId) => {
    setLoading(true);
    clientBeneficiaryRefreshReq(id, beneficiaryId).then((res) => {
      setLoading(false);
      refreshData();
    });
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Liste des bénéficiaires
        </div>
      </Fragment>
    );
  }

  const renderSelectedBeneficiaryActions = (beneficiary) => {
    return (
      <div className="o-information-detail__actions">
        {beneficiary?.activated &&
          beneficiary?.status === 'VALIDATED' &&
          beneficiary?.usableForSct && (
            <Button btnType="outline" onClick={() => setDeleteBeneficiaryOpen(true)}>
              <img className="mr-2" height="14" src={IconCancel} alt="" />
              Supprimer
            </Button>
          )}
        <Button btnType="outline" onClick={() => refreshBeneficiaryData(id, selectedID)}>
          {loading ? <IconLoading /> : <img className="mr-2" src={IconEdit} alt="" />}
          Refresh
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-detail">
        <div className="o-information-detail__block">
          <div className="o-information-detail__actions"></div>
        </div>
      </div>
      <div className="o-information-grid">
        <div className="mx-3 mb-3 table-title">
          Bénéficiaires
          <Table
            columns={tableBeneficiaryHeader}
            tableData={beneficiaryTableData}
            status={statusBeneficiariesTable}
            setShowModal={setShowModal}
            setSelectedID={setSelectedID}
            ContainHeader={() => ''}
            contain
          />
        </div>
        <div className="o-viewer">
          <Button btnType="outline" onClick={() => setAddBeneficiaryOpen(true)}>
            <img className="mr-2" src={IconEdit} alt="" />
            Ajouter un bénéficiaire
          </Button>
          {selectedID && selectedItem === 'beneficiary' && (
            <div>
              <div>
                <div className="mt-5">
                  <b>Informations Bénéficiaire</b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div>
                        <b>Beneficiary ID</b>
                      </div>
                      <div>{selectedBeneficiary?.beneficiaryId}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Date de création</b>
                      </div>
                      <div>{moment(selectedBeneficiary?.created).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Mise à jour</b>
                      </div>
                      <div>{moment(selectedBeneficiary?.updated).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Statut</b>
                      </div>
                      <div>{selectedBeneficiary?.status}</div>
                    </div>
                  </div>
                  <div className=" mt-2 ml-4">
                    <div>
                      <div>
                        <b>Trz Beneficiary ID</b>
                      </div>
                      <div>{selectedBeneficiary?.trzBeneficiaryId}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Active</b>
                      </div>
                      <div>{selectedBeneficiary?.activated ? 'Oui' : 'Non'}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Active</b>
                      </div>
                      <div>{selectedBeneficiary?.usableForSct ? 'SCT' : 'SDDR'}</div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <b>Actions</b>
                  <hr />
                  {renderSelectedBeneficiaryActions(selectedBeneficiary)}
                </div>
              </div>
            </div>
          )}
          {!selectedID && <Logo className="o-viewer-logo" />}
        </div>
      </div>
      {selectedID && (
        <ConfirmationDeleteBeneficiary
          deleteBeneficiaryOpen={deleteBeneficiaryOpen}
          setDeleteBeneficiaryOpen={setDeleteBeneficiaryOpen}
          userId={id}
          beneficiaryId={selectedID}
          refreshData={refreshData}
        />
      )}
      <ConfirmationAddBeneficiary
        addBeneficiaryOpen={addBeneficiaryOpen}
        setAddBeneficiaryOpen={setAddBeneficiaryOpen}
        userId={id}
        refreshData={refreshData}
      />
    </div>
  );
};

export default ClientBeneficiary;
