import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { collectATDClient } from '../../../../api/client/billing';
import IconLoading from '../../../../components/loading/loading.jsx';
import IconCross from '../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationCollectATD = ({ collectATDOpen, setCollectATDOpen, userData, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const collectATDHandler = async (userId, data) => {
    setLoading(true);
    console.log(data);
    data['userId'] = userId;
    let status = await collectATDClient(userId, data);
    setLoading(false);
    if (status === 'success') {
      setCollectATDOpen(false);
      refreshData();
    }
  };

  const CollectATDInitialValues = {
    label: 'Transfert ATD',
    amount: '',
  };
  const CollectATDSchema = Yup.object().shape({
    label: Yup.string().required('Requis'),
    amount: Yup.number().required('Requis').positive(),
  });

  return (
    <Modal isOpen={collectATDOpen} toggle={setCollectATDOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={CollectATDInitialValues}
        validationSchema={CollectATDSchema}
        onSubmit={(values) => {
          collectATDHandler(userData.userId, values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img
              src={IconCross}
              alt="Close"
              className="icon-cancel"
              onClick={() => setCollectATDOpen(false)}
            />
            <div className="o-modal-info__title-text o-title">
              Saisir les informations de la saisie d'Avis Tiers Detenteur
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="label"
                label="Libellé de l'ATD"
                placeholder="Libellé de l'ATD *"
                component={InputFormik}
              />
              <Field
                name="amount"
                label="Montant (€)"
                placeholder="Montant en €"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setCollectATDOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationCollectATD;
