import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { createTransferReq } from '../../../../api/transfers/transfers';
import { clientWalletListReq } from '../../../../api/client/wallet';
import { clientBeneficiaryReq } from '../../../../api/client/beneficiary/beneficiary';
import IconLoading from '../../../../components/loading/loading.jsx';
import Dropdown from '../../../../components/dropdown';
import IconCross from '../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationCreatePayout = ({
  createPayoutOpen,
  setCreatePayoutOpen,
  userId,
  refreshData,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [walletListDrp, setWalletListDrp] = useState();
  let walletOptions = [];
  const [beneficiaryListDrp, setBeneficiaryListDrp] = useState();
  let beneficiaryOptions = [];

  useEffect(() => {
    //For wallet dropdown
    if (createPayoutOpen) {
      clientWalletListReq(userId).then((res) => {
        res.map((item) =>
          walletOptions.push({ label: item.walletId + ' - ' + item.name, value: item.walletId }),
        );
        setWalletListDrp(walletOptions);
      });

      clientBeneficiaryReq(userId).then((res) => {
        res.map((item) =>
          beneficiaryOptions.push({
            label: item.beneficiaryId + ' - ' + item.name,
            value: item.beneficiaryId,
          }),
        );
        setBeneficiaryListDrp(beneficiaryOptions);
      });
    }
  }, [createPayoutOpen]);

  const createPayoutHandler = async (userId, data) => {
    setLoading(true);
    console.log(data);
    data['user_id'] = userId;
    data['walletId'] = data.walletId?.value;
    data['beneficiaryId'] = data.beneficiaryId?.value;
    let status = await createTransferReq(data);
    setLoading(false);
    if (status === 'success') {
      setCreatePayoutOpen(false);
      refreshData();
    }
  };

  const CreatePayoutInitialValues = {};
  const CreatePayoutSchema = Yup.object().shape({
    walletId: Yup.object().required('Requis'),
    beneficiaryId: Yup.object().required('Requis'),
    label: Yup.string().required('Requis'),
    amount: Yup.number().required('Requis').positive(),
  });

  return (
    <Modal isOpen={createPayoutOpen} toggle={setCreatePayoutOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={CreatePayoutInitialValues}
        validationSchema={CreatePayoutSchema}
        onSubmit={(values) => {
          createPayoutHandler(userId, values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img
              src={IconCross}
              alt="Close"
              className="icon-cancel"
              onClick={() => setCreatePayoutOpen(false)}
            />
            <div className="o-modal-info__title-text o-title">
              Saisir les informations du virement externe
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="walletId"
                label="Wallet source"
                placeholder="Wallet source *"
                options={walletListDrp}
                component={Dropdown}
              />
              <Field
                name="beneficiaryId"
                label="Bénéficiaire externe"
                placeholder="Bénéficiaire externe *"
                options={beneficiaryListDrp}
                component={Dropdown}
              />
              <Field name="label" label="Motif" placeholder="Motif *" component={InputFormik} />
              <Field
                name="amount"
                label="Montant du transfert (€)"
                placeholder="Montant du transfert en €"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setCreatePayoutOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationCreatePayout;
