import moment from 'moment';

export const tableGodchildrenHeader = ['ID', 'Nom', 'Statut', 'Validation'];

export const renderGodchildrenTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.userId,
      userId: item.userId,
      name: item.firstname + ' ' + item.lastname,
      status: item.userStatus,
      validation_date: item.validation_date ? moment(item.validation_date).format('DD/MM/YYYY HH:mm') : '',
    }),
  );
  return data;
};
