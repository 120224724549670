import * as Yup from 'yup';
import React from 'react';
import { ButtonTable } from '../../../components/button/index';

export const DocumentInitialValues = {
  name: '',
  activated: '',
  order: '',
  level: '',
  rectoVerso: '',
};
export const DocumentSchema = Yup.object().shape({
  name: Yup.string().max(255, '255 car. max').required('Requis'),
  activated: Yup.object().required('Requis'),
  order: Yup.number().integer().required('Requis'),
  level: Yup.string().max(255, '255 car. max').required('Requis'),
  rectoVerso: Yup.object().required('Requis'),
});

export const columns = [
  'Nom',
  'Activé',
  'Ordre',
  'Niveau',
  'Recto-Verso',
  'Doc. Treezor',
  'Doc. Vialink',
  'Modif.',
  'Supp.',
];
export const YesNo = [
  { label: 'Oui', value: true },
  { label: 'Non', value: false },
];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.order === Number(searchInput) ||
      value.level?.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.trzDocumentType?.toLowerCase().includes(searchInput.toLowerCase()) ||
      (value.vialinkDocumentType &&
        value.vialinkDocumentType?.toLowerCase().includes(searchInput.toLowerCase()))
    );
  });
  setFilteredData(result);
};

export const renderTable = (
  res,
  setAddModal,
  setDeleteModal,
  setSelectedID,
  config,
  isEdit,
  isDelete,
) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.documentTypeId,
      name: item.name,
      activated: item.activated ? 'Oui' : 'Non',
      order: item.order,
      level: item.level,
      rectoVerso: item.rectoVerso ? 'Oui' : 'Non',
      trzDocumentType:
        config &&
        config.trzDocumentType.filter((trz) => trz.value === item.trzDocumentType)[0]?.label,
      vialinkDocumentType: item.vialinkDocumentType,
      modif: isEdit ? (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedID(item.documentTypeId);
            setAddModal(true);
          }}
        />
      ) : (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
      delete: isDelete ? (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
            setDeleteModal(true);
            setSelectedID(item.documentTypeId);
          }}
        />
      ) : (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
    }),
  );
  return data;
};
