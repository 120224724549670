import React from 'react';
import IconCheck from '../../../assets/icons/check.svg';
import IconCancel from '../../../assets/icons/cancel.png';
import IconRedCross from '../../../assets/icons/red-cross.svg';

import moment from 'moment';

export const columns = ['Création','Mise à jour', 'UserId', 'E-mail', 'Nom', 'Statut', 'Bloqué', 'Fraude Suspect', 'Virement reçu']; // 'Entreprise' apreès 'Nom' supprimé, 'Date de naissance' supprimé, 'Adresse' supprimé
// export const columns = (status) => {
//   if (status === 'onboarding_finalized') {
//     return ['Création','Mise à jour', 'UserId', 'E-mail', 'Nom', 'Date de naissance', 'Adresse', 'Statut', 'Bloqué', 'Virement reçu'];
//   } else if (choosenStatus === 'onboarding_waiting_payin') {
//     return ['Création','Mise à jour', 'UserId', 'E-mail', 'Nom', 'Date de naissance', 'Adresse', 'Statut', 'Bloqué', 'Virement reçu'];
//   } else {
//     return ['Création','Mise à jour', 'UserId', 'E-mail', 'Nom', 'Date de naissance', 'Adresse', 'Statut', 'Bloqué', 'Fraude Suspect'];
//   }
// }
export const columnsPackageTable = [
  'Ordre',
  'Nom',
  'Type',
  'Type de fonctionnalité',
  'Valeur',
  'Description',
  'Activé',
  'Inclus',
];

//export const columnsEditable =[{"title" : 'Création', "sort": true, "sort_param":"created", "filterByStatus":true}]



export const columnsHistoryTable = ['Description', 'Date'];

export const dummyDataHistoryTable = [
  {
    description: 'Transaction entrante',
    date: '17/11/2020',
  },
  {
    description: 'Activation d’une CB',
    date: '17/11/2020',
  },
  {
    description: 'Upload d’un document',
    date: '17/11/2020',
  },
  {
    description: 'Prélèvement automatique',
    date: '17/11/2020',
  },
  {
    description: 'Transaction sortante',
    date: '17/11/2020',
  },
];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.createdDate.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.updatedDate.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.email.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.firstname.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.id.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      value.status.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.address.toLowerCase().includes(searchInput.toLowerCase())
    );
  });
  setFilteredData(result);
};

export const renderTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.userId,
      createdDate: moment(item.created).format('DD/MM/YYYY HH:mm'),
      updatedDate: moment(item.updated).format('DD/MM/YYYY HH:mm'),
      id: item.userId,
      email: item.email,
      firstname: item.firstname + ' ' + item.lastname,
      //company: item.legal_infos?.legal_name,
      //birthdayDate : moment(item.birthday).format('DD/MM/YYYY'),
      //address : item.postcode + ' ' + item.city,
      status: item.userStatus,
      blocked: item?.blocked ? 'Oui' : 'Non',
      fraudWarning: item?.fraud_warning ? 'Oui' : 'Non',
      receiverPayin: item?.payin_received ? <img className="mr-2" src={IconCheck} alt="" /> : <img className="mr-2" src={IconRedCross} alt="" />,
    }),
  );
  return data;
};

export const renderPackageTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.featureId,
      order: item.order,
      name: item.name,
      type: item.type,
      featureType: item.featureType,
      value: item.value,
      description: item.description,
      activated: item.activated ? (
        <img className="o-table-icon" src={IconCheck} alt="" />
      ) : (
        <img className="o-table-icon" src={IconCancel} alt="" />
      ),
      included: item.included ? (
        <img className="o-table-icon" src={IconCheck} alt="" />
      ) : (
        <img className="o-table-icon" src={IconCancel} alt="" />
      ),
    }),
  );
  return data;
};

export const columnsDocumentTable = ['Document ID','Trz Document ID','Création','Mise à jour', 'Statut', 'Type de document','Niveau', 'Nom'];

export const renderDocumentTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item,
      id: item.documentId,
      trzId: item.trzDocumentId,
      createdDate: item.created ? moment(item.created).format('DD/MM/YYYY HH:mm') : '-',
      updatedDate: item.updated ? moment(item.updated).format('DD/MM/YYYY HH:mm') : '-',
      status: item.documentStatus,
      type: item.type?.name,
      level: item.type?.level,
      name: item.fileName,
    }),
  );
  //console.log(data);
  return data;
};

export const columnsHistoryHttpTable = [
  'Date','IP','Pays','Ville',
];
export const renderHistoryHttpTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item,
      date: moment(item.date).format('DD/MM/YYYY HH:mm'),
      ipAddress: item.ipAddress,
      country: item.country,
      city: item.city,
    }),
  );
  //console.log(data);
  return data;
};


export const columnsSummaryHttpTable = [
  'IP','Pays','Ville','Nbre Appels',
];
export const renderSummaryHttpTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item,
      ipAddress: item.ipAddress,
      country: item.country,
      city: item.city,
      count: item.count,
    }),
  );
  //console.log(data);
  return data;
};
