export const tableBeneficiaryHeader = ['ID', 'Nom', 'Iban', 'BIC', 'Statut', 'SCT / SDDR'];

export const renderBeneficiaryTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.beneficiaryId,
      beneficiaryID: item.beneficiaryId,
      name: item.name,
      iban: item.iban,
      bic: item.bic,
      status: item.status,
      usableforsct: item.usableForSct ? 'SCT' : 'SDDR',
    }),
  );
  return data;
};
