//permission
export const ACL_READ_PERMISSION = 'ACL_READ_PERMISSION';
export const ACL_UPDATE_PERMISSION = 'ACL_UPDATE_PERMISSION';
export const ACL_DELETE_PERMISSION = 'ACL_DELETE_PERMISSION';

//group
export const ACL_READ_GROUP = 'ACL_READ_GROUP';
export const ACL_UPDATE_GROUP = 'ACL_UPDATE_GROUP';
export const ACL_DELETE_GROUP = 'ACL_DELETE_GROUP';

//user
export const ACL_READ_USER = 'ACL_READ_USER';
export const ACL_UPDATE_USER = 'ACL_UPDATE_USER';
export const ACL_DELETE_USER = 'ACL_DELETE_USER';

//document
export const ACL_READ_DOCUMENT_TYPE = 'ACL_READ_DOCUMENT_TYPE';
export const ACL_UPDATE_DOCUMENT_TYPE = 'ACL_UPDATE_DOCUMENT_TYPE';
export const ACL_DELETE_DOCUMENT_TYPE = 'ACL_DELETE_DOCUMENT_TYPE';

//Package Features
export const ACL_READ_MEMBERSHIP_FEATURE = 'ACL_READ_MEMBERSHIP_FEATURE';
export const ACL_UPDATE_MEMBERSHIP_FEATURE = 'ACL_UPDATE_MEMBERSHIP_FEATURE';
export const ACL_DELETE_MEMBERSHIP_FEATURE = 'ACL_DELETE_MEMBERSHIP_FEATURE';

//Types of Packages
export const ACL_READ_MEMBERSHIP_TYPE = 'ACL_READ_MEMBERSHIP_TYPE';
export const ACL_UPDATE_MEMBERSHIP_TYPE = 'ACL_UPDATE_MEMBERSHIP_TYPE';
export const ACL_DELETE_MEMBERSHIP_TYPE = 'ACL_DELETE_MEMBERSHIP_TYPE';

//Client
export const ACL_READ_CODE_PROMO = 'ACL_READ_CODE_PROMO';
export const ACL_UPDATE_CODE_PROMO = 'ACL_UPDATE_CODE_PROMO';
export const ACL_DELETE_CODE_PROMO = 'ACL_DELETE_CODE_PROMO';

//Client
export const ACL_READ_CLIENT = 'ACL_READ_CLIENT';
export const ACL_UPDATE_CLIENT = 'ACL_UPDATE_CLIENT';
export const ACL_DELETE_CLIENT = 'ACL_DELETE_CLIENT';
// KYC Client
export const ACL_READ_KYC = 'ACL_READ_KYC';
export const ACL_UPDATE_KYC = 'ACL_UPDATE_KYC';

// Partnership
export const ACL_READ_PARTNERSHIP = 'ACL_READ_PARTNERSHIP';
export const ACL_UPDATE_PARTNERSHIP = 'ACL_UPDATE_PARTNERSHIP';

// Analytics
export const ACL_READ_REPORT = 'ACL_READ_REPORT';
