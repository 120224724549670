import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { changePinReq } from '../../../../../api/client/card/forcePin';
import IconCross from '../../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationForcePin = ({ userID, card, forceCodeModal, setForceCodeModal }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const changePinHandler = async (data) => {
    setLoading(true);
    let status = await changePinReq(userID, card.wallet_id, card.cardId, data);
    setLoading(false);
    if (status === 'success') {
      close();
    }
  };

  const close = () => {
    setForceCodeModal(false);
  };

  const ForcePINInitialValues = {};
  const ForcePINSchema = Yup.object().shape({
    newPin: Yup.string().required('Requis'),
    confirmPin: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={forceCodeModal} toggle={setForceCodeModal}>
      <Formik
        enableReinitialize={true}
        initialValues={ForcePINInitialValues}
        validationSchema={ForcePINSchema}
        onSubmit={(values) => {
          console.log('Force PIN code submit');
          changePinHandler(values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img src={IconCross} alt="Close" className="icon-cancel" onClick={close} />
            <div className="o-modal-info__title-text o-title">
              Pour forcer le PIN de la carte il faut saisir le nouveau code PIN
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="newPin"
                label="Code PIN *"
                placeholder="Code PIN *"
                component={InputFormik}
              />
              <Field
                name="confirmPin"
                label="Confirmation du code PIN *"
                placeholder="Confirmation du code PIN *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={close}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationForcePin;
