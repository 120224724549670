import React from 'react';

export const columns = [
  'ID',
  'Débiteur',
  'Créditeur',
  'Mandat',
  'Reject Reason',
  'Transaction ID',
];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.code.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      value.mandate.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      value.creditor?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      value.debitor?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      value.transactionid.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      value.reject_reason.toString().toLowerCase().includes(searchInput.toLowerCase())
    );
  });
  setFilteredData(result);
};

export const renderTable = (res, t) => {
  let data = [];
  res.map((item) =>
    data.push({
      code: item.sepasddrid,
      debitor: <a href={`/dashboard/client/${item.user?.userId}/wallet/${item.wallet?.walletId}`}>{item.user?.firstname} {item.user?.lastname} ({item.user?.userId})</a>,
      creditor: item.beneficiary?.name,
      mandate: item.mandate_id,
      reject_reason: item.reject_reason_code ? item.reject_reason_code + ':' + t('sdd_errors:' + item.reject_reason_code) : '-',
      transactionid: item.transaction_id,
    }),
  );
  return data;
};
