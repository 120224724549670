import * as Yup from 'yup';
import React from 'react';
import { ButtonTable } from '../../../../components/button/index';

export const InvitationCodeInitialValues = {
  invitation_code: '',
  single_use: [],
};
export const InvitationCodeSchema = Yup.object().shape({
  invitation_code: Yup.string().max(50, '50 car. max').required('Requis'),
  single_use: Yup.object().required('Requis'),
});

export const columns = [
  'Code d\'invitation',
  'Usage unique',
  'Date d\'execution',
  'User ID',
  'Suppr.',
];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.invitation_code.toLowerCase().includes(searchInput.toLowerCase())
    );
  });
  setFilteredData(result);
};

export const renderTable = (res, setAddModal, setDeleteModal, setCurrentItem, isEdit, isDelete) => {
  let data = [];
  res.map((item) =>
    data.push({
      invitation_code: item.invitation_code,
      single_use: item?.single_use ? 'OUI' : 'NON',
      execution_date: item?.execution_date ? item.execution_date : '-',
      user_id: item?.user_id ? item?.user_id : '-',
      delete: isDelete ? (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
            setDeleteModal(true);
            setCurrentItem(item);
          }}
        />
      ) : (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
    }),
  );
  return data;
};
