import React, { useContext } from 'react';
import PermissionsContext from '../../contexts/permissionContext';
import { NavLink, useLocation } from 'react-router-dom';
import './filterTabs.scss';

const FilterTabs = (props) => {
    const { permContext } = useContext(PermissionsContext);
    const location = useLocation();

    return (
        <div className='o-filter'>
            <div className='o-filter__tabs'>
                {permContext?.clientRead && (
                    <NavLink
                    to="/dashboard/client"
                    >
                    <div className={`o-filter__tabs__tab ${
                        location.pathname === "/dashboard/client" ? "active" : ""
                    }`}>Prospects</div>
                    </NavLink>
                )}
                {permContext?.clientRead && (
                    <NavLink
                    to="/dashboard/clientbystatus/validated"
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === "/dashboard/clientbystatus/validated" ? "active" : ""
                                }`}>Clients actifs</div>
                    </NavLink>
                )}
                {permContext?.clientRead && (
                    <NavLink
                    to="/dashboard/clientbystatus/canceled"
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === "/dashboard/clientbystatus/canceled" ? "active" : ""
                                }`}>Clients désactivés</div>
                    </NavLink>
                )}
            </div>
        </div>
    )
}

export default FilterTabs;