import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../../components/button';
import Modal from '../../../../../components/modal';
import {
  AssignedPackageFeatureSchema,
  AssignedPackageFeatureInitialValues,
} from '../../assignedPackageFeature.utils';
import { YesNo } from '../../packageType.utils';
import Dropdown from '../../../../../components/dropdown';
import IconLoading from '../../../../../components/loading/loading.jsx';
import IconCross from '../../../../../assets/icons/cross.svg';

const ShowAssignedPackageFeature = ({ showModal, setShowModal, selectedID, features }) => {
  const { t } = useTranslation();
  const [initialValue, setInitialValue] = useState();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    if (selectedID) {
      const result = features.filter((item) => item.featureId === selectedID);
      const res = result[0];
      setInitialValue({
        name: res.name,
        type: { label: res.type, value: res.type },
        value: res.value,
        included: res.included ? YesNo[0] : YesNo[1],
        order: res.order,
        activated: res.activated ? YesNo[0] : YesNo[1],
        featureType: { label: res.featureType, value: res.featureType },
        description: res.description,
      });
      setStatus('done');
    }
  }, [selectedID, features]);

  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <div className="o-modal-info">
        <img
          src={IconCross}
          alt="Close"
          className="icon-cancel"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Formik
          enableReinitialize={true}
          initialValues={selectedID ? initialValue : AssignedPackageFeatureInitialValues}
          validationSchema={AssignedPackageFeatureSchema}
        >
          {() => (
            <Form>
              <div className="o-modal-info__title-text o-title">{t('feature:detail_feature')}</div>
              {status === 'loading' ? (
                <div className="d-flex mx-auto my-4">
                  <IconLoading size={40} />
                </div>
              ) : (
                <Fragment>
                  <div className="o-modal-info__fields">
                    <Field
                      name="type"
                      label={t('type:type')}
                      placeholder={t('type:type_placeholder') + ' *'}
                      component={Dropdown}
                      onlyRead
                    />
                    <Field
                      name="name"
                      label={t('type:name')}
                      placeholder={t('type:name_placeholder') + ' *'}
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="featureType"
                      label={t('type:featureType')}
                      placeholder={t('type:featureType') + ' *'}
                      component={Dropdown}
                      onlyRead
                    />
                    <Field
                      name="value"
                      label={t('type:value')}
                      placeholder={t('type:value') + ' *'}
                      type="number"
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="description"
                      label={t('type:description')}
                      placeholder={t('type:description_placeholder') + ' *'}
                      component={InputFormik}
                      onlyRead
                    />
                    <Field
                      name="activated"
                      label={t('type:activated')}
                      placeholder={t('type:activated_placeholder') + ' *'}
                      component={Dropdown}
                      options={YesNo}
                      onlyRead
                    />
                    <Field
                      name="included"
                      label={t('type:included')}
                      placeholder={t('type:included') + ' *'}
                      component={Dropdown}
                      options={YesNo}
                      onlyRead
                    />
                    <Field
                      name="order"
                      label={t('type:order')}
                      placeholder={t('type:order_placeholder') + ' *'}
                      type="number"
                      component={InputFormik}
                      onlyRead
                    />
                  </div>
                  <Button
                    className="cancel" btnType="outline"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    {t('global:back')}
                  </Button>
                </Fragment>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
export default ShowAssignedPackageFeature;
