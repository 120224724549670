import { backendApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceBackendApi } from '../../index';
import { toast } from 'react-toastify';

export async function handleRib(walletId) {
  try {
    const res = await instanceBackendApi.get(
      backendApiEndpoint.wallet_rib.replace('{{walletId}}', walletId),
      { responseType: 'arraybuffer' },
    );
    if (res.status === 200) {
      const file = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail! : Error :' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function generateRibReq(walletId) {
  let pdfWindow = window.open();
  pdfWindow.document.write('loading...');
  try {
    let queryParams = {
      params: { force: true },
      responseType: 'arraybuffer',
    };
    const res = await instanceBackendApi.get(
      backendApiEndpoint.wallet_rib.replace('{{walletId}}', walletId),
      queryParams,
    );
    if (res.status === 200) {
      const file = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      pdfWindow.location.href = fileURL;
    } else {
      pdfWindow.close();
      return 'fail';
    }
  } catch (error) {
    pdfWindow.close();
    toast.error('Fail! : Error :' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
