import { fridayApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceFridayApi } from '../../index';



export async function getTopupCardsReq(market, ooUserId) {
  try {
    const res = await instanceFridayApi.get(fridayApiEndpoint.client_topupcards.replace('{{market}}', market).replace('{{userId}}' , ooUserId));
    return res.data;
  } catch (error) {
    CheckAuth(error);
  }
}
