import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { addPartnershipReq } from '../../../../api/partnership/partnership';
import { PartnershipSchema, PartnershipInitialValues } from '../partnership.utils';
import IconCross from '../../../../assets/icons/cross.svg';

const AddPartnership = ({ addModal, setAddModal, currentItem }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState(PartnershipInitialValues);

  useEffect(() => {
    if (currentItem) {
      setInitialValues(currentItem);
    }
  }, [currentItem]);

  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={PartnershipSchema}
        onSubmit={(values) => {
          addPartnershipReq(values).then((res) => {
            if (res === 'success') {
              setTimeout(() => {
                window.location.href = '/dashboard';
              }, 700);
            }
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-modal-info">
              <img
                src={IconCross}
                alt="Close"
                className="icon-cancel"
                onClick={() => {
                  setAddModal(false);
                }}
              />
              <div className="o-modal-info__title-text o-title">
                {currentItem
                  ? t('partnership:modify_partnership')
                  : t('partnership:new_partnership')}
              </div>
              <div className="o-modal-info__fields">
                <Field
                  name="code"
                  label={t('partnership:unique_code')}
                  placeholder={t('partnership:unique_code_placeholder') + ' *'}
                  component={InputFormik}
                  disabled={currentItem}
                />
                <Field
                  name="name"
                  label={t('partnership:descriptive_name')}
                  placeholder={t('partnership:descriptive_name_placeholder') + ' *'}
                  component={InputFormik}
                />
                <Field
                  name="description"
                  label={t('partnership:full_description')}
                  placeholder={t('partnership:full_description_placeholder') + ' *'}
                  component={InputFormik}
                />
              </div>
              <div className="o-btn-group">
                <Button type="submit" disabled={!(props.isValid && props.dirty)}>
                  {currentItem ? t('global:validate') : t('global:add')}
                </Button>
                <Button
                  className="cancel" btnType="outline"
                  onClick={() => {
                    setAddModal(false);
                  }}
                >
                  {t('global:cancel')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddPartnership;
