//import React from 'react';  (jamais utilisé)

import moment from 'moment';

export const columnsNotifEventTable = [
  'Date','Code','Type',
];
export const renderNotifEventTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item,
      date: moment(item.created).format('DD/MM/YYYY HH:mm'),
      code: item.code,
      type: item.type,
    }),
  );
  return data;
};
