import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import { updateClientLegalsInfos } from '../../../../api/client/updateClient';
import {
  legalFormsReq,
  legalAnnualNetIncomReq,
  legalAnnualTurnoverReq,
  legalNumberOfEmployeeReq,
} from '../../../../api/config/config';
import IconLoading from '../../../../components/loading/loading.jsx';
import PermissionsContext from '../../../../contexts/permissionContext';
import IconCross from '../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationUpdateLegalInfos = ({
  updateLegalInfosOpen,
  setUpdateLegalInfosOpen,
  userData,
  refreshData,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [legalFormData, setLegalFormData] = useState([]);
  const [legalAnnuelTurnOverData, setLegalAnnuelTurnOverData] = useState([]);
  const [legalAnnuelNetIncomeData, setLegalAnnuelNetIncomeData] = useState([]);
  const [legalNumberOfEmployeeData, setLegalNumberOfEmployeeData] = useState([]);

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    /*Get legalform list*/
    legalFormsReq(permContext.market).then((res) => {
      let legalFormValues = [];
      res.map((item) =>
        legalFormValues.push({ value: item.code, label: item.code + ' - ' + item.name }),
      );
      setLegalFormData(legalFormValues);
    });

    /*Get annual turn over list*/
    legalAnnualTurnoverReq(permContext.market).then((res) => {
      let legalAnnuelTurnOverValues = [];
      res.map((item) =>
        legalAnnuelTurnOverValues.push({
          value: item.value,
          label: item.value + ' - ' + item.label,
        }),
      );
      setLegalAnnuelTurnOverData(legalAnnuelTurnOverValues);
    });

    /*Get anuual net income list*/
    legalAnnualNetIncomReq(permContext.market).then((res) => {
      let legalAnnuelNetIncomeValues = [];
      res.map((item) =>
        legalAnnuelNetIncomeValues.push({
          value: item.value,
          label: item.value + ' - ' + item.label,
        }),
      );
      setLegalAnnuelNetIncomeData(legalAnnuelNetIncomeValues);
    });

    /*Get legalform list*/
    legalNumberOfEmployeeReq(permContext.market).then((res) => {
      let legalNumberOfEmployeeValues = [];
      res.map((item) =>
        legalNumberOfEmployeeValues.push({
          value: item.value,
          label: item.value + ' - ' + item.label,
        }),
      );
      setLegalNumberOfEmployeeData(legalNumberOfEmployeeValues);
    });
  }, []);

  const updateHandler = async (id, values) => {
    setLoading(true);
    let reqParams = {
      legal_name: values.legal_name,
      legal_registration_number: values.legal_registration_number,
      legal_tva_number: values.legal_tva_number,
      legal_registration_date: values.legal_registration_date,
      legal_form: values?.legal_form ? values?.legal_form.value : null,
      legal_share_capital: values.legal_share_capital,
      legal_sector: values?.legal_sector ? values?.legal_sector : null,
      legal_annual_turn_over: values?.legal_annual_turn_over
        ? values?.legal_annual_turn_over.value
        : null,
      legal_annual_net_income: values?.legal_annual_net_income
        ? values?.legal_annual_net_income.value
        : null,
      legal_number_of_employee: values?.legal_number_of_employee
        ? values?.legal_number_of_employee.value
        : null,
    };
    let status = await updateClientLegalsInfos(id, reqParams);
    setLoading(false);
    setUpdateLegalInfosOpen(false);
    if (status) {
      refreshData();
    }
  };

  const UpdateLegalInfosInitialValues = (data) => {
    return {
      legal_name: data.legal_name ? data.legal_name : '',
      legal_registration_number: data.legal_registration_number
        ? data.legal_registration_number
        : '',
      legal_tva_number: data.legal_tva_number ? data.legal_tva_number : '',
      legal_registration_date: data.legal_registration_date ? data.legal_registration_date : '',
      legal_form: data.legal_form
        ? {
            value: data.legal_form?.code_insee_level_3,
            label:
              data.legal_form?.code_insee_level_3 + ' - ' + data.legal_form?.name_insee_level_3,
          }
        : {},
      legal_share_capital: data.legal_share_capital ? data.legal_share_capital : '',
      legal_sector: data.legal_sector ? data.legal_sector : '',
      legal_annual_turn_over: data.legal_annual_turn_over
        ? {
            value: data.legal_annual_turn_over,
            label: data.legal_annual_turn_over + ' - ' + data.legal_annual_turn_over,
          }
        : {},
      legal_annual_net_income: data.legal_annual_net_income
        ? {
            value: data.legal_annual_net_income,
            label: data.legal_annual_net_income + ' - ' + data.legal_annual_net_income,
          }
        : {},
      legal_number_of_employee: data.legal_number_of_employee
        ? {
            value: data.legal_number_of_employee,
            label: data.legal_number_of_employee + ' - ' + data.legal_number_of_employee,
          }
        : {},
    };
  };

  const UpdateLegalInfosSchema = Yup.object().shape({});

  return (
    <Modal isOpen={updateLegalInfosOpen} toggle={setUpdateLegalInfosOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={UpdateLegalInfosInitialValues(userData?.legal_infos)}
        validationSchema={UpdateLegalInfosSchema}
        onSubmit={(values) => {
          updateHandler(userData.userId, values).then((res) => {
            if (res === 'success') {
              setUpdateLegalInfosOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img
              src={IconCross}
              alt="Close"
              className="icon-cancel"
              onClick={() => setUpdateLegalInfosOpen(false)}
            />
            <div className="o-modal-info__title-text o-title">
              Pour confirmer la modification des informations du client
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="legal_name"
                label={t('client:legal_name')}
                placeholder={t('client:legal_name') + ' *'}
                component={InputFormik}
              />
              <Field
                name="legal_registration_number"
                label={t('client:legal_registration_number')}
                placeholder={t('client:legal_registration_number') + ' *'}
                component={InputFormik}
              />
              <Field
                name="legal_tva_number"
                label={t('client:legal_tva_number')}
                placeholder={t('client:legal_tva_number') + ' *'}
                component={InputFormik}
              />
              <Field
                name="legal_registration_date"
                label={t('client:legal_registration_date')}
                placeholder={t('client:legal_registration_date') + ' *'}
                component={InputFormik}
              />
              <Field
                name="legal_form"
                label={t('client:legal_form')}
                placeholder={t('client:legal_form') + ' *'}
                component={Dropdown}
                options={legalFormData}
              />
              <Field
                name="legal_share_capital"
                label={t('client:legal_share_capital')}
                placeholder={t('client:legal_share_capital') + ' *'}
                component={InputFormik}
              />
              <Field
                name="legal_sector"
                label={t('client:legal_sector') + '([0-9][0-9][0-9][0-9][A-Z])'}
                placeholder={t('client:legal_sector') + ' ([0-9][0-9][0-9][0-9][A-Z]) *'}
                component={InputFormik}
              />
              <Field
                name="legal_annual_turn_over"
                label={t('client:legal_annual_turn_over')}
                placeholder={t('client:legal_annual_turn_over') + ' *'}
                component={Dropdown}
                options={legalAnnuelTurnOverData}
              />
              <Field
                name="legal_annual_net_income"
                className="mt-2"
                label={t('client:legal_annual_net_income')}
                placeholder={t('client:legal_annual_net_income') + ' *'}
                component={Dropdown}
                options={legalAnnuelNetIncomeData}
              />
              <Field
                name="legal_number_of_employee"
                label={t('client:legal_number_of_employee')}
                placeholder={t('client:legal_number_of_employee') + ' *'}
                component={Dropdown}
                options={legalNumberOfEmployeeData}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button
                className="cancel" btnType="outline"
                onClick={() => setUpdateLegalInfosOpen(false)}
              >
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateLegalInfos;
