import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './creditCard.scss';
import MasterCard from '../../assets/img/mastercard.svg';
import Card from '../../assets/img/card.png';
import IconLoading from '../../components/loading/loading.jsx';
import { cardDetailImageReq } from '../../api/client/card/card';

const CreditCard = ({ cardNumber, name, date, cardImageUrl }) => {
  const [loadingImage, setLoadingImage] = useState(true);

  useEffect(() => {
    getCardImageHandler(cardImageUrl);
    console.log('cardImageUrl = ' + cardImageUrl);
  }, [cardImageUrl]);

  const getCardImageHandler = async (cardImageUrl) => {
    const imageCarte = document.getElementById('card_image');
    if (cardImageUrl) {
      imageCarte.src = null;
      setLoadingImage(true);
      const index = cardImageUrl.indexOf('static.onlyone');
      if (index !== -1) {
        imageCarte.src = cardImageUrl;
      } else {
        const data = await cardDetailImageReq(cardImageUrl);
        const file = new Blob([data], { type: 'image/jpeg', type: 'image/png' });
        const fileUrl = URL.createObjectURL(file);
        imageCarte.src = fileUrl;
      }
      setLoadingImage(false);
    }
  };

  return (
    <div>
      <div>
        {loadingImage ? (
          <div className="d-flex mx-auto">
            <IconLoading size={40} />
          </div>
        ) : (
          ''
        )}
        <img id="card_image" className="o-credit-card-img" alt="" src="" hidden={loadingImage} />
      </div>
      <div className="o-credit-card">
        <div className="o-credit-card__container">
          <img className="o-credit-card__img" src={Card} alt="" height="36" />
          <img className="o-credit-card__img" src={MasterCard} alt="" height="25" />
        </div>
        <div className="o-credit-card__number">
          {cardNumber
            ?.toString()
            .replace(
              /^(.{4})(.{4})(.{4})(.{4})$/,
              '$1 \xa0\xa0\xa0 $2 \xa0\xa0\xa0 $3 \xa0\xa0\xa0 $4',
            )}
        </div>
        <div className="o-credit-card__container">
          <div>
            <div className="o-credit-card__label">Card Holder</div>
            <div className="o-credit-card__name">{name}</div>
          </div>
          <div>
            <div className="o-credit-card__label">Expires</div>
            <div className="o-credit-card__name">{moment(date).format('MM/YY')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
