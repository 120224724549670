import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { deleteClientReq } from '../../../../api/client/deleteClient';
import IconLoading from '../../../../components/loading/loading.jsx';
import IconCross from '../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationDeleteInfo = ({ deleteInfoOpen, setDeleteInfoOpen, userId, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const deleteHandler = async (id, confirmName) => {
    setLoading(true);
    let status = await deleteClientReq(id, confirmName);
    setLoading(false);
    setDeleteInfoOpen(false);
    if (status === 'success') {
      //Correct error : Expected '===' and instead saw '=='     eqeqeq
      refreshData();
    }
  };

  const DeleteClientInitialValues = {
    confirmLastnameUppercase: '',
  };
  const DeleteClientSchema = Yup.object().shape({
    confirmLastnameUppercase: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={deleteInfoOpen} toggle={setDeleteInfoOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={DeleteClientInitialValues}
        validationSchema={DeleteClientSchema}
        onSubmit={(values) => {
          console.log('Delete client submit');
          deleteHandler(userId, values['confirmLastnameUppercase']).then((res) => {
            if (res === 'success') {
              setDeleteInfoOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img
              src={IconCross}
              alt="Close"
              className="icon-cancel"
              onClick={() => setDeleteInfoOpen(false)}
            />
            <div className="o-modal-info__title-text o-title">
              Pour confirmer la suppression du client, veuillez saisir en majuscule le nom du client
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="confirmLastnameUppercase"
                label="Nom du client en MAJUSCULE"
                placeholder="Nom du client en MAJUSCULE *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button className="warning" btnType="outline" type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" className="cancel" btnType="outline" onClick={() => setDeleteInfoOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteInfo;
