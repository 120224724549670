import React, { useState } from 'react';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';

import { Formik, Form } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { isicCardDeleteReq } from '../../../../../api/client/isic/isiccards';
import IconLoading from '../../../../../components/loading/loading.jsx';
import IconCross from '../../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationDeleteIsicCard = ({
  deleteIsicCardOpen,
  setDeleteIsicCardOpen,
  userId,
  isicCard,
  refreshData,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const deleteHandler = async (id, isicCardId) => {
    setLoading(true);
    let status = await isicCardDeleteReq(id, isicCardId);
    setLoading(false);
    setDeleteIsicCardOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  const DeleteIsicCardInitialValues = () => {
    return {};
  };
  const DeleteIsicCardSchema = Yup.object().shape({});

  return (
    <Modal isOpen={deleteIsicCardOpen} toggle={setDeleteIsicCardOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={DeleteIsicCardInitialValues()}
        validationSchema={DeleteIsicCardSchema}
        onSubmit={(values) => {
          deleteHandler(userId, isicCard.cardid).then((res) => {
            if (res === 'success') {
              setDeleteIsicCardOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img
              src={IconCross}
              alt="Close"
              className="icon-cancel"
              onClick={() => setDeleteIsicCardOpen(false)}
            />
            <div className="o-modal-info__title-text o-title">Suppression de la carte ISIC</div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setDeleteIsicCardOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteIsicCard;
