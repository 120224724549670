import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function clientDocumentReq(id) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_documents.replace('{{userId}}', id));
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
