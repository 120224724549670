import React, { useState, useEffect, useContext, Fragment } from 'react';
import * as Yup from 'yup';

import Button from '../../../components/button';
import { Formik, Form, Field } from 'formik';
import Dropdown from '../../../components/dropdown';
import PermissionsContext from '../../../contexts/permissionContext';
import Head from '../../../components/head';
import Table from '../../../components/table';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { columns, renderTable, tableSearch } from './client_by_codepromo.utils';
import { getCodePromoReq } from '../../../api/codepromo/getCodePromo';
import {
  searchClientByCodePromo,
  getClientByCodePromoDownload,
} from '../../../api/client/searchClient';
import IconLoading from '../../../components/loading/loading.jsx';
import { IconDownload, IconSearch } from '../../../assets/icons/icons-search-download.jsx';

const ClientSearchByCodePromo = () => {
  const { permContext } = useContext(PermissionsContext);
  const [loading, setLoading] = useState(false);
  const [codePromoData, setCodePromoData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('done');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [formData, setFormData] = useState();
  const [csvLoading, setCsvLoading] = useState(false);

  const downloadCsv = async (data) => {
    if (tableData && tableData.length > 0) {
      setCsvLoading(true);

      const res = await getClientByCodePromoDownload(data).finally(() => setCsvLoading(false));
    }
  };

  const searchUserHandler = async (values) => {
    setLoading(true);
    setDataStatus('loading');
    let reqParams = { code_promo: values.code_promo?.value };
    setFormData(reqParams);
    searchClientByCodePromo(reqParams)
      .then((res) => {
        /*Render and set data of the list*/
        setTableData(renderTable(res));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setDataStatus('done');
        setLoading(false);
      });
  };

  useEffect(() => {
    let isRead;

    isRead = permContext?.clientRead;
    if (permContext && !isRead) window.location.href = '/dashboard';

    if (permContext) {
      /*Get data of the list*/
      getCodePromoReq().then((res) => {
        let codePromoValues = [{ label: 'Tous les codes', value: '' }];
        res.map((item) => codePromoValues.push({ label: item.code, value: item.code }));
        setCodePromoData(codePromoValues);
      });
    }
  }, [permContext]);

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${selectedID}`;
  }, [showModal]);

  useEffect(() => {
    tableSearch(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  const SearchUserInitialValues = {};
  const SearchUserSchema = Yup.object().shape({});

  function HeaderFragmentRight() {
    return (
      <Fragment>
        <div className="ml-auto"></div>
      </Fragment>
    );
  }

  return (
    <div>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={SearchUserInitialValues}
          validationSchema={SearchUserSchema}
          onSubmit={(values) => {
            console.log('Search User by code promo');
            searchUserHandler(values);
          }}
        >
          <Form className="form-inline mt-5 ml-5">
            <div className="container">
              <div className="row">
                <div className="col-sm">
                  <Field
                    name="code_promo"
                    label=""
                    placeholder="Code Promo"
                    component={Dropdown}
                    options={codePromoData}
                  />
                </div>
                <div className="col-sm">
                  <Button
                    className="ml-5"
                    type="submit"
                    icon={
                      loading ? (
                        <div className="d-flex mx-auto">
                          <IconLoading />
                        </div>
                      ) : (
                        <IconSearch />
                      )
                    }
                  >
                    {' '}
                    Rechercher
                  </Button>
                </div>

                {tableData && tableData.length > 0 && (
                  <div className="col-sm">
                    <Button
                      btnType="outline"
                      className="ml-4"
                      onClick={() => {
                        downloadCsv(formData);
                      }}
                      icon={csvLoading ? <IconLoading /> : <IconDownload />}
                    >
                      Télécharger CSV
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Form>
        </Formik>
      </div>
      <Head
        headTitle="Recherche client par code promo"
        HeadIcon={IconClient}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setSelectedID={setSelectedID}
        isAdd={false}
        rightFragment={HeaderFragmentRight()}
      />
      <div className="o-content">
        <Table
          columns={columns}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
        />
      </div>
    </div>
  );
};

export default ClientSearchByCodePromo;
