import { cerebroApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceCerebroApi } from '../../index';
import { toast } from 'react-toastify';

// Récupération de la liste des demande de paiment par virement
export async function clientPaymentLinksReq(userId) {
  try {
    const res = await instanceCerebroApi.get(cerebroApiEndpoint.client_bridge_paymentlink.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

// Récupération de la liste des comptes agrégés
export async function clientBridgeItemsReq(userId) {
  try {
    const res = await instanceCerebroApi.get(cerebroApiEndpoint.client_bridge_items.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

// Liste des comptes agrégés pour un item donné
export async function clientBridgeAccountsReq(userId, itemId) {
  try {
    const res = await instanceCerebroApi.get(cerebroApiEndpoint.client_bridge_accounts.replace('{{userId}}', userId).replace('{{itmeId}}', itemId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}
