import React, { useState, useEffect, useContext, Fragment } from 'react';
import PermissionsContext from '../../../contexts/permissionContext';
import { useTranslation } from 'react-i18next';
import { columns, renderTable, tableSearch } from './documenttype.utils';
import { getDocumentListReq, getDocumentConfigReq } from '../../../api/documenttype/documenttype';
import Head from '../../../components/head';
import Table from '../../../components/table';
import { IconDocument } from '../../../assets/icons/icons-sidebar';
import AddDocument from './modals/addDocument';
import ShowDocument from './modals/showDocument';
import DeleteDocument from './modals/deleteDocument';

const Document = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [tableData, setTableData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [config, setConfig] = useState([]);
  const [isAdd, setIsAdd] = useState();

  useEffect(() => {
    getDocumentConfigReq().then((res) => {
      setConfig(res);
    });
  }, []);

  useEffect(() => {
    if (config?.trzDocumentType || config?.securityLevels || config?.vlDocumentType) {
      let isEdit, isDelete, isRead;

      isRead = permContext?.docRead;
      isEdit = permContext?.docUpdate;
      isDelete = permContext?.docDelete;
      if (permContext && !isRead) window.location.href = '/dashboard';
      setIsAdd(isEdit);

      if (permContext) {
        /*Get data of the list*/
        getDocumentListReq()
          .then((res) => {
            /*Render and set data of the list*/
            setTableData(
              renderTable(
                res,
                setAddModal,
                setDeleteModal,
                setSelectedID,
                config,
                isEdit,
                isDelete,
              ),
            );
          })
          .finally(() => setDataStatus('done'));
      }
    }
  }, [permContext, config]);

  useEffect(() => {
    tableSearch(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/admin">Administration&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          {t('sidebar:documents')}
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Head
        headTitle={headTitle()}
        HeadIcon={IconDocument}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setAddModal={setAddModal}
        setSelectedID={setSelectedID}
        isAdd={isAdd}
      />
      <div className="o-content">
        <Table
          columns={columns}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
        />
      </div>
      {/*Add and edit group*/}
      {addModal && (
        <AddDocument
          addModal={addModal}
          setAddModal={setAddModal}
          selectedID={selectedID}
          config={config}
        />
      )}
      {/*Show document*/}
      {showModal && (
        <ShowDocument
          showModal={showModal}
          setShowModal={setShowModal}
          selectedID={selectedID}
          config={config}
        />
      )}
      {/*Delete group*/}
      {deleteModal && (
        <DeleteDocument
          selectedID={selectedID}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
    </div>
  );
};

export default Document;
