import { fridayApiEndpoint} from '../../constants.js';
import { CheckAuth, instanceFridayApi } from '../../index';
import { toast } from 'react-toastify';


export async function cardDigitalizationListReq(cardId, data) {

  try {
    const res = await instanceFridayApi.get(fridayApiEndpoint.card_digitalization.replace('{{cardId}}', cardId), data);
    if (res.status === 200) {
      return res.data.sort((a,b) => b.carddigitalizationid - a.carddigitalizationid);
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';
  }
}

export async function cardDigitalizationDetailReq(cardId, cardDigitalizationId, data) {

  try {
    const res = await instanceFridayApi.get(fridayApiEndpoint.card_digitalization_detail.replace('{{cardId}}', cardId).replace('{{cardDigitalizationId}}', cardDigitalizationId), data);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';
  }
}

export async function cardDigitalizationDetailUpdateStatusReq(cardId, cardDigitalizationId, data) {
  let queryParams = { params: {
    action : data.action,
    reason : data.reason,
  }};
  console.log(queryParams);
  try {
    const res = await instanceFridayApi.put(fridayApiEndpoint.card_digitalization_detail.replace('{{cardId}}', cardId).replace('{{cardDigitalizationId}}', cardDigitalizationId), null, queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';
  }
}

export async function cardDigitalizationDetailDeleteReq(cardId, cardDigitalizationId, data) {
  let queryParams = { params: {
    reason : data.reason,
  }};
  try {
    const res = await instanceFridayApi.delete(fridayApiEndpoint.card_digitalization_detail.replace('{{cardId}}', cardId).replace('{{cardDigitalizationId}}', cardDigitalizationId), queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';
  }
}
