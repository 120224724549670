import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PermissionsContext from '../../../../contexts/permissionContext';
import Head from '../../../../components/head';
import TablePage from '../../../../components/tablepage';
import { IconClient } from '../../../../assets/icons/icons-sidebar';
import { columns, renderTable, tableSearch } from './../client.utils';
import { localStorageKeys } from '../../../../api/constants.js';
import { clientChildrenReq } from '../../../../api/client/detailClient';

const ClientMembers = () => {
  const { permContext } = useContext(PermissionsContext);
  const [basePath, setBasePath] = useState('/dashboard/client');

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageMetaData, setPageMetaData] = useState({});
  const [dataStatus, setDataStatus] = useState('loading');

  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();

  const updateTableDataFromLocalStorage = () => {
    let searchedClients = localStorage.getItem(localStorageKeys.clients_searched);
    var tableDataList = [];
    try {
      tableDataList = renderTable(JSON.parse(searchedClients));
    } catch (e) {
      console.log('Problème de parsing de ' + searchedClients);
    } finally {
      setTableData(tableDataList);
    }
  };

  const search = useLocation().search;
  const status = new URLSearchParams(search).get('status');

  useEffect(() => {
    setBasePath(status ? '/dashboard/clientbystatus/' + status : '/dashboard/client');

    refreshData();
    console.log('========================');
    console.log('Status : ' + status);
    console.log('========================');
  }, []);

  useEffect(() => {
    let isRead;

    isRead = permContext?.clientRead;
    if (permContext && !isRead) window.location.href = '/dashboard';

    if (permContext) {
      updateTableDataFromLocalStorage();
    }
    setLoading(false);
    setDataStatus('done');
  }, [permContext]);

  useEffect(() => {
    if (showModal) window.location.href = `/dashboard/client/${selectedID}`;
  }, [showModal]);

  const refreshData = (pageNo, pageSize) => {
    clientChildrenReq(id)
      .then((res) => setTableData(renderTable(res)))
      .finally(() => setDataStatus('done'));
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={basePath}>Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}?status=${status}`}>
            Détails client&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Liste des membres
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Head
        headTitle={headTitle()}
        HeadIcon={IconClient}
        setSelectedID={setSelectedID}
        isAdd={false}
        rightFragment=""
        disabledSearch={true}
      />
      <div className="o-content table-title">
        Membres
        <TablePage
          columns={columns}
          tableData={tableData}
          pageMetaData={pageMetaData}
          loadTableData={refreshData}
          status={dataStatus}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
          ContainHeader={() => ''}
          contain
        />
      </div>
    </div>
  );
};

export default ClientMembers;
