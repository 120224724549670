import { endpoint } from '../constants.js';
import { CheckAuth, instance } from '../index';
import { toast } from 'react-toastify';

export async function detailPackageFeatureReq(selectedID) {
  try {
    const res = await instance.get(endpoint.packageFeature + '/' + selectedID + endpoint.key);
    if (res.status === 200) {
      return res.data.content;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!');
    CheckAuth(error);
    return 'fail';
  }
}
