import React from 'react';
import './loading.scss';

export default function IconLoading({size = 25}) { 
  return (
    <div
      className="svg-loader"  
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <svg className="svg-container" viewBox="0 0 100 100">
        <circle className="loader-svg bg" cx="50" cy="50" r="45"></circle>
        <circle className="loader-svg animate" cx="50" cy="50" r="45"></circle>
      </svg>
    </div>
  );
}
