import React, { useState, useEffect, Fragment, useContext } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Head from '../../../components/head';
import Table from '../../../components/table';

import Logo from '../../../components/logo';
import { IconClient } from '../../../assets/icons/icons-sidebar';

import IconLoading from '../../../components/loading/loading.jsx';

import { detailClientReq } from '../../../api/client/detailClient';
import PermissionsContext from '../../../contexts/permissionContext';

import { clientBridgeItemsReq, clientPaymentLinksReq } from '../../../api/client/bridge/bridge';
import {
  tableBridgeItemsHeader,
  renderBridgeItemsTable,
  tableBridgePaymentRequestHeader,
  renderBridgePaymentRequestTable,
} from './bridge.utils';

const ClientBridgeItems = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState();
  const [detailData, setDetailData] = useState();
  const [selectedItemID, setSelectedItemID] = useState();
  const [selectedPaymentID, setSelectedPaymentID] = useState();
  const [bridgePaymentsTableData, setBridgePaymentsTableData] = useState([]);
  const [bridgeItemsTableData, setBridgeItemsTableData] = useState([]);
  const [selectedBridgePayment, setSelectedBridgePayment] = useState();
  const [selectedBridgeItem, setSelectedBridgeItem] = useState();
  const [allBridgePayments, setAllBridgePayments] = useState([]);
  const [allBridgeItems, setAllBridgeItems] = useState([]);
  const [loadingBridgePayments, setLoadingBridgePayments] = useState(true);
  const [loadingBridgeItems, setLoadingBridgeItems] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('loading');

  const [selectedItemType, setSelectedItemType] = useState();

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    detailClientReq(id)
      .then((res) => {
        setDetailData(res);
        refreshPaymentsData();
        refreshItemsData();
      })
      .finally(() => setStatus('done'));
  }, []);

  useEffect(() => {
    const bridgeItemDetail = allBridgeItems.find((x) => x.itemId === selectedItemID);
    setSelectedBridgeItem(bridgeItemDetail);
    setSelectedItemType('item');
  }, [selectedItemID]);

  useEffect(() => {
    const bridgePaymentDetail = allBridgePayments.find((x) => x.id === selectedPaymentID);
    setSelectedBridgePayment(bridgePaymentDetail);
    setSelectedItemType('payment');
  }, [selectedPaymentID]);

  const refreshPaymentsData = () => {
    setLoadingBridgePayments(true);
    clientPaymentLinksReq(id)
      .then((res) => {
        console.log(res);
        setAllBridgePayments(res);
        setBridgePaymentsTableData(renderBridgePaymentRequestTable(res, t));
      })
      .finally(() => setLoadingBridgePayments(false));
  };

  const refreshItemsData = () => {
    setLoadingBridgeItems(true);
    clientBridgeItemsReq(id)
      .then((res) => {
        console.log(res);
        setAllBridgePayments(res);
        setBridgeItemsTableData(renderBridgeItemsTable(res));
      })
      .finally(() => setLoadingBridgeItems(false));
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Espace bridge
        </div>
      </Fragment>
    );
  }

  const renderSelectedBridgeItemsActions = (selectedBridgeItem) => {
    return <div className="o-information-detail__actions"></div>;
  };

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-grid">
        <div className="mx-3 mb-3">
          {status === 'loading' ? (
            <div className="d-flex mx-auto mt-4">
              <IconLoading size={40} />
            </div>
          ) : (
            <div className="o-information">
              <div className="my-3">
                <b>Informations client</b>
              </div>
              <div className="o-information-row">
                <div className="o-information-column">
                  <div className="o-information--block">
                    <div className="o-information-detail__item mb-2">
                      <div>UserId | TrzUserId</div>
                      <div>
                        {detailData?.userId} | {detailData?.trzUserId}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Prénom NOM</div>
                      <div className="ml-2">
                        {detailData?.firstname ? detailData?.firstname : '-'}{' '}
                        {detailData?.lastname ? detailData?.lastname : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Statut</div>
                      <div>{detailData?.userStatus}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="table-title">
            Liste paiement par lien (alimentation compte / PayByLink)
            <Table
              columns={tableBridgePaymentRequestHeader}
              tableData={bridgePaymentsTableData}
              status={loadingBridgePayments}
              setShowModal={setShowModal}
              setSelectedID={setSelectedPaymentID}
              ContainHeader={() => ''}
              contain
              itemPerPage={10}
            />
          </div>
          <div className="table-title">
            Liste Comptes agrégés
            <Table
              columns={tableBridgeItemsHeader}
              tableData={bridgeItemsTableData}
              status={loadingBridgeItems}
              setShowModal={setShowModal}
              setSelectedID={setSelectedItemID}
              ContainHeader={() => ''}
              contain
              itemPerPage={10}
            />
          </div>
        </div>
        <div className="o-viewer">
          {selectedItemID && selectedItemType === 'item' && (
            <div>
              <div>
                <div className="mt-5">
                  <b>Informations de l'Item</b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div>
                        <b>Identification ID</b>
                      </div>
                      <div>{selectedBridgeItem?.id}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Date</b>
                      </div>
                      <div>
                        {selectedBridgeItem?.date
                          ? moment(selectedBridgeItem.date).format('MMMM YYYY')
                          : '-'}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Item</b>
                      </div>
                      <div>{selectedBridgeItem.bank ? selectedBridgeItem.bank.id + ' - ' + selectedBridgeItem.bank.name : '-'}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Statut</b>
                      </div>
                      <div>
                        {selectedBridgeItem?.ooStatus ? selectedBridgeItem.ooStatus : '-'}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Statut Bridge</b>
                      </div>
                      <div>
                        {selectedBridgeItem?.statusCodeInfo ? selectedBridgeItem.statusCodeInfo : '-'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <b>Actions</b>
                  <hr />
                  {renderSelectedBridgeItemsActions(selectedBridgeItem)}
                </div>
              </div>
            </div>
          )}
          {selectedPaymentID && selectedItemType === 'payment' && (
            <div>
              <div>
                <div className="mt-5">
                  <b>Informations de lien de paiement</b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div>
                        <b>Identification ID</b>
                      </div>
                      <div>{selectedBridgePayment?.id}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Date</b>
                      </div>
                      <div>
                        {selectedBridgePayment?.created_at
                          ? moment(selectedBridgePayment.created_at).format('DD/MM/YYYY HH:mm')
                          : '-'}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Item</b>
                      </div>
                      <div>{selectedBridgePayment?.feature?.name}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Montant</b>
                      </div>
                      <div>
                        {selectedBridgePayment?.transactions ? selectedBridgePayment?.transactions?.amount + ' €' : '- €'}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>End to End ID</b>
                      </div>
                      <div>
                        {selectedBridgePayment?.transactions ? selectedBridgePayment?.transactions?.end_to_end_id : '- €'}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Montant TTC</b>
                      </div>
                      <div>
                        {(selectedBridgeItem?.amount
                          ? Math.round(
                              selectedBridgeItem.amount * (1 + selectedBridgeItem.vat.rate),
                            )
                          : '-') + '€'}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Facturé (O/N)</b>
                      </div>
                      <div>{selectedBridgeItem?.transfer ? 'Oui' : 'Non'}</div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <b>Actions</b>
                  <hr />
                  {renderSelectedBridgeItemsActions(selectedBridgeItem)}
                </div>
              </div>
            </div>
          )}
          {!selectedItemID && <Logo className="o-viewer-logo" />}
        </div>
      </div>
    </div>
  );
};

export default ClientBridgeItems;
