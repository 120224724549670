import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { UserSchema, UserInitialValues } from '../user.utils';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import Dropdown from '../../../../components/dropdown';
import { getGroupsReq } from '../../../../api/group/groups';
import IconLoading from '../../../../components/loading/loading.jsx';
import { addUserReq, detailUserReq } from '../../../../api/user/users';
import { getPermissionsReq } from '../../../../api/permission/permissions';
import IconCross from '../../../../assets/icons/cross.svg';

const AddUser = ({ addModal, setAddModal, selectedID }) => {
  const { t } = useTranslation();
  const [dataStatus, setDataStatus] = useState('loading');
  const [groupDrp, setGroupDrp] = useState();
  const [permissionDrp, setPermissionDrp] = useState();
  const [currentItem, setCurrentItem] = useState();

  useEffect(() => {
    let groupOptions = [];
    let permissionOptions = [];
    if (selectedID) {
      setDataStatus('loading');
      detailUserReq(selectedID)
        .then((res) => {
          let permissions = [];
          res.permissions &&
            res.permissions.map((item) => permissions.push({ label: item.name, value: item.code }));
          setCurrentItem({
            email: res.email,
            group: res.group && { label: res.group.name, value: res.group.id },
            permissions: permissions,
          });
        })
        .finally(() => setDataStatus('done'));
    }
    //for group dropdown
    getGroupsReq().then((res) => {
      /*Render and set data of the list*/
      res.map((item) => groupOptions.push({ label: item.name, value: item.id }));
      setGroupDrp(groupOptions);
    });
    getPermissionsReq()
      .then((res) => {
        /*Render and set data of the list*/
        res.map((item) => permissionOptions.push({ label: item.name, value: item.code }));
        setPermissionDrp(permissionOptions);
      })
      .finally(() => setDataStatus('done'));
  }, [selectedID]);

  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? currentItem : UserInitialValues}
        validationSchema={UserSchema}
        onSubmit={(values) => {
          let permissionList = [];
          values.permissions && values.permissions.map((item) => permissionList.push(item.value));
          addUserReq(
            selectedID
              ? {
                  id: selectedID,
                  email: values.email,
                  group: Number(values.group.value),
                  permissions: permissionList,
                }
              : {
                  email: values.email,
                  group: Number(values.group.value),
                  permissions: permissionList,
                },
          ).then((res) => {
            if (res === 'success') {
              setTimeout(() => {
                window.location.href = '/dashboard/admin/users';
              }, 700);
            }
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-modal-info">
              <img
                src={IconCross}
                alt="Close"
                className="icon-cancel"
                onClick={() => {
                  setAddModal(false);
                }}
              />
              <div className="o-modal-info__title-text o-title text-center">
                {selectedID ? t('user:modify_user') : t('user:new_user')}
              </div>
              {dataStatus === 'loading' ? (
                <div className="d-flex mx-auto my-4">
                  <IconLoading size={40} />
                </div>
              ) : (
                <Fragment>
                  <div className="o-modal-info__fields">
                    <Field
                      name="email"
                      label={t('user:email')}
                      placeholder={t('user:email_placeholder') + ' *'}
                      component={InputFormik}
                    />
                    <Field
                      name="group"
                      label={t('user:group')}
                      placeholder={t('user:group_placeholder') + ' *'}
                      options={groupDrp}
                      component={Dropdown}
                    />
                    <Field
                      name="permissions"
                      label="Permissions"
                      placeholder="Permissions"
                      options={permissionDrp}
                      component={Dropdown}
                      isMulti
                    />
                  </div>
                  <div className="o-btn-group">
                    <Button type="submit" disabled={!(props.isValid && props.dirty)}>
                      {selectedID ? t('global:validate') : t('global:add')}
                    </Button>
                    <Button
                      className="cancel" btnType="outline"
                      onClick={() => {
                        setAddModal(false);
                      }}
                    >
                      {t('global:cancel')}
                    </Button>
                  </div>
                </Fragment>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUser;
