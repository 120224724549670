import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { PackageTypeSchema, PackageTypeInitialValues, YesNo } from '../packageType.utils';
import { addPackageTypeReq } from '../../../../api/packageType/addPackageType';
import { detailPackageTypeReq } from '../../../../api/packageType/detailPackageType';
import { getPackageTypeConfigReq } from '../../../../api/packageType/config';
import Dropdown from '../../../../components/dropdown';
import IconLoading from '../../../../components/loading/loading.jsx';
import IconCross from '../../../../assets/icons/cross.svg';

const AddPackageType = ({ addModal, setAddModal, selectedID }) => {
  const { t } = useTranslation();
  const [dataStatus, setDataStatus] = useState('done');
  const [currentItem, setCurrentItem] = useState();
  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
    getPackageTypeConfigReq().then((res) => {
      setTypeOptions(res.membershipTypes);
    });
  }, []);

  useEffect(() => {
    if (selectedID) {
      setDataStatus('loading');
      detailPackageTypeReq(selectedID)
        .then((res) => {
          setCurrentItem({
            name: res.name,
            type: { label: res.type, value: res.type },
            order: res.order,
            activated: res.activated ? YesNo[0] : YesNo[1],
            price: res.price,
            description: res.description,
            summary: res.summary,
            minAdult: res.minAdult,
            minChild: res.minChild,
            numberOfChildAllowed: res.numberOfChildAllowed,
            numberOfWalletAllowed: res.numberOfWalletAllowed,
            numberOfWithdrawalAllowed: res.numberOfWithdrawalAllowed,
          });
        })
        .finally(() => setDataStatus('done'));
    }
  }, [selectedID]);

  return (
    <Modal isOpen={addModal} toggle={setAddModal}>
      <Formik
        enableReinitialize={true}
        initialValues={selectedID ? currentItem : PackageTypeInitialValues}
        validationSchema={PackageTypeSchema}
        onSubmit={(values) => {
          addPackageTypeReq(
            selectedID
              ? {
                  membershipTypeId: selectedID,
                  name: values.name,
                  type: values.type.value,
                  order: values.order,
                  activated: values.activated.value,
                  price: values.price,
                  description: values.description,
                  summary: values.summary,
                  minAdult: values.minAdult,
                  minChild: values.minChild,
                  numberOfChildAllowed: values.numberOfChildAllowed,
                  numberOfWalletAllowed: values.numberOfWalletAllowed,
                  numberOfWithdrawalAllowed: values.numberOfWithdrawalAllowed,
                }
              : {
                  name: values.name,
                  type: values.type.value,
                  order: values.order,
                  activated: values.activated.value,
                  price: values.price,
                  description: values.description,
                  summary: values.summary,
                  minAdult: values.minAdult,
                  minChild: values.minChild,
                  numberOfChildAllowed: values.numberOfChildAllowed,
                  numberOfWalletAllowed: values.numberOfWalletAllowed,
                  numberOfWithdrawalAllowed: values.numberOfWithdrawalAllowed,
                },
          ).then((res) => {
            if (res === 'success') {
              setTimeout(() => {
                window.location.href = '/dashboard/admin/package-types';
              }, 700);
            }
          });
        }}
      >
        {(props) => (
          <Form>
            <div className="o-modal-info">
              <img
                src={IconCross}
                alt="Close"
                className="icon-cancel"
                onClick={() => {
                  setAddModal(false);
                }}
              />
              <div className="o-modal-info__title-text o-title text-center">
                {selectedID ? t('type:modify_type') : t('type:new_type')}
              </div>
              {dataStatus === 'loading' ? (
                <div className="d-flex mx-auto my-4">
                  <IconLoading size={40} />
                </div>
              ) : (
                <Fragment>
                  <div className="o-modal-info__fields">
                    <Field
                      name="name"
                      label={t('type:name')}
                      placeholder={t('type:name_placeholder') + ' *'}
                      component={InputFormik}
                    />
                    <Field
                      name="type"
                      label={t('type:type')}
                      placeholder={t('type:type_placeholder') + ' *'}
                      component={Dropdown}
                      options={typeOptions}
                    />
                    <Field
                      name="order"
                      label={t('type:order')}
                      placeholder={t('type:order_placeholder') + ' *'}
                      type="number"
                      component={InputFormik}
                    />
                    <Field
                      name="activated"
                      label={t('type:activated')}
                      placeholder={t('type:activated_placeholder') + ' *'}
                      component={Dropdown}
                      options={YesNo}
                    />
                    <Field
                      name="price"
                      label={t('type:price')}
                      placeholder={t('type:price_placeholder') + ' *'}
                      type="number"
                      component={InputFormik}
                    />
                    <Field
                      name="description"
                      label={t('type:description')}
                      placeholder={t('type:description_placeholder') + ' *'}
                      component={InputFormik}
                    />
                    <Field
                      name="summary"
                      label={t('type:summary')}
                      placeholder={t('type:summary_placeholder') + ' *'}
                      component={InputFormik}
                    />
                    <Field
                      name="minAdult"
                      label={t('type:minAdults')}
                      placeholder={t('type:minAdults_placeholder') + ' *'}
                      type="number"
                      component={InputFormik}
                    />
                    <Field
                      name="minChild"
                      label={t('type:minChildren')}
                      placeholder={t('type:minChildren_placeholder') + ' *'}
                      type="number"
                      component={InputFormik}
                    />
                    <Field
                      name="numberOfChildAllowed"
                      label={t('type:maxNbChildren')}
                      placeholder={t('type:maxNbChildren_placeholder')}
                      type="number"
                      component={InputFormik}
                    />
                    <Field
                      name="numberOfWalletAllowed"
                      label={t('type:maxNbWallets')}
                      placeholder={t('type:maxNbWallets_placeholder')}
                      type="number"
                      component={InputFormik}
                    />
                    <Field
                      name="numberOfWithdrawalAllowed"
                      label={t('type:maxNbWithdrawals')}
                      placeholder={t('type:maxNbWithdrawals_placeholder')}
                      type="number"
                      component={InputFormik}
                    />
                  </div>
                  <div className="o-btn-group">
                    <Button type="submit" disabled={!(props.isValid && props.dirty)}>
                      {selectedID ? t('global:validate') : t('global:add')}
                    </Button>
                    <Button
                      className="cancel" btnType="outline"
                      onClick={() => {
                        setAddModal(false);
                      }}
                    >
                      {t('global:cancel')}
                    </Button>
                  </div>
                </Fragment>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddPackageType;
