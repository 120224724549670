import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Head from '../../../components/head';
import Table from '../../../components/table';
import Button from '../../../components/button';
import Logo from '../../../components/logo';
import IconEdit from '../../../assets/icons/edit.svg';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import IconLoading from '../../../components/loading/loading.jsx';
import IconCancel from '../../../assets/icons/cancel.png';
import IconCheck from '../../../assets/icons/check.svg';
import {
  clientMandatesReq,
  clientBeneficiaryRefreshReq,
} from '../../../api/client/beneficiary/beneficiary';
import { tableMandateHeader, renderMandateTable } from './mandates.utils';
import ConfirmationBlacklistMandate from './modals/beneficiary/confirmationBlacklistMandate';
import ConfirmationRemoveBlacklistMandate from './modals/beneficiary/confirmationRemoveBlacklistMandate';

const ClientMandate = () => {
  const { id, beneficiaryId } = useParams();
  const [beneficiaryTableData, setBeneficiaryTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cardTableData, setCardTableData] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [allBeneficiaries, setAllBeneficiaries] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [addBlacklistMandateOpen, setAddBlacklistMandateOpen] = useState(false);
  const [removeBlacklistMandateOpen, setRemoveBlacklistMandateOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusMandatesTable, setStatusMandatesTable] = useState('loading');

  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    refreshData();
  }, []); // manque 'refreshData'

  useEffect(() => {
    const beneficiary = allBeneficiaries.find((x) => x.beneficiaryId === selectedID);
    setSelectedBeneficiary(beneficiary);
    setSelectedItem('beneficiary');
  }, [selectedID]); // manque 'allBeneficiaries'

  const refreshData = () => {
    clientMandatesReq(id)
      .then((res) => {
        console.log(res);
        setAllBeneficiaries(res);
        setBeneficiaryTableData(renderMandateTable(res));
      })
      .finally(() => setStatusMandatesTable('done'));
  };

  const refreshBeneficiaryData = (id, beneficiaryId) => {
    setLoading(true);
    clientBeneficiaryRefreshReq(id, beneficiaryId).then((res) => {
      setLoading(false);
      refreshData();
    });
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Liste des mandats (SDDR)
        </div>
      </Fragment>
    );
  }

  const renderSelectedBeneficiaryActions = () => {
    return (
      <div className="o-information-detail__actions">
        <Button btnType="outline" onClick={() => setAddBlacklistMandateOpen(true)}>
          <img className="mr-2" height="14" src={IconCancel} alt="" />
          Bannir Mandat
        </Button>
        <Button btnType="outline" onClick={() => setRemoveBlacklistMandateOpen(true)}>
          <img className="mr-2" height="14" src={IconCheck} alt="" />
          Rétablir Mandat
        </Button>
        <Button btnType="outline" onClick={() => refreshBeneficiaryData(id, selectedID)}>
          {loading ? <IconLoading /> : <img className="mr-2" src={IconEdit} alt="" />}
          Refresh
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-grid">
        <div className="mx-3 mb-3 table-title">
          Mandats
          <Table
            columns={tableMandateHeader}
            tableData={beneficiaryTableData}
            status={statusMandatesTable}
            setShowModal={setShowModal}
            setSelectedID={setSelectedID}
            ContainHeader={() => ''}
            contain
          />
        </div>
        <div className="o-viewer">
          {selectedID && selectedItem === 'beneficiary' && (
            <div>
              <div>
                <div className="mt-5">
                  <b>Informations Mandat</b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div>
                        <b>Beneficiary ID</b>
                      </div>
                      <div>{selectedBeneficiary?.beneficiaryId}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Date de création</b>
                      </div>
                      <div>{moment(selectedBeneficiary?.created).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Mise à jour</b>
                      </div>
                      <div>{moment(selectedBeneficiary?.updated).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Statut</b>
                      </div>
                      <div>{selectedBeneficiary?.status}</div>
                    </div>
                  </div>
                  <div className=" mt-2 ml-4">
                    <div>
                      <div>
                        <b>Trz Beneficiary ID</b>
                      </div>
                      <div>{selectedBeneficiary?.trzBeneficiaryId}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Active</b>
                      </div>
                      <div>{selectedBeneficiary?.activated ? 'Oui' : 'Non'}</div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <b>Actions</b>
                  <hr />
                  {renderSelectedBeneficiaryActions()}
                </div>
              </div>
            </div>
          )}
          {!selectedID && <Logo className="o-viewer-logo" />}
        </div>
      </div>
      {selectedID && (
        <ConfirmationBlacklistMandate
          blacklistMandateOpen={addBlacklistMandateOpen}
          setBlacklistMandateOpen={setAddBlacklistMandateOpen}
          userId={id}
          beneficiaryId={selectedID}
          refreshData={refreshData}
        />
      )}
      {selectedID && (
        <ConfirmationRemoveBlacklistMandate
          removeBlacklistMandateOpen={removeBlacklistMandateOpen}
          setRemoveBlacklistMandateOpen={setRemoveBlacklistMandateOpen}
          userId={id}
          beneficiaryId={selectedID}
          refreshData={refreshData}
        />
      )}
    </div>
  );
};

export default ClientMandate;
