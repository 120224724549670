import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Head from '../../../components/head';
import Table from '../../../components/table';
import IconLoading from '../../../components/loading/loading.jsx';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { detailClientReq } from '../../../api/client/detailClient';
import { columnsPackageTable, renderPackageTable } from './client.utils';

export default function ClientPackage() {
  const [detailData, setDetailData] = useState();
  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState('loading');
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Forfait client
        </div>
      </Fragment>
    );
  }
  useEffect(() => {
    detailClientReq(id)
      .then((res) => {
        setTableData(renderPackageTable(res.membership?.type?.features));
        setDetailData(res);
      })
      .finally(() => setStatus('done'));
  }, []);
  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} disabledSearch />
      <div className="my-3 ml-5">
        <b>Forfait Client</b>
      </div>
      {status === 'loading' ? (
        <div className="d-flex mx-auto mt-4">
          <IconLoading size={40} />
        </div>
      ) : (
        <Fragment>
          <div className="o-information">
            <div className="o-information-column">
              <div className="o-card">
                <div className="o-information-detail__item mb-2">
                  <div>Nom</div>
                  <div className="ml-2">
                    {detailData?.membership?.type?.name ? detailData?.membership?.type?.name : '-'}
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Prix</div>
                  <div className="ml-2">{detailData?.membership?.price}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nombre Wallets Autorisés</div>
                  <div className="ml-2">{detailData?.membership?.numberOfWalletAllowed}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nombre Retraits Autorisés</div>
                  <div className="ml-2">{detailData?.membership?.numberOfWithdrawalAllowed}</div>
                </div>
              </div>
            </div>
            <div className="o-information-column">
              <div className="o-card">
                <div className="o-information-detail__item mb-2">
                  <div>Activé</div>
                  <div className="ml-2">{detailData?.membership?.activated ? 'TRUE' : 'FALSE'}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Membership ID</div>
                  <div className="ml-2">{detailData?.membership?.membershipId}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nombre d’enfants</div>
                  <div className="ml-2">{detailData?.membership?.nbChild}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nombre d’adultes</div>
                  <div className="ml-2">{detailData?.membership?.nbAdult}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="o-content table-title">
            Test
            <Table
              showModal={showModal}
              setShowModal={setShowModal}
              columns={columnsPackageTable}
              tableData={tableData}
              ContainHeader=""
              contain
              notClickableRow={true}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}
