import moment from 'moment';

import { ButtonTable } from '../../../components/button/index';
//function renderBillingsHistoryArray(array){
//  return array.map((item, index) => <div>{ item }</div>);
//}
function renderFeature(item){
  return <div><div>{ (item.feature ? item.feature.name : '-') }</div>
        <div>{ (item.transfer ? '"' + item.transfer.label + '"' : '') }</div>
        </div>;
}

export const tableBillingsHistoryHeader = ['ID', 'Date', 'Item','Montant HT', 'TVA', 'Montant TTC', 'Facturé (O/N)', 'Action'];

export const renderBillingsHistoryTable = (res, setDeleteModal, setCurrentItem) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.user_feature_history_id,
      user_feature_history_id: item.user_feature_history_id,
      date : item.date? moment(item.date).format('MMMM YYYY') : '-',
      feature: renderFeature(item) ,
      amount: item.amount ? item.amount.toFixed(2) + '€' : 'Gratuit',
      vat: (item.vat? Math.round(item.vat.rate*100) :'-') + '%',
      amount_ttc: (item.amount ? (item.amount*(1+item.vat.rate)).toFixed(2) + '€' : 'Gratuit'),
      billed: (item.transfer || item.amount === 0) ? 'Oui' : <div className="o-red">Non</div>,
      delete: (item.transfer || item.amount === 0) ? ('-') : (<ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
            setDeleteModal(true);
            setCurrentItem(item);
          }}
        />),
    }),
  );
  return data;
};
