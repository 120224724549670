import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function clientStatementsReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_statements.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function downloadStatementReq(statementId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_statements_download.replace('{{statementId}}', statementId), {responseType: "arraybuffer"});
    if (res.status === 200) {
      //Create a Blob from the PDF Stream
      const file = new Blob([res.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail! : Error :' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function regenerateStatementReq(statementId) {
  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_statements_generate.replace('{{statementId}}', statementId));
    if (res.status === 200) {
      return 'success';
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail! : Error :' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function deleteStatementReq(statementId) {
  try {
    const res = await instanceBackendApi.delete(backendApiEndpoint.client_statements_detail.replace('{{statementId}}', statementId));
    if (res.status === 204) {
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}
