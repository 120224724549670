import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function getGroupsReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.acl_groups);
    return res.data;
  } catch (error) {
    CheckAuth(error);
  }
}

export async function getGroupDetailReq(groupId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.acl_groups_detail.replace('{{groupId}}',groupId));
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function addGroupReq(data) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.acl_groups, data);
    if (res.status === 200) {
      toast('Les données ont été enregistrées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!');
    CheckAuth(error);
  }
}

export async function deleteGroupReq(id) {
  try {
    const res = await instanceBackendApi.delete(backendApiEndpoint.acl_groups_detail.replace('{{groupId}}',id));
    if (res.status === 204) {
      toast('Les données ont été supprimées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!');
    CheckAuth(error);
    return 'fail';
  }
}
