import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Head from '../../../components/head';
import Table from '../../../components/table';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { columnsNotifEventTable, renderNotifEventTable } from './historyNotifEvent.utils';
import { historyClientEventNotificationReq } from '../../../api/client/historyClient';

const ClientNotifEvent = () => {
  const { id } = useParams();
  const [tableHistoryData, setTableHistoryData] = useState([]);
  const [tableSummaryData, setTableSummaryData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState('loading');
  const [csvLoading, setCsvLoading] = useState(false);

  useEffect(() => {
    historyClientEventNotificationReq(id)
      .then((res) => {
        console.log(res);
        setTableHistoryData(renderNotifEventTable(res));
      })
      .finally(() => setStatus('done'));
  }, []); // manque 'id'

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Historique des événements
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} isAdd={false} disabledSearch />
      <div className="d-flex o-content">
        <div className="flex-fill table-title">
          Historique des évenements (Mail + Notification Push)
          <Table
            showModal={showModal}
            setShowModal={setShowModal}
            columns={columnsNotifEventTable}
            tableData={tableHistoryData}
            status={status}
            textLeft
            ContainHeader={() => ''}
            contain
            itemPerPage={10}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientNotifEvent;
