import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { columns, renderTable } from './payinalerts.utils';
import { searchPayinAlertPageReq } from '../../../api/monitoring/monitoring';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import Button from '../../../components/button';
import Dropdown from '../../../components/dropdown';
import { AlertFraudStatus } from '../../common.utils';
import Head from '../../../components/head';
import TablePage from '../../../components/tablepage';
import { IconGroup } from '../../../assets/icons/icons-sidebar';
import IconLoading from '../../../components/loading/loading.jsx';
import IconEdit from '../../../assets/icons/edit.svg';
import ConfirmationUpdateAlertStatus from './modals/confirmationUpdateAlertStatus';
import { IconSearch } from '../../../assets/icons/icons-search-download.jsx';

const PayinAlerts = () => {
  const [tableData, setTableData] = useState([]);
  const { status } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allAlerts, setAllAlerts] = useState([]);
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [updateAlertStatusModal, setUpdateAlertStatusModal] = useState(false);
  const [pageMetaData, setPageMetaData] = useState({});
  const [tablePageMetaData, setTablePageMetaData] = useState({});
  const [formValues, setFormValues] = useState({ status: 'NEW' });

  const [selectedID, setSelectedID] = useState();
  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    const alert = allAlerts.find((x) => x.alertfraudid === selectedID);
    setSelectedItem(alert);
  }, [selectedID]);

  const searchAlertListPage = (data, pageNo, pageSize) => {
    setLoading(true);
    setDataStatus('loading');
    setFormValues(data);
    pageNo = pageNo != null ? pageNo : pageMetaData.pageNumber;
    pageSize = pageSize != null ? pageSize : pageMetaData.pageSize;
    searchPayinAlertPageReq(data, pageNo, pageSize)
      .then((res) => {
        setAllAlerts(res.content);
        setTableData(renderTable(res.content));
        setPageMetaData(res.pageable);
        setTablePageMetaData({ count: res.totalElements });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setDataStatus('done');
      });
  };

  const refreshAlertListPage = (pageNo, pageSize) => {
    setSelectedItem(null);
    searchAlertListPage(formValues, pageNo, pageSize);
  };

  const convertFormValues = (values) => {
    return {
      status: values.status.value,
    };
  };

  const SearchAlertFraudInitialValues = {
    status: AlertFraudStatus[0],
  };
  const SearchAlertFraudSchema = Yup.object().shape({});

  return (
    <div>
      <div className="o-information-grid">
        <div className="mx-3 mb-3">
          <div>
            <Formik
              enableReinitialize={true}
              initialValues={SearchAlertFraudInitialValues}
              validationSchema={SearchAlertFraudSchema}
              onSubmit={(values) => {
                searchAlertListPage(convertFormValues(values));
              }}
            >
              <Form className="form-inline mt-5 ml-5">
                <div className="container">
                  <div className="row">
                    <div className="col-sm">
                      <Field
                        name="status"
                        label=""
                        placeholder="Statut Alerte"
                        component={Dropdown}
                        options={AlertFraudStatus}
                      />
                    </div>
                    <div className="col-sm">
                      <Button
                        className="ml-5"
                        type="submit"
                        icon={loading ? <IconLoading /> : <IconSearch />}
                      >
                        Rechercher
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
          <Head headTitle="Alertes Virement Entrant" HeadIcon={IconGroup} disabledSearch={true} />
          <div className="o-content table-title">
            Alertes Fraudes
            <TablePage
              columns={columns}
              tableData={tableData}
              selectedStatus={status}
              pageMetaData={tablePageMetaData}
              loadTableData={refreshAlertListPage}
              status={dataStatus}
              setShowModal={setShowModal}
              setSelectedID={setSelectedID}
              ContainHeader={() => ''}
              contain
            />
          </div>
        </div>
        <div className="o-viewer">
          {selectedID && selectedItem && (
            <div>
              <div>
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div>
                        <b>Client</b>
                      </div>
                      <div>
                        <a
                          href={`/dashboard/client/${selectedItem.user?.userId}/wallet/${selectedItem.wallet?.walletId}`}
                        >
                          {selectedItem.user?.firstname} {selectedItem.user?.lastname} (
                          {selectedItem.user?.userId})
                        </a>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Virement entrant Date</b>
                      </div>
                      <div>{moment(selectedItem?.payin?.payinDate).format('DD/MM/YYYY HH:mm')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Statut</b>
                      </div>
                      <div>{selectedItem?.status}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Commentaire</b>
                      </div>
                      <div>
                        {selectedItem?.comment?.split(' # ').map((com) => (
                          <div>{com}</div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <b>Actions</b>
                  <hr />
                  <div className="o-information-detail__actions mt-3">
                    <Button btnType="outline" onClick={() => setUpdateAlertStatusModal(true)}>
                      <img className="mr-2" src={IconEdit} alt="" />
                      Modifier Statut
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {!selectedID && <Logo className="o-viewer-logo" />} */}
        </div>
      </div>
      <ConfirmationUpdateAlertStatus
        item={selectedItem}
        updateAlertStatusModal={updateAlertStatusModal}
        setUpdateAlertStatusModal={setUpdateAlertStatusModal}
        refreshData={refreshAlertListPage}
      />
    </div>
  );
};

export default PayinAlerts;
