import { BASE_URL, endpoint } from '../constants.js';
import { CheckAuth } from '../index';
import axios from 'axios';
import { toast } from 'react-toastify';
import { localStorageKeys } from '../constants.js';

export async function deleteAssignedPackageFeatureReq(data) {
  try {
    const res = axios({
      method: 'delete',
      headers: { Authorization: 'Bearer ' + localStorage.getItem(localStorageKeys.onlyone_token) },
      url: BASE_URL + endpoint.assignedPackageFeature + endpoint.key,
      data: data,
    });
    if (res.status === 200) {
      toast('Les données ont été supprimées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!');
    CheckAuth(error);
  }
}
