import { backendApiEndpoint} from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function getPermissionsReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.acl_permission);
    return res.data.sort((a,b) => a.code > b.code ? 1 : -1);
  } catch (error) {
    CheckAuth(error);
  }
}

export async function addPermissionReq(data) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.acl_permission, data);
    if (res.status === 200) {
      toast('Les données ont été enregistrées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!');
    CheckAuth(error);
  }
}

export async function deletePermissionReq(data) {
  try {
    const res = await instanceBackendApi.delete(backendApiEndpoint.acl_permission_detail.replace('{{permissionId}}', data.code));
    if (res.status === 204) {
      toast('Les données ont été supprimées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!');
    CheckAuth(error);
    return 'fail';
  }
}
