import React, { useState } from 'react';
import * as Yup from 'yup';
import { ONLYONE_WEB_APP_BASE_URL } from '../../../../../utils/constants-env.js';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { clientAskUrlKycLivenessReq } from '../../../../../api/client/kycliveness';
import IconLoading from '../../../../../components/loading/loading.jsx';
import IconCross from '../../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationAskKycLivenessURL = ({
  askKycLivenessURLOpen,
  setAskKycLivenessURLOpen,
  userId,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState();
  const { t } = useTranslation();
  const askKycLivenessUrlHandler = async (id, data) => {
    setLoading(true);
    let responseData = await clientAskUrlKycLivenessReq(id, data);
    setLoading(false);
    console.log(responseData);
    if (responseData) {
      refreshData();
      setDetailData(responseData);
    }
  };

  const AskKycLivenessURLInitialValues = {
    redirect_url: ONLYONE_WEB_APP_BASE_URL + '/kycliveness/success',
  };
  const AskKycLivenessURLSchema = Yup.object().shape({});

  return (
    <Modal isOpen={askKycLivenessURLOpen} toggle={setAskKycLivenessURLOpen}>
      {detailData ? (
        <div className="o-modal-info">
          <div className="o-modal-info__title-text">
            <div className="o-title">URL KYC Liveness</div>
            <div className="o-modal-info__title-text__text">
              {detailData?.identification_url ? detailData?.identification_url : '-'}
            </div>
          </div>
          <div className="o-modal-info__title-text">
            <div className="o-title">URL de rediection à la fin du processus</div>
            <div className="o-modal-info__title-text__text">
              {detailData?.redirect_url ? detailData?.redirect_url : '-'}
            </div>
          </div>
          <Button btnType="outline" onClick={() => setAskKycLivenessURLOpen(false)}>
            Fermer
          </Button>
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={AskKycLivenessURLInitialValues}
          validationSchema={AskKycLivenessURLSchema}
          onSubmit={(values) => {
            console.log("Récupération d'une URL de KycLiveness");
            askKycLivenessUrlHandler(userId, values);
          }}
        >
          <Form>
            <div className="o-modal-info">
              <img
                src={IconCross}
                alt="Close"
                className="icon-cancel"
                onClick={() => setAskKycLivenessURLOpen(false)}
              />
              <div className="o-modal-info__title-text o-title">
                Veuillez Saisir la potentielle URL de Redirection
              </div>

              <div className="o-modal-info__fields">
                <Field
                  name="redirect_url"
                  label="URL de redirection"
                  placeholder="URL de redirection"
                  component={InputFormik}
                />
              </div>
              <div className="o-btn-group">
                <Button type="submit">
                  {loading ? (
                    <div className="d-flex mx-auto">
                      <IconLoading />
                    </div>
                  ) : (
                    t('global:confirm')
                  )}
                </Button>
                <Button
                  className="cancel" btnType="outline"
                  onClick={() => setAskKycLivenessURLOpen(false)}
                >
                  {t('global:cancel')}
                </Button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </Modal>
  );
};

export default ConfirmationAskKycLivenessURL;
