import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi ,instanceJwtApi } from '../index';

export async function getAuthReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.acl_auth);
    return res.data;
  } catch (error) {
    CheckAuth(error);
  }
}

export async function getJwtToken(code, redirect_url) {
  try {
    const res = await instanceJwtApi.get(backendApiEndpoint.jwttoken + '?authCode=' + code + '&redirectUrl=' + redirect_url);
    return res.data;
  } catch (error) {
    CheckAuth(error);
  }
}
