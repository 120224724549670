import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function getDocumentConfigReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.document_type_config);
    return res.data;
  } catch (error) {
    CheckAuth(error);
  }
}

export async function getDocumentListReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.document_type_list);
    return res.data;
  } catch (error) {
    CheckAuth(error);
  }
}

export async function detailDocumentReq(selectedID) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.document_type_detail.replace('{{documentTypeId}}', selectedID));
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function addDocumentReq(data) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.document_type_list, data);
    if (res.status === 200) {
      toast('Les données ont été enregistrées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
  }
}

export async function deleteDocumentReq(selectedID) {
  try {
    const res = await instanceBackendApi.delete(backendApiEndpoint.document_type_detail.replace('{{documentTypeId}}', selectedID));
    if (res.status === 204) {
      toast('Les données ont été supprimées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
