import React from 'react';

const DashboardHome = () => {
  return (
    <div>
      Bienvenue sur la BackOffice de OnlyOne
    </div>
  );
};

export default DashboardHome;
