import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import Modal from '../modal';
import Button from '../button';
import IconLoading from '../../components/loading/loading.jsx';
import { cardDigitalizationDetailUpdateStatusReq } from '../../api/client/card/carddigitalizations';
import {
  CardDigitalizationUpdateActions,
  CardDigitalizationUpdateReasons,
} from '../../modules/common.utils';
import Dropdown from '../dropdown';
import IconCross from '../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationUpdateStatusCardDigitalization = ({
  cardId,
  cardDigitalizationId,
  updateCardDigitalizationDetailModal,
  setUpdateCardDigitalizationDetailModal,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const updateStatusCardDigitalizationHandler = async (data) => {
    setLoading(true);
    cardDigitalizationDetailUpdateStatusReq(cardId, cardDigitalizationId, {
      action: data.action.value,
      reason: data.reason.value,
    })
      .then((res) => {
        if (res !== 'fail') {
          refreshData();
          close();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const close = () => {
    setUpdateCardDigitalizationDetailModal(false);
  };

  const UpdateCardDigitalizationInitialValues = {};
  const UpdateCardDigitalizationSchema = Yup.object().shape({
    action: Yup.object().required('Requis'),
    reason: Yup.object().required('Requis'),
  });

  return (
    <Modal
      isOpen={updateCardDigitalizationDetailModal}
      toggle={setUpdateCardDigitalizationDetailModal}
    >
      <Formik
        enableReinitialize={true}
        initialValues={UpdateCardDigitalizationInitialValues}
        validationSchema={UpdateCardDigitalizationSchema}
        onSubmit={(values) => {
          updateStatusCardDigitalizationHandler(values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img src={IconCross} alt="Close" className="icon-cancel" onClick={close} />
            <div className="o-modal-info__title-text o-title">
              Veuillez confirmer le changement de status de la digitalization de la carte
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="action"
                label="Action *"
                placeholder="Action *"
                component={Dropdown}
                options={CardDigitalizationUpdateActions}
              />
              <Field
                name="reason"
                label="Raison *"
                placeholder="Raison *"
                component={Dropdown}
                options={CardDigitalizationUpdateReasons}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={close}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateStatusCardDigitalization;
