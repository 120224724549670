import { endpoint, backendApiEndpoint, fridayApiEndpoint, notifCenterApiEndpoint,
cerebroApiEndpoint, smaugApiEndpoint, sidekickApiEndpoint,
sauronApiEndpoint, scroogeApiEndpoint} from '../constants.js';

import { CheckAuth, instance, instanceBackendApi , instanceFridayApi, instanceNotifCenterApi,
instanceCerebroApi, instanceSmaugApi, instanceSideKickApi, instanceSauronApi, instanceScroogeApi
} from '../index';

export async function getGaeServiceInfos(serviceId) {
  try {
    let res;
    switch (serviceId) {
      case 'default':
        res = await instance.get(endpoint.service_package_versions);
        return res.data.content;
      case 'jarvis':
        res = await instanceBackendApi.get(backendApiEndpoint.service_package_versions);
        return res.data;
      case 'friday':
        res = await instanceFridayApi.get(fridayApiEndpoint.service_package_versions);
        return res.data;
      case 'cerebro':
        res = await instanceCerebroApi.get(cerebroApiEndpoint.service_package_versions);
        return res.data;
      case 'notification-center':
        res = await instanceNotifCenterApi.get(notifCenterApiEndpoint.service_package_versions);
        return res.data;
      case 'smaug':
        res = await instanceSmaugApi.get(smaugApiEndpoint.service_package_versions);
        return res.data;
      case 'sidekick':
        res = await instanceSideKickApi.get(sidekickApiEndpoint.service_package_versions);
        return res.data;
      case 'sauron':
        res = await instanceSauronApi.get(sauronApiEndpoint.service_package_versions);
        return res.data;
      case 'scrooge':
        res = await instanceScroogeApi.get(scroogeApiEndpoint.service_package_versions);
        return res.data;

      default:
        return;
    }

  } catch (error) {
    CheckAuth(error);
    throw error;
  }
}
