import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { updateWaitingListReq } from '../../../../../api/waitinglist/waitinglist';
import { WaitingListStatus } from '../../../../common.utils';
import Dropdown from '../../../../../components/dropdown';
import IconCross from '../../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationUpdateWaitingListStatus = ({
  item,
  updateWaitingListStatusModal,
  setUpdateWaitingListStatusModal,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const changeUpdateWaitingListStatusHandler = async (data) => {
    setLoading(true);
    let status = await updateWaitingListReq({
      email: item.email,
      waiting_type: item.waiting_type,
      status: data.status.value,
      comment: data.comment,
    }).finally(() => {
      setLoading(false);
    });
    if (status === 'success') {
      refreshData();
      close();
    }
  };

  const close = () => {
    setUpdateWaitingListStatusModal(false);
  };

  const UpdateWaitingListStatusInitialValues = () => {
    const initStatus = item?.status
      ? WaitingListStatus.find((x) => x.value === item?.status)
      : WaitingListStatus[0];
    return {
      status: initStatus,
    };
  };
  const UpdateWaitingListStatusSchema = Yup.object().shape({
    status: Yup.object().required('Requis'),
    comment: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={updateWaitingListStatusModal} toggle={setUpdateWaitingListStatusModal}>
      <Formik
        enableReinitialize={true}
        initialValues={UpdateWaitingListStatusInitialValues()}
        validationSchema={UpdateWaitingListStatusSchema}
        onSubmit={(values) => {
          changeUpdateWaitingListStatusHandler(values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img src={IconCross} alt="Close" className="icon-cancel" onClick={close} />
            <div className="o-modal-info__title-text o-title">
              Modifier le statut de la WaitingListe Fraude
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="status"
                label="Statut *"
                placeholder="Statut *"
                component={Dropdown}
                options={WaitingListStatus}
              />
              <Field
                name="comment"
                label="Commentaire *"
                placeholder="Commentaire *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={close}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateWaitingListStatus;
