import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import { YesNo } from '../../../common.utils';
import '../../../../components/modal/modal.scss';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import { updateClientInfos } from '../../../../api/client/updateClient';
import { incomeRangeReq, personalAssetsReq } from '../../../../api/config/config';
import IconCross from '../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';
import IconLoading from '../../../../components/loading/loading.jsx';

import PermissionsContext from '../../../../contexts/permissionContext';

const ConfirmationUpdateInfos = ({
  updateInfosOpen,
  setUpdateInfosOpen,
  userData,
  refreshData,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [incomeRangeData, setIncomeRangeData] = useState([]);
  const [personalAssetsData, setPersonalAssetsData] = useState([]);

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    /*Get annual incomeRange list*/
    incomeRangeReq(permContext.market).then((res) => {
      let legalAnnualIncomeRangeValues = [];
      res.map((item) =>
        legalAnnualIncomeRangeValues.push({
          value: item.code,
          label: item.code + ' - ' + item.label,
        }),
      );
      setIncomeRangeData(legalAnnualIncomeRangeValues);
    });

    /*Get anuual net income list*/
    personalAssetsReq(permContext.market).then((res) => {
      let personalAssetsValues = [];
      res.map((item) =>
        personalAssetsValues.push({ value: item.code, label: item.code + ' - ' + item.label }),
      );
      setPersonalAssetsData(personalAssetsValues);
    });
  }, []);

  const updateHandler = async (id, values) => {
    setLoading(true);
    let reqParams = {
      firstname: values.firstname,
      lastname: values.lastname,
      birthday: values.birthday,
      address1: values.address1,
      address2: values.address2,
      postcode: values.postcode,
      city: values.city,
      country: values.country,
      tax_number: values.taxNumber,
      specified_us_person: values.specifiedUSPerson,
      place_of_birth: values.placeOfBirth,
      birth_country: values.birthCountry,
      nationality: values.nationality,
      nationality_other: values.nationalityOther,
      occupation: values.occupation,
      income_range: values?.incomeRange ? values?.incomeRange.value : null,
      personal_assets: values?.personalAssets ? values?.personalAssets.value : null,
      legal_representative: values.legal_representative,
      effective_beneficiary: values.effective_beneficiary,
      effective_beneficiary_share: values?.effective_beneficiary
        ? values?.effective_beneficiary_share
        : null,
      activity_outside_eu: values?.activity_outside_eu ? values?.activity_outside_eu.value : false,
      economic_sanctions: values?.economic_sanctions ? values?.economic_sanctions.value : false,
      resident_countries_sanctions: values?.resident_countries_sanctions
        ? values?.resident_countries_sanctions.value
        : false,
      involved_sanctions: values?.involved_sanctions ? values?.involved_sanctions.value : false,
    };
    let status = await updateClientInfos(id, reqParams);
    setLoading(false);
    setUpdateInfosOpen(false);
    if (status) {
      refreshData();
    }
  };

  const UpdateInfosInitialValues = (data) => {
    return {
      firstname: data.firstname ? data.firstname : '',
      lastname: data.lastname ? data.lastname : '',
      birthday: data.birthday ? data.birthday : '',
      address1: data.address1 ? data.address1 : '',
      address2: data.address2 ? data.address2 : '',
      postcode: data.postcode ? data.postcode : '',
      city: data.city ? data.city : '',
      country: data.country ? data.country : '',
      taxNumber: data.taxNumber ? data.taxNumber : '',
      specifiedUSPerson: data.specifiedUSPerson ? data.specifiedUSPerson : '',
      placeOfBirth: data.placeOfBirth ? data.placeOfBirth : '',
      birthCountry: data.birthCountry ? data.birthCountry : '',
      nationality: data.nationality ? data.nationality : '',
      nationalityOther: data.nationalityOther ? data.nationalityOther : '',
      occupation: data.occupation ? data.occupation : '',
      incomeRange: data.incomeRange
        ? { value: data.incomeRange, label: data.incomeRange + ' - ' + data.incomeRange }
        : {},
      personalAssets: data.personalAssets
        ? { value: data.personalAssets, label: data.personalAssets + ' - ' + data.personalAssets }
        : {},
      legal_representative: data.legal_representative,
      effective_beneficiary: data.effective_beneficiary,
      effective_beneficiary_share: data.effective_beneficiary_share,
      activity_outside_eu: data.activity_outside_eu ? YesNo[0] : YesNo[1],
      economic_sanctions: data.economic_sanctions ? YesNo[0] : YesNo[1],
      resident_countries_sanctions: data.resident_countries_sanctions ? YesNo[0] : YesNo[1],
      involved_sanctions: data.involved_sanctions ? YesNo[0] : YesNo[1],
    };
  };
  const UpdateInfosSchema = Yup.object().shape({
    address1: Yup.string().required('Requis'),
    postcode: Yup.string().required('Requis'),
    city: Yup.string().required('Requis'),
    country: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={updateInfosOpen} toggle={setUpdateInfosOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={UpdateInfosInitialValues(userData)}
        validationSchema={UpdateInfosSchema}
        onSubmit={(values) => {
          console.log('Delete client submit');
          updateHandler(userData.userId, values).then((res) => {
            if (res === 'success') {
              setUpdateInfosOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img
              src={IconCross}
              alt="Close"
              className="icon-cancel"
              onClick={() => setUpdateInfosOpen(false)}
            />
            <div className="o-modal-info__title-text o-title">
              Pour confirmer la modification des informations du client
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="firstname"
                label={t('client:firstname')}
                placeholder={t('client:firstname')}
                component={InputFormik}
              />
              <Field
                name="lastname"
                label={t('client:lastname')}
                placeholder={t('client:lastname')}
                component={InputFormik}
              />
              <Field
                name="birthday"
                label={t('client:birthday')}
                placeholder={t('client:birthday')}
                component={InputFormik}
              />
              <Field
                name="placeOfBirth"
                label={t('client:placeOfBirth')}
                placeholder={t('client:placeOfBirth')}
                component={InputFormik}
              />
              <Field
                name="birthCountry"
                label={t('client:birthCountry')}
                placeholder={t('client:birthCountry') + '(code ISO 2 lettres)'}
                component={InputFormik}
              />
              <Field
                name="nationality"
                label={t('client:nationality')}
                placeholder={t('client:nationality') + '(code ISO 2 lettres)'}
                component={InputFormik}
              />
              <Field
                name="address1"
                label={t('client:adresse1')}
                placeholder={t('client:adresse1') + ' *'}
                component={InputFormik}
              />
              <Field
                name="address2"
                label={t('client:adresse2')}
                placeholder={t('client:adresse2')}
                component={InputFormik}
              />
              <Field
                name="postcode"
                label={t('client:postcode')}
                placeholder={t('client:postcode') + ' *'}
                component={InputFormik}
              />
              <Field
                name="city"
                label={t('client:city')}
                placeholder={t('client:city') + ' *'}
                component={InputFormik}
              />
              <Field
                name="country"
                label={t('client:country') + '(code ISO 2 lettres)'}
                placeholder={t('client:country') + ' (code ISO 2 lettres) *'}
                component={InputFormik}
              />
              <div>
                <Field type="checkbox" name="specifiedUSPerson" className="ml-2" />
                &nbsp;{t('client:us_person')}
              </div>
              <Field
                name="taxNumber"
                label={t('client:taxNumber')}
                placeholder={t('client:taxNumber')}
                component={InputFormik}
              />
              <Field
                name="occupation"
                label={t('client:occupation')}
                placeholder={t('client:occupation')}
                component={InputFormik}
              />
              <Field
                name="incomeRange"
                label={t('client:income_range')}
                placeholder={t('client:income_range')}
                component={Dropdown}
                options={incomeRangeData}
              />
              <Field
                name="personalAssets"
                label={t('client:personal_assets')}
                placeholder={t('client:personal_assets')}
                component={Dropdown}
                options={personalAssetsData}
              />
              <Field
                name="activity_outside_eu"
                label="Activité en dehors EU"
                placeholder="Activité en dehors EU *"
                options={YesNo}
                component={Dropdown}
              />
              <Field
                name="economic_sanctions"
                label="Sanctions Economiques"
                placeholder="Sanctions Economiques *"
                options={YesNo}
                component={Dropdown}
              />
              <Field
                name="resident_countries_sanctions"
                label="Resident countries sanctions"
                placeholder="Resident countries sanctions *"
                options={YesNo}
                component={Dropdown}
              />
              <Field
                name="involved_sanctions"
                label="Involved Sanctions"
                placeholder="Involved Sanctions *"
                options={YesNo}
                component={Dropdown}
              />
              {permContext.market === 'pro' && (
                <div className="o-modal-info__fields__checkbox">
                  <Field type="checkbox" name="legal_representative" className="ml-2" />
                  &nbsp;{t('client:legal_representative')}
                </div>
              )}
              {permContext.market === 'pro' && (
                <div className="o-modal-info__fields__checkbox">
                  <Field type="checkbox" name="effective_beneficiary" className="ml-2" />
                  &nbsp;{t('client:effective_beneficiary')}
                </div>
              )}
              {permContext?.market === 'pro' && (
                <Field
                  name="effective_beneficiary_share"
                  label={t('client:effective_beneficiary_share')}
                  placeholder={t('client:effective_beneficiary_share')}
                  component={InputFormik}
                />
              )}
            </div>
            <div className="o-btn-group">
              <Button className="confirm-infos" type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateInfos;
