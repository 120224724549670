import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { useTranslation } from 'react-i18next';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { updateCardOptions } from '../../../../../api/client/card/cardoptions';
import { YesNoInteger } from '../../../../common.utils';
import Dropdown from '../../../../../components/dropdown';
import IconCross from '../../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationUpdateCardOptions = ({
  userID,
  card,
  updateOptionsModal,
  setUpdateOptionsModal,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const [cardOptions, setCardOptions] = useState();
  const { t } = useTranslation();
  const updateCardOptionsHandler = async (values) => {
    setLoading(true);
    console.log('card.cardOptions : ' + card.cardOptions);
    let dataPost = {
      optionAtm: values.optionAtm.value,
      optionForeign: values.optionForeign.value,
      optionOnline: values.optionOnline.value,
      optionNfc: values.optionNfc.value,
    };

    let status = await updateCardOptions(userID, card.wallet_id, card.cardId, dataPost);
    setLoading(false);
    if (status === 'success') {
      refreshData();
      close();
    }
  };

  const close = () => {
    setUpdateOptionsModal(false);
  };

  const UpdateOptionsInitialValues = {
    optionAtm: card?.cardOptions?.optionAtm ? YesNoInteger[0] : YesNoInteger[1],
    optionForeign: card?.cardOptions?.optionForeign ? YesNoInteger[0] : YesNoInteger[1],
    optionOnline: card?.cardOptions?.optionOnline ? YesNoInteger[0] : YesNoInteger[1],
    optionNfc: card?.cardOptions?.optionNfc ? YesNoInteger[0] : YesNoInteger[1],
  };
  const UpdateOptionsSchema = Yup.object().shape({
    optionAtm: Yup.object().required('Requis'),
    optionForeign: Yup.object().required('Requis'),
    optionOnline: Yup.object().required('Requis'),
    optionNfc: Yup.object().required('Requis'),
  });

  return (
    <Modal isOpen={updateOptionsModal} toggle={setUpdateOptionsModal}>
      <Formik
        enableReinitialize={true}
        initialValues={cardOptions ? cardOptions : UpdateOptionsInitialValues}
        validationSchema={UpdateOptionsSchema}
        onSubmit={(values) => {
          console.log('Update card options' + cardOptions);
          updateCardOptionsHandler(values);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img src={IconCross} alt="Close" className="icon-cancel" onClick={close} />
            <div className="o-modal-info__title-text o-title">
              Modification des options de paiement de la carte{' '}
            </div>

            <div className="o-modal-info__fields">
              <Field
                name="optionAtm"
                label="Retrait ATM *"
                placeholder="Retrait ATM *"
                component={Dropdown}
                options={YesNoInteger}
              />
              <Field
                name="optionForeign"
                label="Paiement à l'étranger *"
                placeholder="Paiement à l'étranger *"
                component={Dropdown}
                options={YesNoInteger}
              />
              <Field
                name="optionOnline"
                label="Paiement en ligne *"
                placeholder="Paiement en ligne *"
                component={Dropdown}
                options={YesNoInteger}
              />
              <Field
                name="optionNfc"
                label="Paiement NFC *"
                placeholder="Paiement NFC *"
                component={Dropdown}
                options={YesNoInteger}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={close}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateCardOptions;
