import { backendApiEndpoint } from '../constants.js'; // endpoint supprimé car jamais lu
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';


export async function searchPayinAlertPageReq(data, pageNo, pageSize) {
  pageNo = pageNo == null ? 0 : pageNo;
  pageSize = pageSize == null ? 100 : pageSize;
  let queryParams = {
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    params: {
      pageNo : pageNo,
      pageSize : pageSize,
      sortDirection : 'DESC',
  }};
  try {
    const res = await instanceBackendApi.post(
      backendApiEndpoint.monitoring_payin_alert_page, JSON.stringify(data), queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function updatePayinAlertStatusReq(data) {
  let queryParams = {
    headers: { "Content-Type": "application/json; charset=UTF-8" },
  };
  try {
    const res = await instanceBackendApi.post(
      backendApiEndpoint.monitoring_payin_alert_status, JSON.stringify(data), queryParams);
    if (res.status === 200) {
      return 'success';
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function searchSepaSddrRejectedReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.monitoring_sepa_sddr_rejected);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientBillingToRecoverReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.monitoring_billing_to_recover);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientUserWalletOverdraftReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.monitoring_user_wallet_overdraft);
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function getClientFraudSuspectedReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.monitoring_fraud_suspected);
    return res.data
  } catch (error) {
    CheckAuth(error);
  }
}
