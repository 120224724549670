import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';



export async function getClientMembershipReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_membership_detail.replace('{{userId}}', userId));
    return res.data;
  } catch (error) {
    CheckAuth(error);
  }
}

export async function updateMembershipLimits(userId, data) {
  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_membership_limits.replace('{{userId}}', userId), data);
    if (res.status === 200) {
      toast('Mise à jour des plafonds de virement externe réalisée avec succès !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}


export async function updateClientMembershipReq(userId, data) {
  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.client_membership.replace('{{userId}}', userId), data);
    if (res.status === 200) {
      toast('Mise à jour du forfait pour le client !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}
