import React from "react"; 
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n/index';

const market = process.env.REACT_APP_MARKET;


const chooseTheme = () => {
    const theme = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    const style = document.getElementsByName("style")[0];
    style.href =
        window.location.origin + "/static/css/" + market + "-" + theme + ".css";
};

const setAppBannerContent = () => {
    const appId = market === "pro" ? "1659841595" : "1520016716";
    const metaTag = document.getElementById("apple__banner");
    if (metaTag) {
        metaTag.content = `app-id=${appId}`;
    }
};

const handleMediaChange = () => {
    chooseTheme();
};

const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
mediaQuery.addEventListener("change", handleMediaChange);

chooseTheme();
setAppBannerContent();

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

