import React, { useState, useEffect, Fragment, useContext } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Head from '../../../components/head';
import Table from '../../../components/table';
import Button from '../../../components/button';
import Logo from '../../../components/logo';
import IconEdit from '../../../assets/icons/edit.svg';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { ArrowRight } from '../../../assets/icons/icons-arrow';
import IconLoading from '../../../components/loading/loading.jsx';

import IconCheck from '../../../assets/icons/check.svg';
import IconEye from '../../../assets/icons/eye.svg';
import IconDoc from '../../../assets/icons/doc.svg';
import IconRing from '../../../assets/icons/ring.svg';
import { detailClientReq } from '../../../api/client/detailClient';
import PermissionsContext from '../../../contexts/permissionContext';

import {
  clientKycLivenessListReq,
  clientAskUrlKycLivenessReq,
  downloadKycLivenessReportReq,
  generateAndDownloadKycLivenessReportReq,
} from '../../../api/client/kycliveness'; // 'clientAskUrlKycLivenessReq'  (jamlais utilisé)
import { tableKycLivenessHeader, renderKycLivenessTable } from './kycliveness.utils';

import ConfirmationAskKycLivenessURL from './modals/kyc/confirmationAskKycLivenessUrl';
import ConfirmationAskKycLivenessWithMail from './modals/kyc/confirmationAskKycLivenessWithMail';
import ConfirmationAskKycLivenessWithSMS from './modals/kyc/confirmationAskKycLivenessWithSMS';
import ConfirmationReviewKycLiveness from './modals/kyc/confirmationReviewKycLiveness';
import ConfirmationSubmitKYCDocs from './modals/confirmationSubmitKycDocs';
import ConfirmationSubmitKYCReview from './modals/confirmationSubmitKycReview';

const ClientKYCLiveness = () => {
  const { id, kycLivenessId } = useParams();
  const [detailData, setDetailData] = useState();
  const [kycLivenessTableData, setKycLivenessTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [submitKycDocOpen, setSubmitKycDocOpen] = useState(false);
  const [oldKycReviewOpen, setOldKycReviewOpen] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [allKycLiveness, setAllKycLiveness] = useState([]);
  const [selectedKycLiveness, setSelectedKycLiveness] = useState();
  const [askKycLivenessURLOpen, setAskKycLivenessURLOpen] = useState(false);
  const [askKycLivenessWithMailOpen, setAskKycLivenessWithMailOpen] = useState(false);
  const [askKycLivenessWithSMSOpen, setAskKycLivenessWithSMSOpen] = useState(false);
  const [reviewKycLivenessOpen, setReviewKycLivenessOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingIdentification, setLoadingIdentification] = useState('loading');
  const [status, setStatus] = useState('loading');

  const [selectedItem, setSelectedItem] = useState();

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    detailClientReq(id)
      .then((res) => {
        setDetailData(res);
        refreshData();
      })
      .finally(() => setStatus('done'));
  }, []); // manque 'id' & 'refreshData'

  useEffect(() => {
    const kycLiveness = allKycLiveness.find((x) => x.identificationid === selectedID);
    setSelectedKycLiveness(kycLiveness);
    setSelectedItem('kycLiveness');
  }, [selectedID]); // manque 'allKycLiveness'

  const refreshData = () => {
    setLoadingIdentification('loading');
    clientKycLivenessListReq(id)
      .then((res) => {
        console.log(res);
        setAllKycLiveness(res);
        setKycLivenessTableData(renderKycLivenessTable(res));
      })
      .finally(() => setLoadingIdentification('done'));
  };

  const refreshDataClient = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });
  };

  const downloadKycLivenessReport = async (userId, kycLiveness) => {
    setLoading(true);
    if (userId && kycLiveness?.document_id && kycLiveness?.document?.bucketFileName) {
      const res = await downloadKycLivenessReportReq(userId, kycLiveness.document_id);
    } else if (userId && kycLiveness?.document_id) {
      const res = await generateAndDownloadKycLivenessReportReq(userId, kycLiveness.document_id);
    }
    setLoading(false);
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Liste des KYCLiveness process
        </div>
      </Fragment>
    );
  }

  const renderSelectedKycLivenessActions = () => {
    return (
      <div className="o-information-detail__actions">
        {selectedKycLiveness?.document_id ? (
          <Button
            btnType="outline"
            onClick={() => downloadKycLivenessReport(detailData?.userId, selectedKycLiveness)}
          >
            {loading ? <IconLoading /> : <img className="mr-2" src={IconEye} alt="" />}
            Télécharger Ubble Report
          </Button>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-grid">
        <div className="mx-3 mb-3">
          {status === 'loading' ? (
            <div className="d-flex mx-auto mt-4">
              <IconLoading size={40} />
            </div>
          ) : (
            <div className="o-information">
              <div className="my-3">
                <b>Informations client</b>
              </div>
              <div className="o-information-row">
                <div className="o-information-column">
                  <div className="o-information--block">
                    <div className="o-information-detail__item mb-2">
                      <div>Titre</div>
                      <div className="ml-2">{detailData?.title ? detailData?.title : '-'}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Prénom</div>
                      <div className="ml-2">
                        {detailData?.firstname ? detailData?.firstname : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Nom</div>
                      <div className="ml-2">
                        {detailData?.lastname ? detailData?.lastname : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Date de naissance</div>
                      <div className="ml-2">
                        {detailData?.birthday
                          ? moment(detailData?.birthday).format('DD/MM/YYYY')
                          : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Statut KYC</div>
                      <div>{detailData?.userStatus}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Bloqué</div>
                      <div>{detailData?.blocked ? 'Oui' : 'Non'}</div>
                    </div>
                  </div>
                  <div className="mt-2 flex-fill">
                    <b>
                      Client Documents
                      <a href={`/dashboard/client/${id}/document`} className="pl-2">
                        Voir détail <ArrowRight />
                      </a>
                    </b>
                  </div>
                </div>
                <div className="o-information-column">
                  <div className="o-information--block">
                    <div className="o-information-detail__item mb-2">
                      <div>KYC Level</div>
                      <div className="ml-2">
                        {detailData?.kycLevel ? detailData?.kycLevel : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>KYC Review</div>
                      <div className="ml-2">
                        {detailData?.kycReview ? detailData?.kycReview : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>KYC Review Comment</div>
                      <div className="ml-2">
                        {detailData?.kycReviewComment ? detailData?.kycReviewComment : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Freezed</div>
                      <div className="ml-2">{detailData?.isFreezed}</div>
                    </div>
                  </div>
                  <Button btnType="outline" onClick={() => refreshDataClient(true)}>
                    {loading ? <IconLoading /> : <img className="mr-2" src={IconEdit} alt="" />}
                    Rafraichir les données KYC
                  </Button>
                </div>
                {detailData?.userTypeId === 1 && (
                  <div className="o-information-column">
                    <div className="my-3">
                      <b>KYC Liveness</b>
                    </div>
                    <div className="o-information-detail__actions">
                      <Button btnType="outline" onClick={() => setAskKycLivenessURLOpen(true)}>
                        <img className="mr-2" height="14" src={IconCheck} alt="" />
                        Demander un nouveau KYC Liveness
                      </Button>
                      <Button btnType="outline" onClick={() => setAskKycLivenessWithMailOpen(true)}>
                        <img className="mr-2" height="14" src={IconCheck} alt="" />
                        Nouveau KYC Liveness + Mail
                      </Button>
                      {detailData?.mobile ? (
                        <Button
                          btnType="outline"
                          onClick={() => setAskKycLivenessWithSMSOpen(true)}
                        >
                          <img className="mr-2" height="14" src={IconCheck} alt="" />
                          Nouveau KYC Liveness + SMS
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>

                    <div className="my-3">
                      <b>KYC Liveness Review</b>
                    </div>
                    <div className="o-information-detail__actions">
                      <Button btnType="outline" onClick={() => setReviewKycLivenessOpen(true)}>
                        <img className="mr-2" height="14" src={IconCheck} alt="" />
                        Demander Review KYC Liveness
                      </Button>
                    </div>
                  </div>
                )}
                {permContext.market === 'pro' && (
                  <div className="o-information-column">
                    <div className="my-3">
                      <b>KYC Classique</b>
                    </div>
                    <div className="o-information-detail__actions">
                      {permContext?.clientUpdate && (
                        <Button btnType="outline" onClick={() => setSubmitKycDocOpen(true)}>
                          <img className="mr-2" src={IconDoc} alt="" />
                          Soumettre Doc. KYC
                        </Button>
                      )}
                    </div>
                    <div className="my-3">
                      <b>KYC Review</b>
                    </div>
                    <div className="o-information-detail__actions">
                      {permContext?.clientUpdate && (
                        <Button btnType="outline" onClick={() => setOldKycReviewOpen(true)}>
                          <img className="mr-2" src={IconRing} alt="" />
                          Déclencher Review KYC
                        </Button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="table-title">
            KYC Liveness Identification Process
            <Table
              columns={tableKycLivenessHeader}
              tableData={kycLivenessTableData}
              status={loadingIdentification}
              setShowModal={setShowModal}
              setSelectedID={setSelectedID}
              ContainHeader={() => ''}
              contain
            />
          </div>
        </div>
        <div className="o-viewer">
          {selectedID && selectedItem === 'kycLiveness' && (
            <div>
              <div>
                <div className="mt-5">
                  <b>Actions</b>
                  <hr />
                  {renderSelectedKycLivenessActions()}
                </div>
                <div className="mt-5">
                  <b>Informations KYC Liveness</b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div>
                        <b>Identification ID</b>
                      </div>
                      <div>{selectedKycLiveness?.identificationid}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Date de début</b>
                      </div>
                      <div>{selectedKycLiveness?.start_at}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Mise à jour</b>
                      </div>
                      <div>{selectedKycLiveness?.updated_at}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>URL KYC Liveness</b>
                      </div>
                      <div>{selectedKycLiveness?.identification_url}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Score</b>
                      </div>
                      <div>{selectedKycLiveness?.score}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Nom complet détecté</b>
                      </div>
                      <div>
                        {selectedKycLiveness?.first_name} | {selectedKycLiveness?.last_name}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Commentaire</b>
                      </div>
                      <div>{selectedKycLiveness?.comment}</div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <b>Valeurs de retour en fin de tunnel KYCLiveness</b>
                  <hr />
                  <div className="mt-2">
                    <div>
                      <b>Status Retour</b>
                    </div>
                    <div>{selectedKycLiveness?.return_status}</div>
                  </div>
                  <div className="mt-2">
                    <div>
                      <b>Raison Retour</b>
                    </div>
                    <div>{selectedKycLiveness?.return_reason}</div>
                  </div>
                  <div className="mt-2">
                    <div>
                      <b>Type Erreur retour Retour</b>
                    </div>
                    <div>{selectedKycLiveness?.return_error_type}</div>
                  </div>
                  <div className="mt-2">
                    <div>
                      <b>Description Erreur</b>
                    </div>
                    <div>{selectedKycLiveness?.return_description}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!selectedID && <Logo className="o-viewer-logo" />}
        </div>
      </div>
      <ConfirmationAskKycLivenessURL
        askKycLivenessURLOpen={askKycLivenessURLOpen}
        setAskKycLivenessURLOpen={setAskKycLivenessURLOpen}
        userId={id}
        refreshData={refreshData}
      />
      <ConfirmationReviewKycLiveness
        reviewKycLivenessOpen={reviewKycLivenessOpen}
        setReviewKycLivenessOpen={setReviewKycLivenessOpen}
        userId={id}
        refreshData={refreshData}
      />
      <ConfirmationSubmitKYCDocs
        submitKycOpen={submitKycDocOpen}
        setSubmitKycOpen={setSubmitKycDocOpen}
        userId={detailData?.userId}
        refreshData={() => {}}
      />
      <ConfirmationSubmitKYCReview
        setKycReviewOpen={oldKycReviewOpen}
        setSetKycReviewOpen={setOldKycReviewOpen}
        userId={detailData?.userId}
      />
      <ConfirmationAskKycLivenessWithMail
        askKycLivenessWithMailOpen={askKycLivenessWithMailOpen}
        setAskKycLivenessWithMailOpen={setAskKycLivenessWithMailOpen}
        userId={detailData?.userId}
        refreshData={refreshData}
      />
      <ConfirmationAskKycLivenessWithSMS
        askKycLivenessWithSMSOpen={askKycLivenessWithSMSOpen}
        setAskKycLivenessWithSMSOpen={setAskKycLivenessWithSMSOpen}
        user={detailData}
        refreshData={refreshData}
      />
    </div>
  );
};

export default ClientKYCLiveness;
