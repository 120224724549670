import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PermissionsContext from '../../../contexts/permissionContext';

import { columns, tableSearch, renderTable } from './sepasddrrejected.utils';
import { searchSepaSddrRejectedReq } from '../../../api/monitoring/monitoring';

import Head from '../../../components/head';
import Table from '../../../components/table';
import { IconGroup } from '../../../assets/icons/icons-sidebar';

const SepasSddrRejected = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [tableData, setTableData] = useState([]);
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (permContext) {
      /*Get data of the list*/
      searchSepaSddrRejectedReq()
        .then((res) => {
          /*Render and set data of the list*/
          setTableData(renderTable(res, t));
        })
        .finally(() => setDataStatus('done'));
    }
  }, [permContext, t]);

  useEffect(() => {
    tableSearch(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  return (
    <div>
      <Head
        headTitle="SDDR Rejetés"
        HeadIcon={IconGroup}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
      <div className="o-content">
        <Table
          columns={columns}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
        />
      </div>
    </div>
  );
};

export default SepasSddrRejected;
