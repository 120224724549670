import React, { useState, useEffect } from 'react';
import { REDIRECT_URL, CLIENT_ID } from '../../utils/constants-env.js';
import { localStorageKeys } from '../../api/constants.js';
import './login.scss';
import { startOnlyOneLogin } from './login';
import IconLoading from '../../components/loading/loading.jsx';
import { LogoOnlyOne } from '../../assets/onlyone';
import {jwtDecode} from 'jwt-decode';

const Login = () => {
  const [background, setBackground] = useState('https://static.onlyonecard.io/images/onlyone_forest.jpg');

  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://accounts.google.com/gsi/client';
    scriptTag.id = 'google-auth-onlyone';
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.onload = () => {
      window.onGoogleLibraryLoad();
    };
    document.body.append(scriptTag);

    const backgrounds = [
      'https://static.onlyonecard.io/images/onlyone_tree.jpg',
      'https://static.onlyonecard.io/images/onlyone_landscape.jpg',
      'https://static.onlyonecard.io/images/onlyone_forest.jpg',
      'https://static.onlyonecard.io/images/onlyone_sky.jpg',
      'https://static.onlyonecard.io/images/onlyone_mountains.jpg',
    ];
    let randomIndex = Math.random();
    let indexBackground = Math.floor(randomIndex * backgrounds.length);
    setBackground(backgrounds[indexBackground]);
  }, []);

  const handleCredentialResponse = (response) => {
    // Decode the JWT token to get the user's profile information
    const userObject = jwtDecode(response.credential);
    // Save the user's information in local storage
    localStorage.setItem(localStorageKeys.onlyone_token, response.credential);
    localStorage.setItem('user_name', JSON.stringify({ firstName: userObject.given_name, lastName: userObject.family_name }));
    window.location.href = '/dashboard/client';
  };

  window.onGoogleLibraryLoad = () => {
    window.google.accounts.id.initialize({
      client_id: CLIENT_ID, // Replace with your Google Client ID
      callback: handleCredentialResponse,
      cancel_on_tap_outside: true,
      auto_select: false,
      ux_mode: 'popup',
    });

    window.google.accounts.id.renderButton(
      document.getElementById("signinButtonOnlyone"),
      { theme: 'outline', size: 'large' }
    );
    window.google.accounts.id.prompt();
  };

  return (
    <div className="login">
      <div className="login-left">
        <div className="login-block">
          <LogoOnlyOne />
          <div>
            <div id="signinButtonOnlyone">&nbsp;</div>
            <div id="loader" className="mx-auto mt-4" style={{ display: 'none' }}>
              <IconLoading size={40} />
            </div>
          </div>
        </div>
      </div>
      <div className="login-right">
        <img src={background} alt="bg" />
      </div>
    </div>
  );
};

export default Login;
