import moment from 'moment';

export const tableScaWalletHeader = [
  'SCA Wallet ID',
  'Name',
  'Status',
  'Sous Status',
  'Passcord Status',
  'Bloqué',
  'Date expiration activation code',
];
export const renderScaWalletTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.user_sca_wallet_id,
      user_sca_wallet_id: item.user_sca_wallet_id,
      sca_wallet_name: item.sca_wallet_name,
      status : item.status,
      sub_status: item.sub_status,
      passcode_status: item.passcode_status,
      locked: item.locked ? 'Oui' : 'Non',
      activation_code_expiry_date: moment(item.activation_code_expiry_date).format('DD/MM/YYYY HH:mm'),
    }),
  );
  return data;
};
