import { backendApiEndpoint, BASE_URL } from '../../constants.js';
import { CheckAuth, instanceBackendApi } from '../../index';
import { toast } from 'react-toastify';

export async function changePinReq(userId, walletId, cardId, data) {

  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(backendApiEndpoint.client_card_detail_setpin.replace('{{userId}}', userId).replace('{{walletId}}', walletId).replace('{{cardId}}', cardId), data, queryParams);
    if (res.status === 200) {
      toast('Blocage / Déblocage de la carte avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';

  }
}

export async function unlockPinReq(userId, walletId, cardId) {

  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.client_card_detail_unlockpin.replace('{{userId}}', userId).replace('{{walletId}}', walletId).replace('{{cardId}}', cardId));
    if (res.status === 200) {
      toast('Déblocage de la carte avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';
  
  }
}
