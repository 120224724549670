import React , {useState} from 'react';

const Logo = ({className, type, ...props }) => {
  const [logo, setLogo] = useState('');

  import(`../../assets/${process.env.REACT_APP_MARKET}/logo/onlyone_${type}.svg`).then((module) => {
    setLogo(module.default);
  });

  return (
    <img className={`o-sidebar-logo ${className}`} src={logo} alt="" width="200" {...props} />
  );
};

Logo.defaultProps = {
  className: '',
  type: 'dark',
};

export default Logo;
