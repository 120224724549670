export const PROJECT_ID = 'onlyonebank-dev';
export const CLIENT_ID = '351525652321-t7kb6jnt5v2tdms5uift5i9ksv99oidl.apps.googleusercontent.com';

export const BASE_URL = 'https://api-dev.onlyonebank.io';
//export const BASE_URL = 'http://localhost:8080';
export const REDIRECT_URL = 'https://backend-dev.onlyonebank.io';
//export const REDIRECT_URL = 'http://localhost:3000';
export const API_KEY = 'AIzaSyDfNpRHR1rvG6AxdsnUb-yjhE9yxx9zAvI';
export const BACKEND_API_BASE_URL = 'https://jarvis-dev.onlyonebank.io';
export const FRIDAY_API_BASE_URL = 'https://friday-dev.onlyonebank.io';
export const CEREBRO_API_BASE_URL = 'https://cerebro-dev.onlyonebank.io';
export const SMAUG_API_BASE_URL = 'https://smaug-dev.onlyonebank.io';
export const SIDEKICK_API_BASE_URL = 'https://sidekick-dev.onlyonebank.io';
export const NOTIF_CENTER_API_BASE_URL = 'https://notif-center-dev.onlyonebank.io';
export const SAURON_API_BASE_URL = 'https://sauron-dev.onlyonebank.io';
export const SCROOGE_API_BASE_URL = 'https://scrooge-dev.onlyonebank.io';

//export const BACKEND_API_BASE_URL = 'http://localhost:8080';
export const ONLYONE_WEB_APP_BASE_URL = 'https://app-dev.onlyonebank.io';
