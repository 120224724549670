import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { useTranslation } from 'react-i18next';
import { clientReviewKycLivenessReq } from '../../../../api/client/kycliveness';
import IconLoading from '../../../../components/loading/loading.jsx';
import IconCheck from '../../../../assets/icons/check.svg';
import IconCross from '../../../../assets/icons/cross.svg';

// Ancien système de KYC Review
const ConfirmationSubmitKYCReview = ({ setKycReviewOpen, setSetKycReviewOpen, userId }) => {
  const [loading, setLoading] = useState(false);
  const [responseText, setResponseText] = useState('');
  const { t } = useTranslation();
  const close = () => {
    setSetKycReviewOpen(false);
  };

  const setKYCReviewRequest = async () => {
    setLoading(true);
    const res = await clientReviewKycLivenessReq(userId, true);
    setResponseText(res);
    setLoading(false);
  };

  const ShowResponseText = () => (
    <div style={{ textAlign: 'center', margin: '20px auto 30px auto' }}>
      {responseText} <br />
      {responseText === 'success' ? (
        <img className="d-flex mx-auto" style={{ marginTop: '20px' }} src={IconCheck} alt="" />
      ) : (
        <img className="d-flex mx-auto" style={{ marginTop: '20px' }} src={IconCross} alt="" />
      )}
    </div>
  );
  return (
    <Modal isOpen={setKycReviewOpen} toggle={setSetKycReviewOpen}>
      <div className="o-modal-info">
        <img src={IconCross} alt="Close" className="icon-cancel" onClick={close} />
        <div className="o-modal-info__title-text o-title">Déclencher Review KYC</div>
        {loading ? (
          <img
            className="d-flex mx-auto"
            style={{ margin: '20px auto 30px auto' }}
            src={IconLoading}
            height="25"
            alt=""
          />
        ) : responseText.length ? (
          <ShowResponseText />
        ) : (
          ''
        )}
        <div className="o-btn-group">
          {!responseText.length ? (
            <Button className="mb-2 mt-2" onClick={setKYCReviewRequest} type="submit">
              {t('global:confirm')}
            </Button>
          ) : (
            ''
          )}

          <Button className="cancel" btnType="outline" onClick={close}>
            {t('global:back')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationSubmitKYCReview;
