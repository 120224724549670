import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function documentTypesToSubmitReq() {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.config_document_types + '?active=true&securityLevel=FIRST&securityLevel=SECOND');
    if (res.status === 200) {
      console.log(res);
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
