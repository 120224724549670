import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Head from '../../../components/head';
import IconLoading from '../../../components/loading/loading.jsx';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import IconEdit from '../../../assets/icons/edit.svg';
import Button from '../../../components/button';
import { getClientMembershipReq } from '../../../api/client/clientmembership';
import ConfirmationUpdatePayoutLimits from './modals/membership/confirmationUpdatePayoutLimits';
import ConfirmationUpdateClientMembership from './modals/membership/confirmationUpdateClientMembership';

export default function ClientMembership() {
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('loading');
  const [updatePayoutLimitsModal, setUpdatePayoutLimitsModal] = useState(false);
  const [updateClientMembershipModal, setUpdateClientMembershipModal] = useState(false);

  const { id } = useParams();
  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>Membership
        </div>
      </Fragment>
    );
  }
  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    setLoading(true);
    getClientMembershipReq(id)
      .then((res) => {
        console.log(res);
        setDetailData(res);
        setLoading(false);
      })
      .finally(() => setStatus('done'));
  };

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} disabledSearch />
      {status === 'loading' ? (
        <div className="d-flex mx-auto mt-4">
          <IconLoading size={40} />
        </div>
      ) : (
        <div className="o-information-detail flex-fill">
          <div className="my-3">
            <b>Information Forfait</b>
          </div>
          <div className="o-information-detail__block">
            <div className="o-information-detail__info flex-fill">
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>Forfait</div>
                  <div className="ml-2">{detailData?.type?.name}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Prix</div>
                  <div className="ml-2">
                    {detailData?.price} € ({detailData?.billing_type})
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nb de wallets gratuits</div>
                  <div className="ml-2">{detailData?.numberOfWalletAllowed}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Nb de retraits ATM gratuits</div>
                  <div className="ml-2">{detailData?.numberOfWithdrawalAllowed}</div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Virement externe / jour</div>
                  <div className="ml-2">
                    {detailData?.current_payout_amount_daily != null
                      ? detailData?.current_payout_amount_daily
                      : '0'}{' '}
                    € /{' '}
                    {detailData?.limit_payout_amount_daily != null
                      ? detailData?.limit_payout_amount_daily
                      : '-'}{' '}
                    €
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Virement externe / mois</div>
                  <div className="ml-2">
                    {detailData?.current_payout_amount_monthly != null
                      ? detailData?.current_payout_amount_monthly
                      : '0'}{' '}
                    € /{' '}
                    {detailData?.limit_payout_amount_monthly != null
                      ? detailData?.limit_payout_amount_monthly
                      : '-'}{' '}
                    €
                  </div>
                </div>
              </div>
            </div>
            <div className="o-information-detail__info flex-fill">
              <div className="o-information--block">
                <div className="o-information-detail__item mb-2">
                  <div>Création Wallet</div>
                  <div className="ml-2">
                    {detailData?.current_wallet_created}&nbsp;Wallets&nbsp;créés&nbsp;/&nbsp;
                    {detailData?.limit_wallet_created}&nbsp;Wallets&nbsp;autorisés
                  </div>
                </div>
                <div className="o-information-detail__item mb-2">
                  <div>Création Cartes</div>
                  <div className="ml-2">
                    {detailData?.current_card_created}&nbsp;Cartes&nbsp;créées&nbsp;/&nbsp;
                    {detailData?.limit_card_created}&nbsp;Cartes&nbsp;autorisées
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="o-information-detail__actions">
            <b>Actions sur le Forfait</b>
            <div>
              <Button btnType="outline" onClick={() => setUpdatePayoutLimitsModal(true)}>
                <img className="mr-2" src={IconEdit} alt="" />
                Modifier plafonds Virement externe
              </Button>
              <Button btnType="outline" onClick={() => setUpdateClientMembershipModal(true)}>
                <img className="mr-2" src={IconEdit} alt="" />
                Changer de Forfait
              </Button>
            </div>
          </div>
        </div>
      )}
      <ConfirmationUpdatePayoutLimits
        userID={id}
        membership={detailData}
        updatePayoutLimitsModal={updatePayoutLimitsModal}
        setUpdatePayoutLimitsModal={setUpdatePayoutLimitsModal}
        refreshData={refreshData}
      />
      <ConfirmationUpdateClientMembership
        userID={id}
        membership={detailData}
        updateClientMembershipModal={updateClientMembershipModal}
        setUpdateClientMembershipModal={setUpdateClientMembershipModal}
        refreshData={refreshData}
      />
    </div>
  );
}
