import {  backendApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceBackendApi } from '../../index';
import { toast } from 'react-toastify';

export async function clientBeneficiaryReq(userId) {
  try {
    const res = await instanceBackendApi.get(
      backendApiEndpoint.client_beneficiaries.replace('{{userId}}', userId),
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return [];
    }
  } catch (error) {
    CheckAuth(error);
    return [];
  }
}
export async function clientMandatesReq(userId) {
  try {
    const res = await instanceBackendApi.get(
      backendApiEndpoint.client_mandates.replace('{{userId}}', userId) ,
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return [];
    }
  } catch (error) {
    CheckAuth(error);
    return [];
  }
}
export async function clientBlacklistMandateReq(userId, beneficiaryId, data) {
  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(
      backendApiEndpoint.client_beneficiaries_mandates_blacklist.replace('{{userId}}', userId).replace('{{beneficiaryId}}', beneficiaryId) + '/add', data, queryParams
    );
    if (res.status === 200) {
      return 'success';
    } else {
      return 'fail';
    }
  } catch (error) {
    // toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
export async function clientBlacklistRemoveMandateReq(userId, beneficiaryId, data) {
  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(
      backendApiEndpoint.client_beneficiaries_mandates_blacklist.replace('{{userId}}', userId).replace('{{beneficiaryId}}', beneficiaryId) + '/remove', data, queryParams
    );
    if (res.status === 200) {
      return 'success';
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientSepaSddrRejectedReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_sepa_sddr_rejected.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientBeneficiaryAddReq(userId, data) {
  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(
      backendApiEndpoint.client_beneficiaries.replace('{{userId}}', userId), data, queryParams
    );
    if (res.status === 200) {
      return 'success';
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
export async function clientBeneficiaryDeleteReq(userId, beneficiaryId) {
  try {
    const res = await instanceBackendApi.delete(
      backendApiEndpoint.client_beneficiaries_detail.replace('{{userId}}', userId).replace('{{beneficiaryId}}', beneficiaryId),
    );
    if (res.status === 200) {
      return 'success';
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
export async function clientBeneficiaryRefreshReq(userId, beneficiaryId) {
  try {
    let queryParams = { params : {
      force : true,
    }
    }
    const res = await instanceBackendApi.get(backendApiEndpoint.client_beneficiaries_detail.replace('{{userId}}', userId).replace('{{beneficiaryId}}', beneficiaryId),queryParams);
    if (res.status === 200) {
      return 'success';
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
