import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { deleteUserReq } from '../../../../api/user/users';
import IconCross from '../../../../assets/icons/cross.svg';

const DeleteUser = ({ deleteModal, setDeleteModal, selectedID }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={deleteModal} toggle={setDeleteModal}>
      <div className="o-modal-info">
        <img
          src={IconCross}
          alt="Close"
          className="icon-cancel"
          onClick={() => {
            setDeleteModal(false);
          }}
        />
        <div className="o-modal-info__title-text">
          <div className="o-title">{t('user:delete_user')}</div>
          <div
            className="o-modal-info__title-text__text"
            dangerouslySetInnerHTML={{ __html: t('user:delete_user_description') }}
          />
        </div>
        <div className="o-btn-group">
          <Button
            type="submit"
            onClick={() => {
              deleteUserReq(selectedID).then((res) => {
                if (res === 'success') {
                  setTimeout(() => {
                    window.location.href = '/dashboard/admin/users';
                  }, 700);
                }
              });
            }}
          >
            Confirm
          </Button>
          <Button
            className="cancel" btnType="outline"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteUser;
