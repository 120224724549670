import React, { useState } from 'react';
import * as Yup from 'yup';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { useTranslation } from 'react-i18next';
import { clientRefundReq } from '../../../../../api/client/billing';
import IconLoading from '../../../../../components/loading/loading.jsx';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import IconCross from '../../../../../assets/icons/cross.svg';

const RefundClientModal = ({ clientId, refundModal, setRefundModal, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const refundClientHandler = async (clientId, data) => {
    console.log(data);
    setLoading(true);
    let status = await clientRefundReq(clientId, data);
    setLoading(false);
    if (status === 'success') {
      setRefundModal(false);
      refreshData();
    }
  };

  const RefundClientInitialValues = {
    label: 'Remboursement',
  };

  const RefundClientSchema = Yup.object().shape({
    label: Yup.string().required('Requis'),
    amount: Yup.number().required('Requis').positive(),
  });

  return (
    <Modal isOpen={refundModal} toggle={setRefundModal}>
      <Formik
        enableReinitialize={true}
        initialValues={RefundClientInitialValues}
        validationSchema={RefundClientSchema}
        onSubmit={(values) => {
          refundClientHandler(clientId, values);
        }}
      >
        {(props) => (
          <Form>
            <div className="o-modal-info">
              <img
                src={IconCross}
                alt="Close"
                className="icon-cancel"
                onClick={() => {
                  setRefundModal(false);
                }}
              />
              <div className=" o-modal-info__title-text o-title">Remboursement</div>
              <div className="o-modal-info__fields">
                <Field
                  name="label"
                  className="mr-5"
                  label="Libellé du remboursement"
                  placeholder="Libellé du remboursement *"
                  component={InputFormik}
                />
                <Field
                  name="amount"
                  label="Montant du remboursement (€)"
                  placeholder="Montant du remboursement en €"
                  component={InputFormik}
                />
              </div>
              <div className="o-btn-group">
                <Button type="submit">
                  {loading ? (
                    <div className="d-flex mx-auto">
                      <IconLoading />
                    </div>
                  ) : (
                    t('global:confirm')
                  )}
                </Button>
                <Button className="cancel" btnType="outline" onClick={() => setRefundModal(false)}>
                  {t('global:cancel')}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RefundClientModal;
