import * as Yup from 'yup';
import React from 'react';
import { ButtonTable } from '../../../components/button/index';

export const CodePromoInitialValues = {
  code: '',
  description: '',
  activated: '',
};
export const CodePromoSchema = Yup.object().shape({
  code: Yup.string().max(50, '50 car. max').required('Requis'),
  description: Yup.string().max(255, '255 car. max').required('Requis'),
});

export const columns = [
  'Code unique',
  'Description complète',
  'Activé',
  'Modif.',
  'Suppr.',
];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.code.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.description.toString().toLowerCase().includes(searchInput.toLowerCase())
    );
  });
  setFilteredData(result);
};

export const renderTable = (res, setAddModal, setDeleteModal, setCurrentItem, isEdit, isDelete) => {
  let data = [];
  res.map((item) =>
    data.push({
      code: item.code,
      description: item.description,
      activated: item.activated ? 'Oui' : 'Non',
      modif: isEdit ? (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
            setCurrentItem(item);
            setAddModal(true);
          }}
        />
      ) : (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
      delete: isDelete ? (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
            setDeleteModal(true);
            setCurrentItem(item);
          }}
        />
      ) : (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
    }),
  );
  return data;
};
