import React, { useContext, useEffect } from 'react';
import './dashboard.scss';
import { useWindowDimensions } from '../utils/windowDimension';
import Navbar from '../../src/components/navbar/index.jsx';
import PermissionsContext from '../contexts/permissionContext';
import { localStorageKeys } from '../api/constants.js';
import { getAuthReq } from '../api/auth/index';
import * as constants from '../utils/constants';

const LayoutDashboard = (props) => {
  const { setPermContext } = useContext(PermissionsContext);
  const isMobile = useWindowDimensions().width <= 900;
  const isDesktop = useWindowDimensions().width > 900;

  useEffect(() => {

    let storedPermissions = localStorage.getItem(localStorageKeys.permissions);
    if (storedPermissions != null) {
      let storedPermissionsParsed = JSON.parse(storedPermissions);
      setPermContext(storedPermissionsParsed);
    } else {
      getAuthReq().then((res) => {
        if (res) {
          // Administration
          let permissionRead = res.permissions.some((item) => item.code === constants.ACL_READ_PERMISSION);
          let permissionUpdate = res.permissions.some((item) => item.code === constants.ACL_UPDATE_PERMISSION);
          let permissionDelete = res.permissions.some((item) => item.code === constants.ACL_DELETE_PERMISSION);

          let groupRead = res.permissions.some((item) => item.code === constants.ACL_READ_GROUP);
          let groupUpdate = res.permissions.some((item) => item.code === constants.ACL_UPDATE_GROUP);
          let groupDelete = res.permissions.some((item) => item.code === constants.ACL_DELETE_GROUP);

          let userRead = res.permissions.some((item) => item.code === constants.ACL_READ_USER);
          let userUpdate = res.permissions.some((item) => item.code === constants.ACL_UPDATE_USER);
          let userDelete = res.permissions.some((item) => item.code === constants.ACL_DELETE_USER);

          let docRead = res.permissions.some((item) => item.code === constants.ACL_READ_DOCUMENT_TYPE);
          let docUpdate = res.permissions.some((item) => item.code === constants.ACL_UPDATE_DOCUMENT_TYPE);
          let docDelete = res.permissions.some((item) => item.code === constants.ACL_DELETE_DOCUMENT_TYPE);

          let featureRead = res.permissions.some((item) => item.code === constants.ACL_READ_MEMBERSHIP_FEATURE);
          let featureUpdate = res.permissions.some((item) => item.code === constants.ACL_UPDATE_MEMBERSHIP_FEATURE);
          let featureDelete = res.permissions.some((item) => item.code === constants.ACL_DELETE_MEMBERSHIP_FEATURE);

          let typeRead = res.permissions.some((item) => item.code === constants.ACL_READ_MEMBERSHIP_TYPE);
          let typeUpdate = res.permissions.some((item) => item.code === constants.ACL_UPDATE_MEMBERSHIP_TYPE);
          let typeDelete = res.permissions.some((item) => item.code === constants.ACL_DELETE_MEMBERSHIP_TYPE);

          let codePromoRead = res.permissions.some((item) => item.code === constants.ACL_READ_CODE_PROMO);
          let codePromoUpdate = res.permissions.some((item) => item.code === constants.ACL_UPDATE_CODE_PROMO);
          let codePromoDelete = res.permissions.some((item) => item.code === constants.ACL_DELETE_CODE_PROMO);

          let adminRead = permissionRead || groupRead || userRead || docRead || featureRead || typeRead || codePromoRead;

          // Client
          let clientRead = res.permissions.some((item) => item.code === constants.ACL_READ_CLIENT);
          let clientUpdate = res.permissions.some((item) => item.code === constants.ACL_UPDATE_CLIENT);
          let clientDelete = res.permissions.some((item) => item.code === constants.ACL_DELETE_CLIENT);

          // Partnership
          let partnershipRead = res.permissions.some((item) => item.code === constants.ACL_READ_PARTNERSHIP);
          let partnershipUpdate = res.permissions.some((item) => item.code === constants.ACL_UPDATE_PARTNERSHIP);

          let reportRead = res.permissions.some((item) => item.code === constants.ACL_READ_REPORT);

          const userID = res.id;
          const market = process.env.REACT_APP_MARKET;
          let permissions = {
            permissionRead,
            permissionUpdate,
            permissionDelete,

            groupRead,
            groupUpdate,
            groupDelete,

            userRead,
            userUpdate,
            userDelete,

            featureRead,
            featureUpdate,
            featureDelete,

            typeRead,
            typeUpdate,
            typeDelete,

            docRead,
            docUpdate,
            docDelete,

            codePromoRead,
            codePromoUpdate,
            codePromoDelete,

            adminRead,

            clientRead,
            clientUpdate,
            clientDelete,

            partnershipRead,
            partnershipUpdate,

            reportRead,

            userID,
            market,
          };
          storedPermissions = JSON.stringify(permissions);
          localStorage.setItem(localStorageKeys.permissions, storedPermissions);

          setPermContext(permissions);
      }
      });
    }
    if (!localStorage.getItem(localStorageKeys.onlyone_token)) {
      window.location.href = '/';
    }
  }, []); 
  return (
    <div>
      <div className={isMobile ? 'o-overlay' : 'hidden'} /> 

      {<Navbar
        className={isDesktop  ? 'show' : 'hidden'}
      /> }
      <div className="o-layout-dashboard__content">{props.children}</div>
    </div>
  );
};
export default LayoutDashboard;


