import React, { useState, useEffect, Fragment, useContext } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Head from '../../../components/head';
import Table from '../../../components/table';
import Button from '../../../components/button';
import Logo from '../../../components/logo';
import IconEdit from '../../../assets/icons/edit.svg';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import IconLoading from '../../../components/loading/loading.jsx';

import IconCheck from '../../../assets/icons/check.svg';

import IconCoin from '../../../assets/icons/_SmallIcons/ic_coin.svg';
import { detailClientReq } from '../../../api/client/detailClient';
import PermissionsContext from '../../../contexts/permissionContext';

import {
  clientBillingHistoryReq, //collectClientBillingHistoryReq (jamais utilisé)
} from '../../../api/client/billing';
import { tableBillingsHistoryHeader, renderBillingsHistoryTable } from './billings.utils';

import ConfirmationCollectBillingsHistory from './modals/billings/confirmationCollectBillingsHistory';
import ConfirmationCreateFutureFees from './modals/billings/confirmationCreateFutureFees';
import ConfirmationDeleteBillingHistory from './modals/billings/confirmationDeleteBillingHistory';
import RefundClientModal from './modals/billings/refundClientModal';
import ConfirmationCollectFees from './modals/billings/confirmationCollectFees';

const ClientBillingsHistory = () => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState();
  const [detailData, setDetailData] = useState();
  const [billingsHistoryDetailTableData, setBillingsHistoryTableData] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [allBillingsHistory, setAllBillingsHistory] = useState([]);
  const [selectedBillingsHistory, setSelectedBillingsHistory] = useState();
  const [collectBillingsHistoryOpen, setCollectBillingsHistoryOpen] = useState(false);
  const [deleteBillingHistoryOpen, setDeleteBillingHistoryOpen] = useState(false);
  const [createFutureFeesOpen, setCreateFutureFeesOpen] = useState(false);
  const [collectFeesOpen, setCollectFeesOpen] = useState(false);
  const [clientRefundOpen, setClientRefundOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingIdentification, setLoadingIdentification] = useState('loading');
  const [status, setStatus] = useState('loading');

  const [selectedItem, setSelectedItem] = useState();

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    detailClientReq(id)
      .then((res) => {
        setDetailData(res);
        refreshData();
      })
      .finally(() => setStatus('done'));
  }, []); // manque 'refreshData

  useEffect(() => {
    const billingsHistoryDetail = allBillingsHistory.find(
      (x) => x.user_feature_history_id === selectedID,
    );
    setSelectedBillingsHistory(billingsHistoryDetail);
    setSelectedItem('billing');
  }, [selectedID]); // manque 'allBillingHistory'

  const refreshData = () => {
    setLoadingIdentification('loading');
    clientBillingHistoryReq(id)
      .then((res) => {
        console.log(res);
        setAllBillingsHistory(res);
        setBillingsHistoryTableData(
          renderBillingsHistoryTable(res, setDeleteBillingHistoryOpen, setSelectedBillingsHistory),
        );
      })
      .finally(() => setLoadingIdentification('done'));
  };

  const refreshDataClient = (force) => {
    //  'refreshDataClient' (jamais utilisé)
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Historique de la facturation
        </div>
      </Fragment>
    );
  }

  const renderSelectedBillingsHistoryActions = (selectedBillingsHistory) => {
    return (
      <div className="o-information-detail__actions">
        {selectedBillingsHistory?.transfer ? (
          ''
        ) : (
          <Button btnType="outline" onClick={() => setCollectBillingsHistoryOpen(true)}>
            <img className="mr-2" height="14" src={IconCheck} alt="" />
            Collecter la facturation
          </Button>
        )}
      </div>
    );
  };

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-grid">
        <div className="mx-3 mb-3">
          {status === 'loading' ? (
            <div className="d-flex mx-auto mt-4">
              <IconLoading size={40} />
            </div>
          ) : (
            <div className="o-information">
              <div className="my-3">
                <b>Informations client</b>
              </div>
              <div className="o-information-row">
                <div className="o-information-column">
                  <div className="o-information--block">
                    <div className="o-information-detail__item mb-2">
                      <div>UserId | TrzUserId</div>
                      <div>
                        {detailData?.userId} | {detailData?.trzUserId}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Prénom</div>
                      <div className="ml-2">
                        {detailData?.firstname ? detailData?.firstname : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Nom</div>
                      <div className="ml-2">
                        {detailData?.lastname ? detailData?.lastname : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Date de naissance</div>
                      <div className="ml-2">
                        {detailData?.birthday
                          ? moment(detailData?.birthday).format('DD/MM/YYYY')
                          : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Statut KYC</div>
                      <div>{detailData?.userStatus}</div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Bloqué</div>
                      <div>{detailData?.blocked ? 'Oui' : 'Non'}</div>
                    </div>
                  </div>
                </div>
                <div className="o-information-column">
                  <div className="o-information--block">
                    <div className="o-information-detail__item mb-2">
                      <div>Date de création</div>
                      <div>
                        {detailData?.created
                          ? moment(detailData?.created).format('DD/MM/YYYY HH:mm')
                          : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Dernière mise à jour</div>
                      <div>
                        {detailData?.updated
                          ? moment(detailData?.updated).format('DD/MM/YYYY HH:mm')
                          : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Date de validation</div>
                      <div>
                        {detailData?.validation_date
                          ? moment(detailData?.validation_date).format('DD/MM/YYYY HH:mm')
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="o-information--block">
                    <div className="o-information-detail__item mb-2">
                      <div>Code Promo</div>
                      <div className="ml-2">
                        {detailData?.code_promo ? detailData?.code_promo : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Code parrain</div>
                      <div className="ml-2">
                        {detailData?.code_parrain ? detailData?.code_parrain : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Code parent</div>
                      <div className="ml-2">
                        {detailData?.code_parent ? detailData?.code_parent : '-'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="o-information-column">
                  <div className="o-information--block mb-2">
                    <div className="o-information-detail__item mb-2">
                      <div>KYC Level</div>
                      <div className="ml-2">
                        {detailData?.kycLevel ? detailData?.kycLevel : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>KYC Review</div>
                      <div className="ml-2">
                        {detailData?.kycReview ? detailData?.kycReview : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>KYC Review Comment</div>
                      <div className="ml-2">
                        {detailData?.kycReviewComment ? detailData?.kycReviewComment : '-'}
                      </div>
                    </div>
                    <div className="o-information-detail__item mb-2">
                      <div>Freezed</div>
                      <div className="ml-2">{detailData?.isFreezed}</div>
                    </div>
                  </div>
                </div>
                <div className="o-information-column">
                  <div className="my-3">
                    <b>Actions</b>
                  </div>
                  <div className="o-information-detail__actions">
                    <Button btnType="outline" onClick={() => setClientRefundOpen(true)}>
                      <img className="mr-2" height="14" src={IconCheck} alt="" />
                      Remboursement
                    </Button>
                  </div>
                  <div className="o-information-detail__actions">
                    <Button btnType="outline" onClick={() => setCreateFutureFeesOpen(true)}>
                      <img className="mr-2" height="14" src={IconEdit} alt="" />
                      Element à facturer
                    </Button>
                  </div>
                  <div className="o-information-detail__actions">
                    <Button btnType="outline" onClick={() => setCollectFeesOpen(true)}>
                      <img className="mr-2" src={IconCoin} alt="" width="25" />
                      Frais immédiat
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="table-title">
            Historique de facturation
            <Table
              columns={tableBillingsHistoryHeader}
              tableData={billingsHistoryDetailTableData}
              status={loadingIdentification}
              setShowModal={setShowModal}
              setSelectedID={setSelectedID}
              ContainHeader={() => ''}
              contain
            />
          </div>
        </div>
        <div className="o-viewer">
          {selectedID && selectedItem === 'billing' && (
            <div>
              <div>
                <div className="mt-5">
                  <b>Informations Facturation</b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div>
                        <b>Identification ID</b>
                      </div>
                      <div>{selectedBillingsHistory?.user_feature_history_id}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Date</b>
                      </div>
                      <div>
                        {selectedBillingsHistory?.date
                          ? moment(selectedBillingsHistory.date).format('MMMM YYYY')
                          : '-'}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Item</b>
                      </div>
                      <div>{selectedBillingsHistory?.feature?.name}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Montant HT</b>
                      </div>
                      <div>
                        {selectedBillingsHistory?.amount
                          ? selectedBillingsHistory.amount.toFixed(2) + '€'
                          : '- €'}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>TVA</b>
                      </div>
                      <div>
                        {(selectedBillingsHistory?.vat
                          ? Math.round(selectedBillingsHistory.vat.rate * 100)
                          : '-') + '%'}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Montant TTC</b>
                      </div>
                      <div>
                        {(selectedBillingsHistory?.amount
                          ? (
                              selectedBillingsHistory.amount *
                              (1 + selectedBillingsHistory.vat.rate)
                            ).toFixed(2)
                          : '-') + '€'}
                      </div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Facturé (O/N)</b>
                      </div>
                      <div>{selectedBillingsHistory?.transfer ? 'Oui' : 'Non'}</div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <b>Actions</b>
                  <hr />
                  {renderSelectedBillingsHistoryActions(selectedBillingsHistory)}
                </div>
              </div>
            </div>
          )}
          {!selectedID && <Logo className="o-viewer-logo" />}
        </div>
      </div>
      <ConfirmationCollectBillingsHistory
        collectBillingsHistoryOpen={collectBillingsHistoryOpen}
        setCollectBillingsHistoryOpen={setCollectBillingsHistoryOpen}
        userId={id}
        userFeatureHistory={selectedBillingsHistory}
        refreshData={refreshData}
      />
      <ConfirmationDeleteBillingHistory
        deleteBillingHistoryOpen={deleteBillingHistoryOpen}
        setDeleteBillingHistoryOpen={setDeleteBillingHistoryOpen}
        userId={id}
        userFeatureHistory={selectedBillingsHistory}
        refreshData={refreshData}
      />
      <ConfirmationCreateFutureFees
        createFutureFeesOpen={createFutureFeesOpen}
        setCreateFutureFeesOpen={setCreateFutureFeesOpen}
        userId={id}
        refreshData={refreshData}
      />
      <RefundClientModal
        clientId={id}
        refundModal={clientRefundOpen}
        setRefundModal={setClientRefundOpen}
        refreshData={refreshData}
      />
      <ConfirmationCollectFees
        collectFeesOpen={collectFeesOpen}
        setCollectFeesOpen={setCollectFeesOpen}
        userData={detailData}
        refreshData={refreshData}
      />
    </div>
  );
};

export default ClientBillingsHistory;
