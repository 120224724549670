import { API_KEY } from '../utils/constants-env.js';

export { BASE_URL, API_KEY, BACKEND_API_BASE_URL, FRIDAY_API_BASE_URL,
CEREBRO_API_BASE_URL, SMAUG_API_BASE_URL, SIDEKICK_API_BASE_URL, NOTIF_CENTER_API_BASE_URL,
SAURON_API_BASE_URL, SCROOGE_API_BASE_URL } from '../utils/constants-env.js';

export const localStorageKeys = {
  onlyone_token: 'onlyone-token',
  permissions: 'permissions',
  clients_searched: 'clients-searched',
};

export const endpoint = {
  key: '?key=' + API_KEY,
  service_package_versions: '/_ah/api/backend/v1/versions?key=' + API_KEY,
  documentConfig: '/_ah/api/backend/v1/document/types/config',
  packageFeature: '/_ah/api/backend/v1/membership/features',
  packageFeatureConfig: '/_ah/api/backend/v1/membership/features/config',
  packageType: '/_ah/api/backend/v1/membership/types',
  packageTypeConfig: '/_ah/api/backend/v1/membership/types/config',
  assignedPackageFeature: '/_ah/api/backend/v1/membership/types/features',
  client: '/_ah/api/backend/v1/users',
  viaLink: '/backend/download/users/kyc/vialink?userId=',
  submitKYC: '/_ah/api/backend/v1/users/',
  lockAccount: '/_ah/api/backend/v1/users/',
  deleteInfo: '/_ah/api/backend/v1/users',
  clientDownload: '/backend/download/users',
  transfers: '_ah/api/backend/v1/transfers',
  payoutDetail: '/_ah/api/backend/v1/users/{{userId}}/payouts/{{payoutId}}',
};

export const backendApiEndpoint = {
  jwttoken: '/api/v1/openid/token',
  service_package_versions: '/api/v1/_ah/versions',
  remoteconfig_update:'/api/v1/firebase/remoteconfig/update',
  config_country_phones: '/api/v1/config/country_phones',
  config_trust_level: '/api/v1/config/trustlevel',
  config_user_acl_roles: '/api/v1/config/useraclroles',
  config_document_types: '/api/v1/back/config/document/types',
  acl_auth: '/api/v1/acl/users/auth',
  acl_permission: '/api/v1/acl/permissions',
  acl_permission_detail: '/api/v1/acl/permissions/{{permissionId}}',
  acl_groups: '/api/v1/acl/groups',
  acl_groups_detail: '/api/v1/acl/groups/{{groupId}}',
  acl_users: '/api/v1/acl/users',
  acl_users_detail: '/api/v1/acl/users/{{userId}}',
  document_type_config: '/api/v1/back/config/document/types/config',
  document_type_list: '/api/v1/back/config/document/types',
  document_type_detail: '/api/v1/back/config/document/types/{{documentTypeId}}',
  membership_type_list: '/api/v1/membership/types',
  membership_type_detail: '/api/v1/membership/types/{{membershipTypeId}}',
  partnership_list: '/api/v1/back/partnerships',
  partnership_detail: '/api/v1/back/partnerships/{{ooPartnershipId}}',
  client_search_page: '/api/v1/users/search/page',
  client_search_by_code_promo: '/api/v1/users/search/bycodepromo',
  client_search_by_code_promo_download: '/api/v1/users/search/bycodepromo/download',
  client_search_by_status_download: '/api/v1/users/bystatus/{{status}}/download',
  client_detail: '/api/v1/users/{{userId}}',
  client_children: '/api/v1/users/{{userId}}/children',
  client_members: '/api/v1/users/{{userId}}/members',
  client_code_promo: '/api/v1/users/{{userId}}/codepromo',
  client_update_status: '/api/v1/users/{{userId}}/status',
  client_update_phone: '/api/v1/users/{{userId}}/mobile',
  client_update_email: '/api/v1/users/{{userId}}/email',
  client_update_infos: '/api/v1/users/{{userId}}/infos',
  client_update_legalinfos: '/api/v1/users/{{userId}}/legalinfos',
  client_update_trust_level: '/api/v1/users/{{userId}}/trustlevel',
  client_update_acl_role: '/api/v1/back/users/{{userId}}/aclrole',
  client_history_http: '/api/v1/users/{{userId}}/http/history',
  client_summary_http: '/api/v1/users/{{userId}}/http/history/summary',
  client_bycountry_http: '/api/v1/users/{{userId}}/http/history/bycountry',
  client_byplateform_http: '/api/v1/users/{{userId}}/http/history/byplateform',
  client_download_http: '/api/v1/users/{{userId}}/http/history/download',
  client_beneficiaries: '/api/v1/back/users/{{userId}}/beneficiaries',
  client_mandates: '/api/v1/back/users/{{userId}}/mandates',
  client_beneficiaries_detail: '/api/v1/back/users/{{userId}}/beneficiaries/{{beneficiaryId}}',
  client_beneficiaries_mandates_blacklist: '/api/v1/back/users/{{userId}}/beneficiaries/{{beneficiaryId}}/mandates/blacklist',
  client_sepa_sddr_rejected : '/api/v1/back/users/{{userId}}/sepassddr/rejected',
  client_kyc_accept: '/api/v1/users/{{userId}}/kyc/onlyone/accept',
  client_kyc_refuse: '/api/v1/users/{{userId}}/kyc/onlyone/refuse',
  client_kyc_kycliveness: '/api/v1/users/{{userId}}/kyc/kycliveness',
  client_kyc_kycliveness_mail: '/api/v1/users/{{userId}}/kyc/kycliveness/sendemail',
  client_kyc_kycliveness_sms: '/api/v1/users/{{userId}}/kyc/kycliveness/sendsms',
  client_kyc_kycliveness_report_download: '/api/v1/users/{{userId}}/kyc/kycliveness/documents/{{documentId}}/download',
  client_kyc_kycliveness_report_generate_download: '/api/v1/users/{{userId}}/kyc/kycliveness/documents/{{documentId}}/generateanddownload',
  client_kyc_kycreview: '/api/v1/users/{{userId}}/kyc/askreview',
  client_membership: '/api/v1/users/{{userId}}/membership',
  client_membership_detail: '/api/v1/users/{{userId}}/membership/detail',
  client_membership_limits: '/api/v1/users/{{userId}}/membership/limits',
  wallet_balance_history: '/api/v1/users/{{userId}}/wallets/{{walletId}}/balances/history',
  wallet_line_detail: '/api/v1/transactions/{{transactionId}}',
  client_wallet_list: '/api/v1/users/{{userId}}/wallets',
  client_wallet_detail: '/api/v1/users/{{userId}}/wallets/{{walletId}}',
  client_wallet_transactions: '/api/v1/users/{{userId}}/wallets/{{walletId}}/transactions',
  client_card_list: '/api/v1/users/{{userId}}/cards',
  client_card_detail: '/api/v1/users/{{userId}}/cards/{{cardId}}',
  client_card_limits: '/api/v1/users/{{userId}}/cards/{{cardId}}/limits',
  client_card_options: '/api/v1/users/{{userId}}/cards/{{cardId}}/options',
  client_card_virtual: '/api/v1/users/{{userId}}/wallets/{{walletId}}/cards/virtual',
  client_card_physical: '/api/v1/users/{{userId}}/wallets/{{walletId}}/cards/physical',
  client_card_virtual_convert: '/api/v1/users/{{userId}}/wallets/{{walletId}}/cards/{{cardId}}/convert',
  client_card_detail_status: '/api/v1/users/{{userId}}/wallets/{{walletId}}/cards/{{cardId}}/status',
  client_card_detail_activate: '/api/v1/users/{{userId}}/wallets/{{walletId}}/cards/{{cardId}}/activate',
  client_card_detail_setpin: '/api/v1/users/{{userId}}/wallets/{{walletId}}/cards/{{cardId}}/pin',
  client_card_detail_unlockpin: '/api/v1/users/{{userId}}/wallets/{{walletId}}/cards/{{cardId}}/unlockpin',
  client_billing_history: '/api/v1/users/billing/{{userId}}/history',
  client_billing_history_detail: '/api/v1/users/billing/{{userId}}/history/{{userFeatureHistoryId}}',
  client_billing_history_detail_billing: '/api/v1/users/billing/{{userId}}/history/{{userFeatureHistoryId}}/billing',
  client_create_fees: '/api/v1/users/billing/{{userId}}/fees/submit',
  client_collect_fees: '/api/v1/users/billing/{{userId}}/fees/collect',
  client_collect_atd: '/api/v1/users/billing/{{userId}}/atd/collect',
  client_refund: '/api/v1/users/billing/{{userId}}/refund',
  client_notes: '/api/v1/users/{{userId}}/notes',
  client_documents: '/api/v1/back/users/{{userId}}/documents',
  client_documents_detail: '/api/v1/back/users/{{userId}}/documents/{{documentId}}',
  client_documents_detail_download: '/api/v1/back/users/{{userId}}/documents/{{documentId}}/download',
  client_statements: '/api/v1/statement/{{userId}}/all',
  client_statements_detail: '/api/v1/statement/{{statementId}}',
  client_statements_download: '/api/v1/statement/{{statementId}}/download',
  client_statements_generate: '/api/v1/statement/{{statementId}}/generate',
  client_godchildren_list: '/api/v1/users/{{userId}}/godchildren',
  client_sca_wallet_list: '/api/v1/back/users/{{userId}}/sca/wallets',
  client_sca_wallet_detail: '/api/v1/back/users/{{userId}}/sca/wallets/{{scaWalletId}}',
  client_sca_wallet_lock: '/api/v1/back/users/{{userId}}/sca/wallets/{{scaWalletId}}/lock',
  client_sca_wallet_unlock: '/api/v1/back/users/{{userId}}/sca/wallets/{{scaWalletId}}/unlock',
  client_sca_wallet_resetpin: '/api/v1/back/users/{{userId}}/sca/wallets/{{scaWalletId}}/pin',
  wallet_rib: '/api/v1/back/rib/download/{{walletId}}',
  monitoring_fraud_suspected: '/api/v1/monitoring/users/fraud/suspected',
  monitoring_billing_to_recover: '/api/v1/monitoring/users/billing/torecover',
  monitoring_payin_alert_page : '/api/v1/monitoring/payin/alerts/page',
  monitoring_payin_alert_status : '/api/v1/monitoring/payin/alerts/status',
  monitoring_sepa_sddr_rejected : '/api/v1/monitoring/sepassddr/rejected',
  monitoring_user_wallet_overdraft: '/api/v1/monitoring/users/wallet/overdraft',
  transfers: '/api/v1/transfers',
  scheduled_transfers: '/api/v1/{{userId}}/transfers/scheduled',
  scheduled_transfers_detail: '/api/v1/{{userId}}/transfers/scheduled/{{scheduledTransferId}}',
  code_promo: '/api/v1/codepromos',
  invitation_code: '/api/v1/invitationcodes',
  report_overview: '/api/v1/dashboard/overview',
  report_incomes: '/api/v1/dashboard/incomes',
  report_incomes_by_types: '/api/v1/dashboard/incomes/bytypes',
  report_validatedusers: '/api/v1/dashboard/validatedusers',
  report_userstatus_history: '/api/v1/analytics/users/status/history',
  waitinglist: '/api/v1/waitinglist',
  waitinglist_types: '/api/v1/waitinglist/types',
  waitinglist_status: '/api/v1/waitinglist/status',
  waitinglist_search: '/api/v1/waitinglist/search',
};

export const fridayApiEndpoint = {
  service_package_versions: '/api/v1/_ah/versions',
  config_income_range: '/api/v1/{{market}}/config/incomes',
  config_personal_assets: '/api/v1/{{market}}/config/personalassets',
  config_legal_forms: '/api/v1/{{market}}/config/legalforms',
  config_legal_annual_net_income: '/api/v1/{{market}}/config/annual/netincome',
  config_legal_annual_turnover: '/api/v1/{{market}}/config/annual/turnover',
  config_legal_number_of_employees: '/api/v1/{{market}}/config/numberofemployees',
  client_statements: '/api/v1/statement/{{userId}}/all',
  client_topupcards: '/api/v1/{{market}}/back/topup/{{userId}}/card/payments',
  card_digitalization: '/api/v1/back/xpay/{{cardId}}/digitizations',
  card_digitalization_detail: '/api/v1/back/xpay/{{cardId}}/digitizations/{{cardDigitalizationId}}',
};

export const cerebroApiEndpoint = {
  service_package_versions: '/api/v1/_ah/versions',
  client_bridge_paymentlink: '/api/v1/bridge/payment-link/users/{{userId}}/list',
  client_bridge_items: '/api/v1/bridge/items/users/{{userId}}/list',
  client_bridge_accounts: '/api/v1/users/{{userId}}/bridge/items/{{itemId}}/accounts',
};

export const smaugApiEndpoint = {
  service_package_versions: '/api/v1/_ah/versions',
};

export const notifCenterApiEndpoint = {
  service_package_versions: '/api/v1/_ah/versions',
  client_history_events: '/api/v1/history/events/notif/{{userId}}',
};

export const sauronApiEndpoint = {
  service_package_versions: '/api/v1/_ah/versions',
};

export const sidekickApiEndpoint = {
  service_package_versions: '/api/v1/_ah/versions',
  isic_cards: '/api/v1/back/isic/users/{{ooUserId}}/cards',
  isic_card_detail: '/api/v1/back/isic/users/{{ooUserId}}/cards/{{isicCardId}}',
  isic_card_detail_image: '/api/v1/back/isic/users/{{ooUserId}}/cards/{{isicCardId}}/image',
  isic_card_detail_files: '/api/v1/back/isic/users/{{ooUserId}}/cards/{{isicCardId}}/files',
  isic_card_import_isicnumber: '/api/v1/back/isic/users/{{ooUserId}}/cards/byisicnumber',
};

export const scroogeApiEndpoint = {
  service_package_versions: '/api/v1/_ah/versions',
};
