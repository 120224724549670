import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Head from '../../../components/head';
import TablePage from '../../../components/tablepage';
import Button from '../../../components/button';
import TransactionCard from '../../../components/transaction';
import Logo from '../../../components/logo';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { ArrowRight } from '../../../assets/icons/icons-arrow';
import IconDoc from '../../../assets/icons/doc.svg';
import IconLoading from '../../../components/loading/loading.jsx';
import { handleRib, generateRibReq } from '../../../api/client/wallet/rib';
import { clientWalletReq, clientWalletTransactionPageReq } from '../../../api/client/wallet';
import { tableTransactionsHeader, renderTransactionsTable } from './wallet.utils';
import { walletLineDetailReq } from '../../../api/client/walletline/walletline';

const ClientWallet = () => {
  const { id, walletId } = useParams();
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [ribLoading, setRibLoading] = useState(false);
  const [generateRibLoading, setgenerateRibLoading] = useState(false);
  const [refreshCardLoading, setRefreshCardLoading] = useState(false);

  const [allTransactions, setAllTransactions] = useState([]);
  const [transactionTableData, setTransactionTableData] = useState([]);
  const [transactionTableStatus, setTransactionTableStatus] = useState('loading');
  const [transactionPageMetaData, setTransactionPageMetaData] = useState([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState();
  const [selectedTransaction, setSelectedTransaction] = useState();

  const [forceCodeModal, setForceCodeModal] = useState(false);
  const [lostCardModal, setLostCardModal] = useState(false);
  const [renewCardModal, setRenewCardModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);

  const [selectedID, setSelectedID] = useState();
  const [selectedItem, setSelectedItem] = useState();

  const refreshTransactionList = (pageNo, pageSize) => {
    setTransactionTableStatus('loading');
    clientWalletTransactionPageReq(id, walletId, pageNo, pageSize)
      .then((res) => {
        setAllTransactions(res.content);
        setTransactionTableData(renderTransactionsTable(res.content));
        setTransactionPageMetaData({ count: res.totalElements });
      })
      .finally(() => {
        setTransactionTableStatus('done');
        setSelectedTransactionId(null);
        setSelectedTransaction(null);
        setSelectedID(null);
        setSelectedItem(null);
      });
  };

  useEffect(() => {
    clientWalletReq(id, walletId).then((res) => {
      setTableData(res);
    });
  }, []);

  useEffect(() => {
    const transaction = allTransactions.find((x) => x.walletLineId === selectedTransactionId);
    // On récupère les informations de la transaction
    if (selectedTransactionId) {
      walletLineDetailReq(selectedTransactionId).then((res) => {
        setSelectedTransaction(res);
        setSelectedItem('transaction');
      });
    }
  }, [selectedTransactionId]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Détails wallet
        </div>
      </Fragment>
    );
  }

  const downloadRib = async (walletId) => {
    if (walletId) {
      setRibLoading(true);
      const res = await handleRib(walletId);
      setRibLoading(false);
    }
  };

  const generateRib = async (walletId) => {
    if (walletId) {
      setgenerateRibLoading(true);
      const res = await generateRibReq(walletId);
      setgenerateRibLoading(false);
    }
  };

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-grid">
        <div className="mx-3 mb-3">
          <div className="o-information-detail__block">
            <div className="o-information-column">
              <div className="o-information-flex">
                <div className="o-information--block">
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Wallet ID | TrzWalletId</b>
                    </div>
                    <div className="ml-2">
                      {tableData?.walletId} | {tableData?.trzWalletId}
                    </div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Type</b>
                    </div>
                    <div className="ml-2">{tableData?.type}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Nom</b>
                    </div>
                    <div className="ml-2">{tableData?.name}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Alias</b>
                    </div>
                    <div className="ml-2">{tableData?.alias}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>IBAN</b>
                    </div>
                    <div className="ml-2">{tableData?.iban}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>BIC</b>
                    </div>
                    <div className="ml-2">{tableData?.bic}</div>
                  </div>
                </div>
                <div className="o-information--block">
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Solde</b>
                    </div>
                    <div className="ml-2">{tableData?.solde?.toFixed(2)}&nbsp;€</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Authorized balance</b>
                    </div>
                    <div className="ml-2">{tableData?.authorized_balance?.toFixed(2)}&nbsp;€</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Activé</b>
                    </div>
                    <div className="ml-2">{tableData?.activated ? 'TRUE' : 'FALSE'}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Statut</b>
                    </div>
                    <div className="ml-2">{tableData?.status}</div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>
                      <b>Devise</b>
                    </div>
                    <div className="ml-2">{tableData?.currency}</div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <b>
                  Historique de balance
                  <a
                    href={`/dashboard/client/${id}/wallet/${walletId}/balance/history`}
                    className="pl-2"
                  >
                    Voir détail <ArrowRight />
                  </a>
                </b>
              </div>
            </div>
            <div className="o-information-detail__actions">
              <b>&nbsp;</b>
              <Button btnType="outline" onClick={() => downloadRib(tableData?.walletId)}>
                {ribLoading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  <img className="mr-2" src={IconDoc} alt="" />
                )}
                Télécharger le RIB
              </Button>
              <b>&nbsp;</b>
              <Button btnType="outline" onClick={() => generateRib(tableData?.walletId)}>
                {' '}
                {generateRibLoading ? (
                  <IconLoading />
                ) : (
                  <img className="mr-2" src={IconDoc} alt="" />
                )}
                Regénérer le RIB
              </Button>
            </div>
          </div>
          <div className="table-title">
            Transactions
            <TablePage
              columns={tableTransactionsHeader}
              tableData={transactionTableData}
              pageMetaData={transactionPageMetaData}
              loadTableData={refreshTransactionList}
              status={transactionTableStatus}
              setShowModal={setShowModal}
              setSelectedID={setSelectedTransactionId}
              ContainHeader={() => ''}
              contain
            />
          </div>
        </div>
        <div className="o-viewer">
          {selectedItem === 'transaction' && selectedTransactionId && selectedTransaction && (
            <div>
              <TransactionCard
                transaction={selectedTransaction}
                userId={id}
                refreshData={() => {}}
              />
            </div>
          )}
          {!selectedID && <Logo className="o-viewer-logo" />}
        </div>
      </div>
    </div>
  );
};

export default ClientWallet;
