import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import { useTranslation } from 'react-i18next';
import { updateClientTrustLevel } from '../../../../api/client/updateClient';
import { trustLevelsReq } from '../../../../api/config/config';
import IconLoading from '../../../../components/loading/loading.jsx';
import IconCross from '../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationUpdateTrustLevel = ({
  updateTrustLevelOpen,
  setUpdateTrustLevelOpen,
  userData,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const [trustLevelData, setTrustLevelData] = useState([]);
  const [currentItem, setCurrentItem] = useState();
  const { t } = useTranslation();
  const updateTrustLevelHandler = async (id, values) => {
    setLoading(true);
    let reqParams = { trust_level_id: values.trust_level?.value };
    let status = await updateClientTrustLevel(id, reqParams);
    setLoading(false);
    setUpdateTrustLevelOpen(false);
    if (status === 'success') {
      refreshData();
    }
  };

  useEffect(() => {
    /*Get trustlevel list*/
    trustLevelsReq().then((res) => {
      let trustLevelValues = [];
      res.map((item) =>
        trustLevelValues.push({
          value: item.trust_level_id,
          label:
            item.trust_level_id +
            '-' +
            item.description +
            ' (Alerte > ' +
            item.alert_payin_limit +
            '€)',
        }),
      );
      setTrustLevelData(trustLevelValues);
    });
  }, []);

  const UpdateTrustLevelInitialValues = (data) => {
    return {
      trust_level: data
        ? { value: data.trust_level?.trust_level_id, label: data.trust_level?.description }
        : {},
    };
  };
  const UpdateTrustLevelSchema = Yup.object().shape({});

  return (
    <Modal isOpen={updateTrustLevelOpen} toggle={setUpdateTrustLevelOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={UpdateTrustLevelInitialValues(userData)}
        validationSchema={UpdateTrustLevelSchema}
        onSubmit={(values) => {
          console.log('Mise à jour du code promo');
          updateTrustLevelHandler(userData.userId, values).then((res) => {
            if (res === 'success') {
              setUpdateTrustLevelOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img
              src={IconCross}
              alt="Close"
              className="icon-cancel"
              onClick={() => setUpdateTrustLevelOpen(false)}
            />
            <div className="o-modal-info__title-text o-title">
              Modification du niveau de confiance du client
            </div>

            <div className="o-modal-info__fields">
              <Field
                name="trust_level"
                label=""
                placeholder="Niveau de confiance"
                component={Dropdown}
                options={trustLevelData}
              />
            </div>
            <Button className="confirm-infos" type="submit">
              {loading ? (
                <div className="d-flex mx-auto">
                  <IconLoading />
                </div>
              ) : (
                t('global:confirm')
              )}
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationUpdateTrustLevel;
