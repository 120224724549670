import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import IconCross from '../../../../../assets/icons/cross.svg';
import { deleteAssignedPackageFeatureReq } from '../../../../../api/assignedPackageFeature/deleteAssignedPackageFeature';

const DeleteAssignedPackageFeature = ({
  deleteModal,
  setDeleteModal,
  selectedID,
  features,
  id,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={deleteModal} toggle={setDeleteModal}>
      <div className="o-modal-info">
        <img
          src={IconCross}
          alt="Close"
          className="icon-cancel"
          onClick={() => {
            setDeleteModal(false);
          }}
        />
        <div className="o-modal-info__title-text">
          <div className="o-title">{t('type:delete_type_assigned')}</div>
          <div
            className="o-modal-info__title-text__text"
            dangerouslySetInnerHTML={{ __html: t('type:delete_type_description_assigned') }}
          />
        </div>
        <div className="o-btn-group">
          <Button
            type="submit"
            onClick={() => {
              const result = features.filter((item) => item.featureId === selectedID);
              const res = result[0];
              deleteAssignedPackageFeatureReq({
                featureId: res.featureId,
                membershipTypeId: id,
                type: res.type,
              }).then((res) => {
                if (res === 'success') {
                  setTimeout(() => {
                    window.location.reload();
                  }, 700);
                }
              });
            }}
          >
            {t('global:confirm')}
          </Button>
          <Button
            className="cancel" btnType="outline"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAssignedPackageFeature;
