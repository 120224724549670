import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { clientWalletListReq } from '../../../../api/client/wallet';
import {
  detailClientReq,
  clientNotesReq,
  clientChildrenReq,
} from '../../../../api/client/detailClient'; //  'clientChildrenReq  (jamais utilisé)

import ClientDetailFilterTabs from './client_filter_tabs';

import ConfirmationLock from '../modals/confirmationLock';
import ConfirmationDeleteInfo from '../modals/confirmationDelete';
import ConfirmationCollectATD from '../modals/confirmationCollectATD';
import ConfirmationCreateTransfer from '../modals/confirmationCreateTransfer';
import ConfirmationCreatePayout from '../modals/confirmationCreatePayout';
import ConfirmationKycAccept from '../modals/confirmationKycAccept';
import ConfirmationKycRefuse from '../modals/confirmationKycRefuse';
import ConfirmationAddNote from '../modals/confirmationAddNote';
import { ArrowRight } from '../../../../assets/icons/icons-arrow';
import { IconClient } from '../../../../assets/icons/icons-sidebar';

import IconEdit from '../../../../assets/icons/edit.svg';
import IconLoading from '../../../../components/loading/loading.jsx';
import IconTrash from '../../../../assets/icons/trash.svg'; // Ajout de l'icone poubelle

import IconHand from '../../../../assets/icons/hand.svg';
import IconCross from '../../../../assets/icons/red-cross.svg';
import IconCheck from '../../../../assets/icons/check.svg';
import Head from '../../../../components/head';
import Button from '../../../../components/button';
import Table from '../../../../components/table';
import { tableWalletHeader, renderWalletTable } from '../wallet.utils';
import PermissionsContext from '../../../../contexts/permissionContext';

function ClientDetailV2Operations() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [userNotes, setUserNotes] = useState([]);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [walletTableData, setWalletTableData] = useState([]);
  const [statusWalletTable, setStatusWalletTable] = useState('loading');
  const [showModal, setShowModal] = useState(false);
  const [lockModal, setLockModal] = useState(false);
  const [deleteInfoOpen, setDeleteInfoOpen] = useState(false);
  const [kycAcceptOpen, setKycAcceptOpen] = useState(false);
  const [kycRefuseOpen, setKycRefuseOpen] = useState(false);
  const [collectATDOpen, setCollectATDOpen] = useState(false);
  const [createTransferOpen, setCreateTransferOpen] = useState(false);
  const [createPayoutOpen, setCreatePayoutOpen] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [loading, setLoading] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  const search = useLocation().search;
  const status = new URLSearchParams(search).get('status');
  const requestSuffix = 'status=' + status;

  useEffect(() => {
    refreshData();

  }, []);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={status ? '/dashboard/clientbystatus/' + status : '/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    clientNotesReq(id).then((res) => setUserNotes(res));
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} HeadIcon={IconClient} disabledSearch />
        </div>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-information-detail">
          <div className="o-information-detail__block">
            <div className="o-information-detail__info">
              <div className="o-information-column">
                <div className="o-information--block">
                  <div className="o-information-detail__item mb-2">
                    <div>UserId | TrzUserId</div>
                    <div>
                      {detailData?.userId} | {detailData?.trzUserId}{' '}
                    </div>
                  </div>
                  {permContext?.market === 'pro' && detailData?.company_type && (
                    <div className="o-information-detail__item mb-2">
                      <div>Type d'Entreprise</div>
                      <div>{detailData?.company_type} </div>
                    </div>
                  )}
                  {permContext?.market === 'pro' && detailData?.parent && (
                    <div className="o-information-detail__item mb-2">
                      <div>Parent</div>
                      <div>
                        <a href={`/dashboard/client/${detailData?.parent?.userId}`} className="pl-2">
                          {detailData?.parent?.userId} <ArrowRight />
                        </a>
                      </div>
                    </div>
                  )}
                  <div className="o-information-detail__item mb-2">
                    <div>Création&nbsp;|&nbsp;MAJ</div>
                    <div>
                      {detailData?.created
                        ? moment(detailData?.created).format('DD/MM/YY HH:mm')
                        : '-'}{' '}
                      |{' '}
                      {detailData?.updated
                        ? moment(detailData?.updated).format('DD/MM/YY HH:mm')
                        : '-'}
                    </div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>Date de validation</div>
                    <div>
                      {detailData?.validation_date
                        ? moment(detailData?.validation_date).format('DD/MM/YY HH:mm')
                        : '-'}
                    </div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>Prénom | Nom</div>
                    <div>
                      {detailData?.firstname} | {detailData?.lastname}
                    </div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>E-mail / Téléphone</div>
                    <div>
                      {detailData?.email} | {detailData?.mobile ? detailData?.mobile : '-'}
                    </div>
                  </div>
                  <div className="o-information-detail__item mb-2">
                    <div>Date de naissance</div>
                    <div>
                      {detailData?.birthday ? moment(detailData?.birthday).format('DD/MM/YYYY') : '-'}{' '}
                      à {detailData?.placeOfBirth} ({detailData?.birthCountry})
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailV2Operations;
