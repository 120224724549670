import * as Yup from 'yup';
import React from 'react';
import { ButtonTable } from '../../../components/button/index';
import { ACL_UPDATE_GROUP, ACL_DELETE_GROUP } from '../../../utils/constants';

export const GroupInitialValues = {
  name: '',
  description: '',
  level: '',
};
export const GroupSchema = Yup.object().shape({
  name: Yup.string().max(255, '255 car. max').required('Requis'),
  description: Yup.string().max(255, '255 car. max').required('Requis'),
  level: Yup.number().required('Requis'),
});

export const columns = ['Nom', 'Description', 'Niveau', 'Permissions', 'Modif.', 'Supp'];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.description.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.level.toString().toLowerCase().includes(searchInput.toLowerCase())
    );
  });
  setFilteredData(result);
};

export const renderTable = (res, setDeleteModal, setAddModal, setSelectedID, isEdit, isDelete) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.id,
      name: item.name,
      description: item.description,
      level: item.level,
      permission: item.permissions && (
        <div className="o-table-element__list">
          {item.permissions.map((permission, index) => (
            <span key={index} className="o-badge">
              {permission.name}
            </span>
          ))}
        </div>
      ),
      modif:
        isEdit ||
        (item.permissions && item.permissions.some((item) => item.code === ACL_UPDATE_GROUP)) ? (
          <ButtonTable
            action="edit"
            onClick={(e) => {
              e.stopPropagation();
              setAddModal(true);
              setSelectedID(item.id);
            }}
          />
        ) : (
          <ButtonTable
            action="edit"
            onClick={(e) => {
              e.stopPropagation();
            }}
            disabled
          />
        ),
      delete:
        isDelete ||
        (item.permissions && item.permissions.some((item) => item.code === ACL_DELETE_GROUP)) ? (
          <ButtonTable
            action="delete"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteModal(true);
              setSelectedID(item.id);
            }}
          />
        ) : (
          <ButtonTable
            action="delete"
            onClick={(e) => {
              e.stopPropagation();
            }}
            disabled
          />
        ),
    }),
  );
  return data;
};
