import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Head from '../../../../components/head';
import Table from '../../../../components/table';
import Button from '../../../../components/button';
import Logo from '../../../../components/logo';
import { IconClient } from '../../../../assets/icons/icons-sidebar';
import IconCancel from '../../../../assets/icons/cancel.png';
import { getScheduledTransferReq } from '../../../../api/transfers/transfers';
import {
  tableScheduledTransfersHeader,
  renderScheduledTransfersTable,
} from './scheduledtransfers.utils';

const ClientScheduledTransfers = () => {
  const { id, scheduledTransfersId } = useParams();
  const [loading, setLoading] = useState(false);
  const [statusScheduledTransfersTable, setStatusScheduledTransfersTable] = useState('loading');
  const [scheduledTransfersTableData, setScheduledTransfersTableData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [cardTableData, setCardTableData] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [allScheduledTransfers, setAllScheduledTransfers] = useState([]);
  const [selectedScheduledTransfers, setSelectedScheduledTransfers] = useState();
  const [deleteScheduledTransfersOpen, setDeleteScheduledTransfersOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState();

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    const scheduledTransfers = allScheduledTransfers.find(
      (x) => x.scheduledTransfersId === selectedID,
    );
    setSelectedScheduledTransfers(scheduledTransfers);
    setSelectedItem('scheduledTransfers');
  }, [selectedID]);

  const refreshData = () => {
    setLoading(true);
    getScheduledTransferReq(id)
      .then((res) => {
        setLoading(false);
        setAllScheduledTransfers(res);
        setScheduledTransfersTableData(renderScheduledTransfersTable(res));
      })
      .finally(() => setStatusScheduledTransfersTable('done'));
  };

  // const refreshScheduledTransfersData = (id, scheduledTransfersId) => {
  //   setLoading(true);
  //   clientScheduledTransfersRefreshReq(id, scheduledTransfersId).then((res) => {
  //     setLoading(false);
  //     refreshData();
  //   });
  // };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Virements programmés
        </div>
      </Fragment>
    );
  }

  const renderSelectedScheduledTransfersActions = (scheduledTransfers) => {
    return (
      <div className="o-information-detail__actions">
        {scheduledTransfers?.activated &&
          scheduledTransfers?.status === 'VALIDATED' &&
          scheduledTransfers?.usableForSct && (
            <Button btnType="outline" onClick={() => setDeleteScheduledTransfersOpen(true)}>
              <img className="mr-2" height="14" src={IconCancel} alt="" />
              Supprimer
            </Button>
          )}
      </div>
    );
  };

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-information-detail">
        <div className="o-information-detail__block">
          <div className="o-information-detail__actions"></div>
        </div>
      </div>
      <div className="o-information-grid">
        <div className="mx-3 mb-3 table-title">
          Virement Programmé
          <Table
            columns={tableScheduledTransfersHeader}
            tableData={scheduledTransfersTableData}
            status={statusScheduledTransfersTable}
            setShowModal={setShowModal}
            setSelectedID={setSelectedID}
            ContainHeader={() => ''}
            contain
          />
        </div>
        <div className="o-viewer">
          {selectedID && selectedItem === 'scheduledTransfers' && (
            <div>
              <div>
                <div className="mt-5">
                  <b>Informations Virement Programmé</b>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="mt-2">
                    <div>
                      <div>
                        <b>ScheduledTransfers ID</b>
                      </div>
                      <div>{selectedScheduledTransfers?.scheduledTransfersId}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Date de création</b>
                      </div>
                      <div>{moment(selectedScheduledTransfers?.created).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Mise à jour</b>
                      </div>
                      <div>{moment(selectedScheduledTransfers?.updated).format('DD/MM/YYYY')}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Statut</b>
                      </div>
                      <div>{selectedScheduledTransfers?.status}</div>
                    </div>
                  </div>
                  <div className=" mt-2 ml-4">
                    <div>
                      <div>
                        <b>Trz ScheduledTransfers ID</b>
                      </div>
                      <div>{selectedScheduledTransfers?.trzScheduledTransfersId}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Active</b>
                      </div>
                      <div>{selectedScheduledTransfers?.activated ? 'Oui' : 'Non'}</div>
                    </div>
                    <div className="mt-2">
                      <div>
                        <b>Active</b>
                      </div>
                      <div>{selectedScheduledTransfers?.usableForSct ? 'SCT' : 'SDDR'}</div>
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <b>Actions</b>
                  <hr />
                  {renderSelectedScheduledTransfersActions(selectedScheduledTransfers)}
                </div>
              </div>
            </div>
          )}
          {!selectedID && <Logo className="o-viewer-logo" />}
        </div>
      </div>
    </div>
  );
};

export default ClientScheduledTransfers;
