import { backendApiEndpoint } from '../constants.js';
import { CheckAuth,  instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function getClientByStatusDownload(choosenStatus) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_search_by_status_download.replace('{{status}}', choosenStatus));
    if (res.status === 200) {
      //Create a Blob from the PDF Stream
      const file = new Blob([res.data], { type: "text/csv" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const link = document.createElement('a')
      link.href = fileURL
      link.setAttribute('download', 'users_' + choosenStatus + '_list.csv')
      document.body.appendChild(link)
      link.click()
      //const csvWindow = window.open();
      //csvWindow.location.href = fileURL;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function getClientGodchildrenReq(userId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_godchildren_list.replace('{{userId}}', userId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
