export const tableTopupCardsHeader = ['ID', 'Wallet', 'Statut', 'Carte Holder', 'Description', 'Montant', 'Date création'];

export const renderTopupCardsTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.topupcardpaymentid,
      topupcardpaymentid: item.topupcardpaymentid,
      wallet_id: item.wallet_id,
      state: item.state,
      card_holder: item.card_holder,
      description: item.description,
      amount: item.amount ? item.amount + '€' : '-',
      operationDate: item.operation_date ? item.operation_date : '-',
    }),
  );
  return data;
};
