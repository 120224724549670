import React, { useState } from 'react';
import * as Yup from 'yup';
import { InputFormik } from '../../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Modal from '../../../../../components/modal';
import Button from '../../../../../components/button';
import { CancelPayoutTransaction } from '../../../../../api/client/wallet/cancelPayout';
import IconLoading from '../../../../../components/loading/loading.jsx';
import IconCross from '../../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';
import { useTranslation } from 'react-i18next';

const ConfirmationCancelPayout = ({
  cancelPayoutOpen,
  setCancelPayoutOpen,
  userId,
  payoutId,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const deleteHandler = async (userId, payoutId, confirmName) => {
    setLoading(true);
    let status = await CancelPayoutTransaction(userId, payoutId, confirmName);
    setLoading(false);
    setCancelPayoutOpen(false);
    if (status === 'success') {
      // Corrected error : Expected '===' and instead saw '=='
      refreshData();
    }
  };

  const CancelPayoutInitialValues = {
    confirmLastnameUppercase: '',
  };
  const CancelPayoutSchema = Yup.object().shape({
    confirmLastnameUppercase: Yup.string().required('Requis'),
  });

  return (
    <Modal isOpen={cancelPayoutOpen} toggle={setCancelPayoutOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={CancelPayoutInitialValues}
        validationSchema={CancelPayoutSchema}
        onSubmit={(values) => {
          console.log('Delete client submit');
          deleteHandler(userId, payoutId, values['confirmLastnameUppercase']).then((res) => {
            if (res === 'success') {
              setCancelPayoutOpen(false);
            }
          });
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img
              src={IconCross}
              alt="Close"
              className="icon-cancel"
              onClick={() => setCancelPayoutOpen(false)}
            />
            <div className="o-modal-info__title-text o-title">
              Pour confirmer l'annulation du virement externe du client, veuillez saisir en
              majuscule le nom du client
            </div>
            <div className="o-modal-info__fields">
              <Field
                name="confirmLastnameUppercase"
                label="Nom du client en MAJUSCULE *"
                placeholder="Nom du client en MAJUSCULE *"
                component={InputFormik}
              />
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button className="cancel" btnType="outline" onClick={() => setCancelPayoutOpen(false)}>
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationCancelPayout;
