import moment from 'moment';

function renderBillingsFeature(array){
  return array.map((item, index) => <li>{ ''
    + ' ' + (item.feature ? item.feature.name : '')
    + ' ' + moment(item.date).format('DD/MM/YY')
    + ' ' + (item.amount? (item.amount*(1+item.vat.rate)).toFixed(2) + '€' : 'Gratuit')
    + ' Billing Failed count : ' + item.collect_failed_count
  }</li>);
}

export const tableSearchBillingsToRecover = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.debitor.toLowerCase().includes(searchInput.toLowerCase())
    );
  });
  setFilteredData(result);
};

export const tableBillingsToRecoverHeader = ['Débiteur', 'Montant HT', 'TVA', 'Montant TTC', 'Tentatives de facturation'];

export const renderBillingsToRecoverTable = (res, setDeleteModal, setCurrentItem) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.user?.userId,
      debitor: item.user?.firstname + ' ' + item.user?.lastname + ' (' + item.user?.userId + ')',
      amountHT: item.amount_total_without_vat ? item.amount_total_without_vat.toFixed(2) + '€' : 'Gratuit',
      amountVAT: item.amount_total_vat? item.amount_total_vat.toFixed(2) + '€' : 'Gratuit',
      amountTTC: item.amount_total? item.amount_total.toFixed(2) + '€' : 'Gratuit',
      billing_try: renderBillingsFeature(item.feature_history),
    }),
  );
  return data;
};
