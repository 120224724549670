import React, { useState, useEffect, useContext } from 'react';
import PermissionsContext from '../../../../contexts/permissionContext';
import { useTranslation } from 'react-i18next';
import Head from '../../../../components/head';
import Table from '../../../../components/table';
import { IconClient } from '../../../../assets/icons/icons-sidebar';
import {
  tableSearchBillingsToRecover,
  renderBillingsToRecoverTable,
  tableBillingsToRecoverHeader,
} from './client.recover.utils';
import { clientBillingToRecoverReq } from '../../../../api/monitoring/monitoring';

const ClientBillingsToRecover = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [tableData, setTableData] = useState([]);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let isRead;

    isRead = permContext?.clientRead;
    if (permContext && !isRead) window.location.href = '/dashboard';

    if (permContext) {
      /*Get data of the list*/
      clientBillingToRecoverReq()
        .then((res) => {
          /*Render and set data of the list*/
          setTableData(renderBillingsToRecoverTable(res));
        })
        .catch((error) => {
          // console.log(error);
        })
        .finally(() => setDataStatus('done'));
    }
  }, [permContext]);

  useEffect(() => {
    if (selectedID) window.location.href = `/dashboard/client/${selectedID}/billings`;
  }, [selectedID]);

  useEffect(() => {
    tableSearchBillingsToRecover(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  return (
    <div>
      <Head
        headTitle={t('sidebar:client_to_recover')}
        HeadIcon={IconClient}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setSelectedID={setSelectedID}
        isAdd={false}
      />
      <div className="o-content">
        <Table
          columns={tableBillingsToRecoverHeader}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
          setSelectedID={setSelectedID}
        />
      </div>
    </div>
  );
};

export default ClientBillingsToRecover;
