import React, { useState } from 'react';
import moment from 'moment';
import './creditCard.scss';
import Button from '../button';
import IconHand from '../../assets/icons/hand.svg';
import IconWarning from '../../assets/icons/triangle.svg';
import ConfirmationUpdateStatusCardDigitalization from './confirmationUpdateStatusCardDigitalization';
import ConfirmationDeleteCardDigitalization from './confirmationDeleteCardDigitalization';

function CreditCardDigitalization({ cardId, cardDetailDigitalizationData, refreshData, ...props }) {
  const [updateCardDigitalizationDetailModal, setUpdateCardDigitalizationDetailModal] =
    useState(false);
  const [deleteCardDigitalizationDetailModal, setDeleteCardDigitalizationDetailModal] =
    useState(false);

  return (
    <div>
      <div>
        <div className="mt-3">
          <b>Informations Digitalization</b>
        </div>
        <hr />
        <div className="d-flex">
          <div className="mt-2">
            <div>
              <div>
                <b>Card Digitalization ID</b>
              </div>
              <div>{cardDetailDigitalizationData?.carddigitalizationid}</div>
            </div>
            <div>
              <div>
                <b>Plateforme</b>
              </div>
              <div>{cardDetailDigitalizationData?.token_requestor}</div>
            </div>
            <div className="mt-2">
              <div>
                <b>Statut</b>
              </div>
              <div>{cardDetailDigitalizationData?.status}</div>
            </div>
          </div>
          <div className="mt-2">
            <div className="mt-2">
              <div>
                <b>Date de création</b>
              </div>
              <div>{moment(cardDetailDigitalizationData?.created).format('DD/MM/YYYY HH:mm')}</div>
            </div>
            <div className="mt-2">
              <div>
                <b>Code d'activation</b>
              </div>
              <div>{cardDetailDigitalizationData?.activation_code}</div>
            </div>
            <div className="mt-2">
              <div>
                <b>Device</b>
              </div>
              <div>
                {cardDetailDigitalizationData?.device_name +
                  ' - ' +
                  cardDetailDigitalizationData?.device_type}
              </div>
            </div>
          </div>
        </div>

        <div className="o-information-detail__actions mt-3">
          {cardDetailDigitalizationData?.status != 'D' ? ( //attend un "==" et reçoit un "!="
            <Button btnType="outline" onClick={() => setUpdateCardDigitalizationDetailModal(true)}>
              <img className="mr-2" height="14" src={IconHand} alt="" />
              Désactiver / Activer
            </Button>
          ) : (
            ''
          )}
          {cardDetailDigitalizationData?.status != 'D' ? ( //attend un "==" et reçoit un "!="
            <Button btnType="outline" onClick={() => setDeleteCardDigitalizationDetailModal(true)}>
              <img className="mr-2" height="14" src={IconWarning} alt="" />
              Supprimer
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
      <ConfirmationUpdateStatusCardDigitalization
        cardId={cardId}
        cardDigitalizationId={cardDetailDigitalizationData?.carddigitalizationid}
        updateCardDigitalizationDetailModal={updateCardDigitalizationDetailModal}
        setUpdateCardDigitalizationDetailModal={setUpdateCardDigitalizationDetailModal}
        refreshData={refreshData}
      />
      <ConfirmationDeleteCardDigitalization
        cardId={cardId}
        cardDigitalizationId={cardDetailDigitalizationData?.carddigitalizationid}
        deleteCardDigitalizationDetailModal={deleteCardDigitalizationDetailModal}
        setDeleteCardDigitalizationDetailModal={setDeleteCardDigitalizationDetailModal}
        refreshData={refreshData}
      />
    </div>
  );
}

export default CreditCardDigitalization;
