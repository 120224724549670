import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';

export async function addCodePromoReq(data) {
  try {
    console.info(data);
    const res = await instanceBackendApi.post(backendApiEndpoint.code_promo, data);
    if (res.status === 200) {
      toast('Les données ont été enregistrées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.message);
    CheckAuth(error);
  }
}
