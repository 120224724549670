import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Modal from '../../../../../components/modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/button';
import { deleteClientBillingHistoryReq } from '../../../../../api/client/billing';
import { clientWalletListReq } from '../../../../../api/client/wallet';
import IconLoading from '../../../../../components/loading/loading.jsx';
import IconCross from '../../../../../assets/icons/_SmallIcons/ic_cross_figma.svg';

const ConfirmationDeleteBillingHistory = ({
  deleteBillingHistoryOpen,
  setDeleteBillingHistoryOpen,
  userId,
  userFeatureHistory,
  refreshData,
}) => {
  const [loading, setLoading] = useState(false);
  const [walletListDrp, setWalletListDrp] = useState();
  const { t } = useTranslation();
  let walletOptions = [];

  useEffect(() => {
    //for wallet dropdown
    clientWalletListReq(userId).then((res) => {
      /*Render and set data of the list*/
      res.map((item) =>
        walletOptions.push({
          label: item.walletId + ' - ' + item.name + ' - ' + item.authorized_balance + ' €',
          value: item.walletId,
        }),
      );
      setWalletListDrp(walletOptions);
    });
  }, []);

  const deleteBillingHistoryHandler = async (id) => {
    setLoading(true);
    let responseData = await deleteClientBillingHistoryReq(
      id,
      userFeatureHistory?.user_feature_history_id,
    );
    setLoading(false);
    console.log(responseData);
    if (responseData === 'success') {
      refreshData();
      setDeleteBillingHistoryOpen(false);
    }
  };

  const DeleteBillingHistoryInitialValues = {};
  const DeleteBillingHistorySchema = Yup.object().shape({});

  return (
    <Modal isOpen={deleteBillingHistoryOpen} toggle={setDeleteBillingHistoryOpen}>
      <Formik
        enableReinitialize={true}
        initialValues={DeleteBillingHistoryInitialValues}
        validationSchema={DeleteBillingHistorySchema}
        onSubmit={(values) => {
          deleteBillingHistoryHandler(userId);
        }}
      >
        <Form>
          <div className="o-modal-info">
            <img
              src={IconCross}
              alt="Close"
              className="icon-cancel"
              onClick={() => setDeleteBillingHistoryOpen(false)}
            />
            <div className="o-modal-info__title-text o-title">
              Confirmez vous la suppression de cette ligne d'élément de facturation
            </div>
            <div className="o-btn-group">
              <Button type="submit">
                {loading ? (
                  <div className="d-flex mx-auto">
                    <IconLoading />
                  </div>
                ) : (
                  t('global:confirm')
                )}
              </Button>
              <Button
                className="cancel" btnType="outline"
                onClick={() => {
                  setDeleteBillingHistoryOpen(false);
                }}
              >
                {t('global:cancel')}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default ConfirmationDeleteBillingHistory;
