import { backendApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceBackendApi } from '../../index';
import { toast } from 'react-toastify';
var qs = require('qs');

export async function cardListReq(userId) {
  try {
    const res = await instanceBackendApi.get(
      backendApiEndpoint.client_card_list.replace('{{userId}}', userId),
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function cardDetailReq(userId, cardId, force) {
  try {
    let queryParams = {
      params: {
        force: force
      }
    };
    const res = await instanceBackendApi.get(backendApiEndpoint.client_card_detail.replace('{{userId}}', userId).replace('{{cardId}}', cardId), queryParams);
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}


export async function orderPhysicalCardReq(userId, walletId, data) {

  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(backendApiEndpoint.client_card_physical.replace('{{userId}}', userId).replace('{{walletId}}', walletId), data, queryParams);
    if (res.status === 200) {
      toast('Création de la nouvelle carte avec succès !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';
  }
}

export async function orderVirtualCardReq(userId, walletId) {

  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    const res = await instanceBackendApi.post(backendApiEndpoint.client_card_virtual.replace('{{userId}}', userId).replace('{{walletId}}', walletId), {}, queryParams);
    if (res.status === 200) {
      toast('Création de la nouvelle carte virtuelle avec succès !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';
  }
}

export async function activateCardReq(userId, walletId, cardId, data) {

  try {
    let queryParams = {
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    };
    let dataQuery = qs.stringify(data)
    const res = await instanceBackendApi.post(backendApiEndpoint.client_card_detail_activate.replace('{{userId}}', userId).replace('{{walletId}}', walletId).replace('{{cardId}}', cardId), dataQuery, queryParams);
    if (res.status === 200) {
      toast('Activation de la carte avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';
  }

}


export async function cardDetailImageReq(cardImageUrl, data) {

  try {
    const res = await instanceBackendApi.get(cardImageUrl, { responseType: "arraybuffer" });
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.error?.message);
    CheckAuth(error)
    return 'fail';
  }
}
