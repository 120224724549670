import { backendApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceBackendApi } from '../../index';
import { toast } from 'react-toastify';

var qs = require('qs');

export async function updateCardLockUnlockReq(userId, walletId, cardId, status) {

  try {
    let dataQuery = qs.stringify({
      'status': status
    });
    const res = await instanceBackendApi.post(backendApiEndpoint.client_card_detail_status.replace('{{userId}}', userId).replace('{{walletId}}', walletId).replace('{{cardId}}', cardId), dataQuery);
    if (res.status === 200) {
      toast('Mise à jour de status de carte enregistrée !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.message);
    CheckAuth(error)
    return 'fail';
    
  }
}

export async function updateCardStatusReq(userId, walletId, cardId, data) {
  try {
    let dataQuery = qs.stringify(data)
    const res = await instanceBackendApi.post(backendApiEndpoint.client_card_detail_status.replace('{{userId}}', userId).replace('{{walletId}}', walletId).replace('{{cardId}}', cardId), dataQuery);
    if (res.status === 200) {
      toast('Mise à jour de status de carte enregistrée !');
      return 'success';
    } else {
      toast.error('Une erreur est survenue !');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue ! Error Code = ' + error.response.data?.message);
    CheckAuth(error)
    return 'fail';
 
  }
}
