import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { PartnershipSchema, PartnershipInitialValues } from '../partnership.utils';
import IconCross from '../../../../assets/icons/cross.svg';

const ShowPartnership = ({ showModal, setShowModal, currentItem }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <Formik
        enableReinitialize={true}
        initialValues={currentItem ? currentItem : PartnershipInitialValues}
        validationSchema={PartnershipSchema}
      >
        {() => (
          <Form>
            <div className="o-modal-info">
              <img
                src={IconCross}
                alt="Close"
                className="icon-cancel"
                onClick={() => {
                  setShowModal(false);
                }}
              />
              <div className="o-modal-info__title-text o-title">
                {t('partnership:detail_partnership')}
              </div>
              <div className="o-modal-info__fields">
                <Field
                  name="code"
                  label={t('partnership:unique_code')}
                  placeholder={t('partnership:unique_code_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />
                <Field
                  name="name"
                  label={t('partnership:descriptive_name')}
                  placeholder={t('partnership:descriptive_name_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />
                <Field
                  name="description"
                  label={t('partnership:full_description')}
                  placeholder={t('partnership:full_description_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />
              </div>
              <Button
                className="cancel" btnType="outline"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {t('global:back')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ShowPartnership;
