import { BASE_URL, BACKEND_API_BASE_URL, FRIDAY_API_BASE_URL,
  CEREBRO_API_BASE_URL, SMAUG_API_BASE_URL, SIDEKICK_API_BASE_URL , NOTIF_CENTER_API_BASE_URL,
  SAURON_API_BASE_URL, SCROOGE_API_BASE_URL } from './constants.js';
import { localStorageKeys } from './constants.js';
import axios from 'axios';
import { toast } from 'react-toastify';

export const CheckAuth = (error) => {
  console.log(error);
  if (error.response && error.response !== null)
    if (error.response.status === 403) {
      console.log('responseDataErrorMessage=' + error.response.data?.error?.message);
      if (error.response.data?.message === 'USER_NOT_GRANTED' || error.response.data?.error?.message === 'USER_NOT_GRANTED'){
        // on ne fait rien
        //toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
      } else {
        localStorage.removeItem(localStorageKeys.onlyone_token);
        localStorage.removeItem(localStorageKeys.permissions);
        window.location.href = '/';
      }
    } else if (error.response.status === 401) {
      localStorage.removeItem(localStorageKeys.onlyone_token);
      localStorage.removeItem(localStorageKeys.permissions);
      window.location.href = '/';
    } else {
      toast.error('Erreur : ' + error.response.data?.error?.message);
    }
};

export const instance = axios.create({
  baseURL: BASE_URL,
  headers: { Authorization: 'Bearer ' + localStorage.getItem(localStorageKeys.onlyone_token) },
});

export const instanceBackendApi = axios.create({
  baseURL: BACKEND_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + localStorage.getItem(localStorageKeys.onlyone_token)
    }
});

export const instanceFridayApi = axios.create({
  baseURL: FRIDAY_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + localStorage.getItem(localStorageKeys.onlyone_token)
    }
});

export const instanceNotifCenterApi = axios.create({
  baseURL: NOTIF_CENTER_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + localStorage.getItem(localStorageKeys.onlyone_token)
    }
});

export const instanceCerebroApi = axios.create({
  baseURL: CEREBRO_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + localStorage.getItem(localStorageKeys.onlyone_token)
    }
});

export const instanceSmaugApi = axios.create({
  baseURL: SMAUG_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + localStorage.getItem(localStorageKeys.onlyone_token)
    }
});

export const instanceSideKickApi = axios.create({
  baseURL: SIDEKICK_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + localStorage.getItem(localStorageKeys.onlyone_token)
    }
});

export const instanceSauronApi = axios.create({
  baseURL: SAURON_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + localStorage.getItem(localStorageKeys.onlyone_token)
    }
});

export const instanceScroogeApi = axios.create({
  baseURL: SCROOGE_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': 'Bearer ' + localStorage.getItem(localStorageKeys.onlyone_token)
    }
});

export const instanceJwtApi = axios.create({
  baseURL: BACKEND_API_BASE_URL,
  headers: { 'X-Requested-With': 'XMLHttpRequest'}
});
