import React, { useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Head from '../../../components/head';
import Table from '../../../components/table';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import { columnsHistoryTable, dummyDataHistoryTable } from './client.utils';

const ClientHistory = () => {
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Historique client
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Head headTitle={headTitle()} HeadIcon={IconClient} isAdd={false} disabledSearch />
      <div className="o-content">
        <Table
          showModal={showModal}
          setShowModal={setShowModal}
          columns={columnsHistoryTable}
          tableData={dummyDataHistoryTable}
          textLeft
        />
      </div>
    </div>
  );
};

export default ClientHistory;
