import { endpoint } from '../constants.js';
import { CheckAuth, instance } from '../index';
import { toast } from 'react-toastify';

export async function addPackageTypeReq(data) {
  try {
    const res = await instance.post(endpoint.packageType + endpoint.key, data);
    if (res.status === 200) {
      toast('Les données ont été enregistrées avec succès');
      return 'success';
    } else {
      toast.error('Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!');
    CheckAuth(error);
  }
}
