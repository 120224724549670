import moment from 'moment';

export const tableScheduledTransfersHeader = ['ID', 'Motif', 'Montant', 'Date programmée', 'Date de fin', 'Statut', 'Date execution'];

export const renderScheduledTransfersTable = (res) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.scheduledtransferid,
      scheduledTransferID: item.scheduledtransferid,
      label: item.label,
      amount: item.amount + ' ' + item.currency_code,
      scheduledStartDate: item.scheduled_start_date? moment(item.scheduled_start_date).format('DD/MM/YYYY') : '-',
      scheduledEndDate: item.scheduled_end_date? moment(item.scheduled_end_date).format('DD/MM/YYYY') : '-',
      status: item.status,
      executionDate: item.execution_date? moment(item.execution_date).format('DD/MM/YYYY HH:mm') : '-',
    }),
  );
  return data;
};
