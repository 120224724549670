import React, { useContext, useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PermissionsContext from '../../contexts/permissionContext';
import { ReactComponent as IconChevronDown } from '../../assets/icons/_SmallIcons/ic_chevron_down.svg';
import { ReactComponent as IconLock } from '../../assets/icons/_SmallIcons/ic_lock.svg';
import './navbar.scss';
import { LogoOnlyOne } from '../../assets/onlyone';
import '../../components/dropdown/dropdown.scss';
import { ReactComponent as IconLogout } from '../../assets/icons/_SmallIcons/ic_logout.svg';
import { ReactComponent as IconSettings } from '../../assets/icons/_SmallIcons/ic_settings.svg';
import { ReactComponent as IconProfile } from '../../assets/icons/_SmallIcons/ic_profile.svg';

const CompanyEnvironmentName = () => process.env.REACT_APP_MARKET === 'pro' ? 'Business' : 'Consumer';

const Navbar = () => {
  const { permContext } = useContext(PermissionsContext);
  const { t } = useTranslation();
  const location = useLocation();
  const [userName, setUserName] = useState({ firstName: '', lastName: '' });

  useEffect(() => {
    const storedUserName = JSON.parse(localStorage.getItem('user_name'));
    if (storedUserName) {
      setUserName(storedUserName);
    }
  }, []);

  function ProfilDropdown() {
    const [isDropdownDisplayed, setIsDropdownDisplayed] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropdownDisplayed(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <fieldset className="o-navbar__right__profil__icon__chevron__dropdown">
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsDropdownDisplayed((prevState) => !prevState);
          }}
        >
          <IconChevronDown className="o-navbar__right__profil__icon__chevron" />
        </button>
        {isDropdownDisplayed && (
          <div
            ref={dropdownRef}
            className="o-navbar__right__profil__icon__chevron__dropdown__panel"
            onClick={(e) => e.stopPropagation()}
          >
            <fieldset>
              <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__sup">
                <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__sup__title">
                  Compte
                </div>
                <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__sup__user">
                  <div className="o-navbar__right__profil__icon">{userName?.firstName?.charAt(0) + userName?.lastName?.charAt(0)}</div>
                  <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__sup__user__info">
                    <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__sup__user__info__name">
                      {userName.firstName} {userName.lastName}
                    </div>
                    <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__sup__user__info__company">
                      OnlyOne <CompanyEnvironmentName />
                    </div>
                  </div>
                </div>
              </div>
              <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__inf">
                <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__inf__menu_item">
                  <IconSettings className="o-navbar__right__profil__icon__chevron__dropdown__panel__inf__menu_item__icon" />
                  <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__inf__menu_item__item">
                    Réglages
                  </div>
                </div>
                <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__inf__menu_item">
                  <IconProfile className="o-navbar__right__profil__icon__chevron__dropdown__panel__inf__menu_item__icon" />
                  <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__inf__menu_item__item">
                    Profil
                  </div>
                </div>
                <NavLink
                  exact
                  className="o-navbar__right__profil__icon__chevron__dropdown__panel__inf__menu_item"
                  to="/"
                  onClick={() => {
                    localStorage.clear();
                  }}
                >
                  <IconLogout className="o-navbar__right__profil__icon__chevron__dropdown__panel__inf__menu_item__icon" />
                  <div className="o-navbar__right__profil__icon__chevron__dropdown__panel__inf__menu_item__item__logout">
                    {t('global:logout')}
                  </div>
                </NavLink>
              </div>
            </fieldset>
          </div>
        )}
      </fieldset>
    );
  }

  return (
    <div className="o-navbar">
      <div className="o-navbar__left">
        <div className="o-navbar__left__logo__title">
          <LogoOnlyOne />
        </div>
        <div className="o-navbar__menu">
          <div>
            {permContext?.adminRead && (
              <NavLink to="/dashboard/status">
                <div
                  className={`o-navbar__header__item ${
                    location.pathname.includes('/dashboard/status') ? 'active' : ''
                  }`}
                >
                  {t('sidebar:status')}
                </div>
              </NavLink>
            )}
          </div>
          {permContext?.clientRead && (
            <>
              <div>
                <NavLink to="/dashboard/client">
                  <div
                    className={`o-navbar__header__item ${
                      location.pathname.includes('/dashboard/client') ? 'active' : ''
                    }`}
                  >
                    {t('sidebar:client')}
                  </div>
                </NavLink>
              </div>
              <div>
                <NavLink to="/dashboard/supervision">
                  <div
                    className={`o-navbar__header__item ${
                      location.pathname.includes('/dashboard/supervision') ? 'active' : ''
                    }`}
                  >
                    Surveillance
                  </div>
                </NavLink>
              </div>
              <div>
                <NavLink to="/dashboard/admin/waitinglist">
                  <div
                    className={`o-navbar__header__item ${
                      location.pathname.includes('/dashboard/admin/waitinglist') ? 'active' : ''
                    }`}
                  >
                    Liste d'attente
                  </div>
                </NavLink>
              </div>
            </>
          )}
          {permContext?.reportRead && (
            <div>
              <NavLink to="/dashboard/reports/reports">
                <div
                  className={`o-navbar__header__item ${
                    location.pathname.includes('/dashboard/reports/reports') ? 'active' : ''
                  }`}
                >
                  Rapports
                </div>
              </NavLink>
            </div>
          )}
        </div>
      </div>
      <div className="o-navbar__right">
        <>
          {permContext?.adminRead && (
            <div className="o-navbar__right__admin">
              <IconLock className="o-navbar__right__admin__lock" />
              <NavLink to="/dashboard/admin">
                <div className="o-navbar__right__admin__text">Espace admin</div>
              </NavLink>
            </div>
          )}
          <div className="o-navbar__right__profil">
            <div className="o-navbar__right__profil__icon">
              {userName.firstName.charAt(0)}
              {userName.lastName.charAt(0)}
            </div>
            <div>
              <ProfilDropdown />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Navbar;
