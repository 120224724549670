import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Head from '../../../components/head';
import Table from '../../../components/table';
import Button from '../../../components/button';
import { IconClient } from '../../../assets/icons/icons-sidebar';
import IconDoc from '../../../assets/icons/doc.svg';
import { columnsDocumentTable, renderDocumentTable } from './client.utils';
import { clientDocumentReq } from '../../../api/client/document';
import ShowDocument from './modals/showDocument';
import ConfirmationSubmitKYCDocs from './modals/confirmationSubmitKycDocs';

const ClientDocument = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState('loading');
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [submitKycDocOpen, setSubmitKycDocOpen] = useState(false);

  useEffect(() => {
    refreshData();
  }, []); // manque 'rereshData'

  const refreshData = () => {
    setStatus('loading');
    clientDocumentReq(id)
      .then((res) => {
        setTableData(renderDocumentTable(res));
      })
      .finally(() => setStatus('done'));
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/client">Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          <a href={`/dashboard/client/${id}`}>Détails client&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          Documents client
        </div>
      </Fragment>
    );
  }

  function UploadDocumentButton() {
    return (
      <div className="ml-auto">
        <Button btnType="outline" className="ml-4" onClick={() => setSubmitKycDocOpen(true)}>
          <img className="mr-2" src={IconDoc} alt="" />
          Ajout Document
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Head
        headTitle={headTitle()}
        HeadIcon={IconClient}
        isAdd={false}
        disabledSearch
        rightFragment={UploadDocumentButton()}
      />
      <div className="o-content">
        <Table
          showModal={showModal}
          setShowModal={setShowModal}
          columns={columnsDocumentTable}
          setSelectedID={setSelectedID}
          tableData={tableData}
          status={status}
        />
      </div>
      {showModal && (
        <ShowDocument
          userId={id}
          document={selectedID}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      <ConfirmationSubmitKYCDocs
        submitKycOpen={submitKycDocOpen}
        setSubmitKycOpen={setSubmitKycDocOpen}
        userId={id}
        refreshData={refreshData}
      />
    </div>
  );
};

export default ClientDocument;
