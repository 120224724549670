import { backendApiEndpoint } from '../constants.js';
import { CheckAuth, instanceBackendApi } from '../index';
import { toast } from 'react-toastify';
var qs = require('qs');

export async function clientKycLivenessListReq(userId) {
  try {
    const res = await instanceBackendApi.get(
      backendApiEndpoint.client_kyc_kycliveness.replace('{{userId}}', userId),
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
export async function clientAskUrlKycLivenessReq(userId, data) {
  try {
    let dataPost = qs.stringify({
      'redirect_url': data.redirect_url
    })
    const res = await instanceBackendApi.post(
      backendApiEndpoint.client_kyc_kycliveness.replace('{{userId}}', userId), dataPost
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue! ' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientReviewKycLivenessReq(userId, oldKycReview) {
  try {
    let dataPost = qs.stringify({
      'old_kyc_review': oldKycReview
    })
    const res = await instanceBackendApi.post(
      backendApiEndpoint.client_kyc_kycreview.replace('{{userId}}', userId), dataPost
    );
    if (res.status === 200) {
      return 'success';
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue! ' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientAskUrlKycLivenessWithMailReq(userId) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.client_kyc_kycliveness_mail.replace('{{userId}}', userId));
    if (res.status === 200) {
      return 'success';
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue! ' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function clientAskUrlKycLivenessWithSmsReq(userId) {
  try {
    const res = await instanceBackendApi.post(backendApiEndpoint.client_kyc_kycliveness_sms.replace('{{userId}}', userId));
    if (res.status === 200) {
      return 'success';
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Une erreur est survenue! ' + error.response.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function downloadKycLivenessReportReq(userId, documentId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_kyc_kycliveness_report_download.replace('{{userId}}', userId).replace('{{documentId}}', documentId), {responseType: "arraybuffer"});
    if (res.status === 200) {
      //Create a Blob from the PDF Stream
      const file = new Blob([res.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function generateAndDownloadKycLivenessReportReq(userId, documentId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.client_kyc_kycliveness_report_generate_download.replace('{{userId}}', userId).replace('{{documentId}}', documentId), {responseType: "arraybuffer"});
    if (res.status === 200) {
      //Create a Blob from the PDF Stream
      const file = new Blob([res.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    } else {
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail!' + error.response.data?.error?.message);
    CheckAuth(error);
    return 'fail';
  }
}
