import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormik } from '../../../../components/input';
import { Formik, Form, Field } from 'formik';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import { PermissionSchema, PermissionInitialValues } from '../permission.utils';
import IconCross from '../../../../assets/icons/cross.svg';

const ShowPermission = ({ showModal, setShowModal, currentItem }) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState(PermissionInitialValues);

  useEffect(() => {
    if (currentItem) {
      setInitialValues(currentItem);
    }
  }, [currentItem]);

  return (
    <Modal isOpen={showModal} toggle={setShowModal}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={PermissionSchema}
      >
        {() => (
          <Form>
            <div className="o-modal-info">
              <img
                src={IconCross}
                alt="Close"
                className="icon-cancel"
                onClick={() => {
                  setShowModal(false);
                }}
              />
              <div className="o-modal-info__title-text o-title">
                {t('permission:detail_permission')}
              </div>
              <div className="o-modal-info__fields">
                <Field
                  name="code"
                  label={t('permission:unique_code')}
                  placeholder={t('permission:unique_code_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />
                <Field
                  name="name"
                  label={t('permission:descriptive_name')}
                  placeholder={t('permission:descriptive_name_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />
                <Field
                  name="description"
                  label={t('permission:full_description')}
                  placeholder={t('permission:full_description_placeholder') + ' *'}
                  component={InputFormik}
                  onlyRead
                />
              </div>
                <Button
                  className="cancel" btnType="outline"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  {t('global:back')}
                </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ShowPermission;
