import * as Yup from 'yup';
import React from 'react';
import { ButtonTable } from '../../../components/button/index';

export const PackageTypeInitialValues = {
  name: '',
  type: '',
  order: '',
  activated: '',
  price: '',
  description: '',
  summary: '',
  minAdult: 1,
  minChild: 0,
  numberOfChildAllowed: '',
  numberOfWalletAllowed: 0,
  numberOfWithdrawalAllowed: 0,
};
export const PackageTypeSchema = Yup.object().shape({
  name: Yup.string().max(255, '255 car. max').required('Required'),
  type: Yup.object().required('Required'),
  order: Yup.number().integer().required('Required'),
  activated: Yup.object().required('Required'),
  price: Yup.number().required('Required'),
  description: Yup.string().max(255, '255 car. max').required('Required'),
  summary: Yup.string().max(255, '255 car. max'),
  minAdult: Yup.number().required('Required'),
  minChild: Yup.number().required('Required'),
  numberOfChildAllowed: Yup.number(),
  numberOfWalletAllowed: Yup.number().required('Required'),
  numberOfWithdrawalAllowed: Yup.number().required('Required'),
});

export const columns = ['Nom', 'Type', 'Ordre', 'Activé', 'Prix', 'Descr.', 'Modif.', 'Supp.'];

export const YesNo = [
  { label: 'Oui', value: true },
  { label: 'Non', value: false },
];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.type.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.activated.toLowerCase().includes(searchInput.toLowerCase()) ||
      value.order === Number(searchInput) ||
      value.price.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      value.description.toLowerCase().includes(searchInput.toLowerCase()) ||
      (value.searchData.summary &&
        value.searchData.summary.toLowerCase().includes(searchInput.toLowerCase())) ||
      value.searchData.minAdult === Number(searchInput) ||
      value.searchData.minChild === Number(searchInput) ||
      (value.searchData.numberOfChildAllowed &&
        value.searchData.numberOfChildAllowed === Number(searchInput)) ||
      value.searchData.numberOfWalletAllowed === Number(searchInput) ||
      value.searchData.numberOfWithdrawalAllowed === Number(searchInput)
    );
  });
  setFilteredData(result);
};

export const renderTable = (res, setAddModal, setDeleteModal, setSelectedID, isEdit, isDelete) => {
  let data = [];
  res.map((item) =>
    data.push({
      key: item.membershipTypeId,
      searchData: item,
      name: item.name,
      type: item.type,
      order: item.order,
      activated: item.activated ? 'Yes' : 'No',
      price: item.price,
      description: item.description,
      modif: isEdit ? (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedID(item.membershipTypeId);
            setAddModal(true);
          }}
        />
      ) : (
        <ButtonTable
          action="edit"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
      delete: isDelete ? (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
            setDeleteModal(true);
            setSelectedID(item.membershipTypeId);
          }}
        />
      ) : (
        <ButtonTable
          action="delete"
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
      ),
    }),
  );
  return data;
};
