import React, { useState, useEffect, useContext, Fragment } from 'react';
import PermissionsContext from '../../../contexts/permissionContext';

import { useTranslation } from 'react-i18next';
import { columns, tableSearch, renderTable } from './partnership.utils';
import { getPartnershipsReq } from '../../../api/partnership/partnership';

import Head from '../../../components/head';
import Table from '../../../components/table';
import AddPartnership from './modals/addPartnership';
import UpdatePartnership from './modals/updatePartnership';
import ShowPartnership from './modals/showPartnership';
import { IconGroup } from '../../../assets/icons/icons-sidebar';

const Partnership = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [tableData, setTableData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    let isEdit, isDelete;
    isEdit = permContext?.partnershipUpdate;
    isDelete = permContext?.partnershipDelete;
    setIsAdd(isEdit);

    if (permContext) {
      /*Get data of the list*/
      getPartnershipsReq()
        .then((res) => {
          /*Render and set data of the list*/
          setTableData(
            renderTable(res, setAddModal, setUpdateModal, setCurrentItem, isEdit, isDelete),
          );
        })
        .finally(() => setDataStatus('done'));
    }
  }, [permContext]);

  useEffect(() => {
    tableSearch(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/admin">Administration&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          {t('sidebar:partnership')}
        </div>
      </Fragment>
    );
  }
  return (
    <div>
      <Head
        headTitle={headTitle()}
        HeadIcon={IconGroup}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setAddModal={setAddModal}
        setCurrentItem={setCurrentItem}
        isAdd={isAdd}
      />
      <div className="o-content">
        <Table
          columns={columns}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
          setShowModal={setShowModal}
          setCurrentItem={setCurrentItem}
        />
      </div>
      {/*Add and edit partnership modal*/}
      {addModal && (
        <AddPartnership addModal={addModal} setAddModal={setAddModal} currentItem={currentItem} />
      )}
      {/*Show partnership modal*/}
      {showModal && (
        <ShowPartnership
          showModal={showModal}
          setShowModal={setShowModal}
          currentItem={currentItem}
        />
      )}
      {/*Update partnership modal*/}
      {updateModal && (
        <UpdatePartnership
          currentItem={currentItem}
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
        />
      )}
    </div>
  );
};

export default Partnership;
