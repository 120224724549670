import React, { useContext } from 'react';
import PermissionsContext from '../../../../contexts/permissionContext';
import { NavLink, useLocation } from 'react-router-dom';
import './client_filter_tabs.scss';

const ClientDetailFilterTabs = ({clientId, ...props}) => {
    const { permContext } = useContext(PermissionsContext);
    const location = useLocation();

    console.log('clientId = ' + clientId);
    return (
        <div className='o-filter'>
            <div className='o-filter__tabs'>
                {permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client_v2/' + clientId }
                    >
                    <div className={`o-filter__tabs__tab ${
                        location.pathname === '/dashboard/client_v2/' + clientId ? "active" : ""
                    }`}>Général</div>
                    </NavLink>
                )}
                {permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client_v2/' + clientId + '/informations'}
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === '/dashboard/client_v2/' + clientId + '/informations' ? "active" : ""
                                }`}>Informations</div>
                    </NavLink>
                )}
                {permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client_v2/' + clientId + '/security'}
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === '/dashboard/client_v2/' + clientId + '/security' ? "active" : ""
                                }`}>Sécurité</div>
                    </NavLink>
                )}
                {permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client_v2/' + clientId + '/membersip'}
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === '/dashboard/client_v2/' + clientId + '/membersip' ? "active" : ""
                                }`}>Forfait</div>
                    </NavLink>
                )}
                {permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client_v2/' + clientId + '/wallets'}
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === '/dashboard/client_v2/' + clientId + '/wallets' ? "active" : ""
                                }`}>Wallets</div>
                    </NavLink>
                )}
                {permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client_v2/' + clientId + '/cards'}
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === '/dashboard/client_v2/' + clientId + '/cards' ? "active" : ""
                                }`}>Cartes</div>
                    </NavLink>
                )}
                {permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client_v2/' + clientId + '/operations'}
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === '/dashboard/client_v2/' + clientId + '/operations' ? "active" : ""
                                }`}>Opérations</div>
                    </NavLink>
                )}
                {permContext?.clientRead && (
                    <NavLink
                    to={'/dashboard/client_v2/' + clientId + '/communication'}
                    >
                    <div className={`o-filter__tabs__tab ${
                                    location.pathname === '/dashboard/client_v2/' + clientId + '/communication' ? "active" : ""
                                }`}>Communication</div>
                    </NavLink>
                )}
            </div>
        </div>
    )
}

export default ClientDetailFilterTabs;
