import { endpoint } from '../constants.js';
import { CheckAuth, instance } from '../index';

export async function getPackageFeatureReq() {
  try {
    const res = await instance.get(endpoint.packageFeature + endpoint.key);
    return res.data.content;
  } catch (error) {
    CheckAuth(error);
  }
}
