import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/modal';
import Button from '../../../../components/button';
import { deletePackageTypeReq } from '../../../../api/packageType/deletePackageType';
import IconCross from '../../../../assets/icons/cross.svg';

const DeletePackageType = ({ deleteModal, setDeleteModal, selectedID }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={deleteModal} toggle={setDeleteModal}>
      <div className="o-modal-info">
        <img
          src={IconCross}
          alt="Close"
          className="icon-cancel"
          onClick={() => {
            setDeleteModal(false);
          }}
        />
        <div className="o-modal-info__title-text">
          <div className="o-title">{t('type:delete_type')}</div>

          <div
            className="o-modal-info__title-text__text"
            dangerouslySetInnerHTML={{ __html: t('type:delete_type_description') }}
          />
        </div>
        <div className="o-btn-group">
          <Button
            type="submit"
            onClick={() => {
              deletePackageTypeReq(selectedID).then((res) => {
                if (res === 'success') {
                  setTimeout(() => {
                    window.location.href = '/dashboard/admin/package-types';
                  }, 700);
                }
              });
            }}
          >
            {t('global:confirm')}
          </Button>
          <Button
            className="cancel" btnType="outline"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {t('global:cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePackageType;
