import moment from 'moment';

export const columns = [
  'ID',
  'Date',
  'Créditeur',
  'Mandat',
  'Transaction ID',
];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (
      value.code.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      value.mandate.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      value.creditor?.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
      value.transactionid.toString().toLowerCase().includes(searchInput.toLowerCase())
    );
  });
  setFilteredData(result);
};

export const renderTable = (res, t) => {
  let data = [];
  res.map((item) =>
    data.push({
      code: item.sepasddrid,
      date: moment(item.created).format('DD/MM/YYYY HH:mm'),
      creditor: item.beneficiary?.name,
      reject_reason: item.reject_reason_code ? item.reject_reason_code + ':' + t('sdd_errors:' + item.reject_reason_code) : '-',
      mandate: item.mandate_id,
      transactionid: item.transaction_id,
    }),
  );
  return data;
};
